import { action, computed, configure, observable, runInAction } from "mobx";

import {
  INotificationData,
  INotificationDataResult,
  INotificationsInfoData,
} from "../../data/AppModels";
import RestApi from "../../services/RestApi";
import BasicStore from "../BasicStore";

// strict mode
configure({ enforceActions: "observed" });

export interface INotificationsStore extends BasicStore {
  count: number;

  notifications: INotificationData[];

  fetchInfoData(): void;

  markAsVisited(id: string): void;
}

class NotificationsStore implements INotificationsStore {
  isInstallationSensitive = () => true;

  @observable infoData: INotificationsInfoData = { count: -1 };

  @observable notificationsData: INotificationData[] = [];

  @computed
  get count(): number {
    return this.infoData.count;
  }

  @computed
  get notifications(): INotificationData[] {
    return this.notificationsData;
  }

  @action
  fetchInfoData() {
    RestApi.loadNotificationsInfoV1()
      .then((data) => {
        if (this.infoData.count !== data.count) {
          this.fetchNotificationsData();
        }
        return this.setData(data);
      })
      .catch((error) => {
        console.warn("could not load notifications info", error);
        this.setData({ count: -1 });
      });
  }

  @action.bound
  setData(data: INotificationsInfoData) {
    this.infoData = data;
  }

  @action
  fetchNotificationsData() {
    return RestApi.loadNotificationsV1().then((data) => {
      this.setNotificationsData(data);
      return data;
    });
  }

  @action.bound
  setNotificationsData(data: INotificationDataResult) {
    this.notificationsData = data.content;
    this.infoData.count = data.content.length;
  }

  @action
  markAsVisited(id: string) {
    const beforeApiCall = [...this.notificationsData];
    this.notificationsData = this.notificationsData.filter(
      (notification) => notification.id !== id
    );
    this.infoData.count = this.notificationsData.length;
    RestApi.markNotificationAsProcessedV1(id).catch(() => {
      console.warn(
        "notification was not marked as visited because of an error"
      );
      runInAction(() => {
        this.notificationsData = beforeApiCall;
        this.infoData.count = this.notificationsData.length;
      });
    });
  }

  clear(): void {
    this.infoData = { count: -1 };
    this.notificationsData = [];
  }
}

export default NotificationsStore;
