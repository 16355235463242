import { action, computed, configure, observable } from "mobx";

import {
  DsMetaColumnDto,
  IDSInfoLoadData,
  IDsTemplate,
} from "../../data/AppModels";
import RestApi from "../../services/RestApi";
import BasicStore from "../BasicStore";

export interface IDataSetTemplateStore extends BasicStore {
  getTemplates: IDsTemplate[];

  getCurrentTemplate: IDsTemplate;

  getDsColumns: DsMetaColumnDto[];

  getDataSetId: string;

  getSelectedTemplate: IDsTemplate | null;

  setTemplateName(name: string): void;

  setTemplateData(data: string): void;

  selectDataSet(dataSetId: string): void;

  saveCurrent(): Promise<string>;

  // setDatasetTemplates(dsTemplates: IDsTemplate[]): void;

  setSelectedTemplate(dsTemplate: IDsTemplate): void;

  clearSelectedTemplate(): void;
}

// strict mode
configure({ enforceActions: "observed" });

class DataSetTemplateStore implements IDataSetTemplateStore {
  isInstallationSensitive = () => true;

  @observable templates: IDsTemplate[] = [];

  dataSetId: string | null = null;

  @observable currentTemplate: IDsTemplate = {
    id: null,
    data: "",
    name: "",
  };

  @observable dsColumns: DsMetaColumnDto[] = [];

  @observable selectedTemplate: IDsTemplate | null = null;

  @computed
  get getTemplates(): IDsTemplate[] {
    return this.templates;
  }

  @computed
  get getDsColumns(): DsMetaColumnDto[] {
    return this.dsColumns;
  }

  @computed
  get getCurrentTemplate(): IDsTemplate {
    return this.currentTemplate;
  }

  @computed
  get getDataSetId(): string {
    return this.dataSetId || "";
  }

  @action
  setTemplateName(name: string): void {
    this.currentTemplate.name = name;
  }

  @action
  setTemplateData(data: string): void {
    console.info(data);
    this.currentTemplate.data = data;
  }

  @action
  selectDataSet(dataSetId): void {
    this.dataSetId = dataSetId;

    RestApi.loadDSMeta(this.dataSetId || "").then((r: IDSInfoLoadData) => {
      this.setDsColumns(r.columns);
    });

    RestApi.loadDatasetTemplates(this.dataSetId || "").then(
      (dsTemplates: IDsTemplate[]) => {
        this.setDatasetTemplates(dsTemplates);
      }
    );
  }

  @action.bound
  setDsColumns(dsColumns: DsMetaColumnDto[]) {
    this.dsColumns = dsColumns;
  }

  @action
  saveCurrent(): Promise<string> {
    if (this.dataSetId) {
      return RestApi.createDataSetTemplate(
        this.dataSetId,
        this.currentTemplate
      );
    }
    return Promise.reject();
  }

  @action.bound
  private setDatasetTemplates(dsTemplates: IDsTemplate[]): void {
    this.templates = dsTemplates;
  }

  @action.bound
  public setSelectedTemplate(dsTemplate: IDsTemplate): void {
    this.selectedTemplate = dsTemplate;
  }

  @computed
  public get getSelectedTemplate(): IDsTemplate | null {
    return this.selectedTemplate;
  }

  @action.bound
  public clearSelectedTemplate(): void {
    this.selectedTemplate = null;
  }

  @action.bound
  clear(): void {
    this.templates = [];
    this.dataSetId = null;
    this.dsColumns = [];
    this.selectedTemplate = null;
  }
}

export default DataSetTemplateStore;
