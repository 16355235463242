import { ConnectorDto } from "../../api/dto/connector/ConnectorDto";
import { ConnectorUpdateDto } from "../../api/dto/connector/ConnectorUpdateDto";
import * as Models from "../../data/AppModels";
import {
  ConnectorAttributeDto,
  ConnectorAttributeResult,
  IPageableResultBase,
} from "../../data/AppModels";
import RestApi from "../../services/RestApi";

export interface ConnectorStoreTransport {
  createConnector: (dtoU: ConnectorUpdateDto) => Promise<string>;
  updateConnector: (dtoU: ConnectorUpdateDto, id: string) => Promise<void>;
  getConnectors: (
    perUser?: boolean
  ) => Promise<IPageableResultBase<ConnectorDto>>;
  getConnector: (connectorId: string) => Promise<ConnectorDto>;
  deleteConnector: (id: string) => Promise<void>;

  getConnectorAttributes: (
    connectorId: string | string[] | undefined,
    forUser: boolean | undefined
  ) => Promise<IPageableResultBase<ConnectorAttributeDto>>;
  createConnectorAttributes: (
    dto: Models.ConnectorAttributeUpdateDto
  ) => Promise<string>;
  updateConnectorAttribute: (
    dto: Models.ConnectorAttributeUpdateDto,
    connectorAttributeId: string
  ) => Promise<void>;
  deleteConnectorAttribute: (id: string) => Promise<void>;
}

export function createConnectorStoreTransport(): ConnectorStoreTransport {
  return {
    createConnector,
    updateConnector,
    getConnectors,
    getConnector,
    deleteConnector,
    getConnectorAttributes,
    createConnectorAttributes,
    deleteConnectorAttribute,
    updateConnectorAttribute,
  };
}

function getConnectors(perUser?: boolean) {
  return RestApi.doGet<IPageableResultBase<ConnectorDto>>("/v1/connectors", {
    pageable: true,
    params: {
      "per-user": perUser,
      sort: "name,asc",
    },
  });
}

function createConnector(dtoU: ConnectorUpdateDto) {
  return RestApi.doPost<string>("/v1/connectors", dtoU);
}

function updateConnector(dto: ConnectorUpdateDto, connectorId: string) {
  return RestApi.doPut<void>(`/v1/connectors/${connectorId}`, dto, {
    returnRawData: true,
  });
}

function getConnector(connectorId: string) {
  return RestApi.doGet<ConnectorDto>(`/v1/connectors/${connectorId}`);
}

function deleteConnector(connectorId: string) {
  return RestApi.doDelete(`/v1/connectors/${connectorId}`);
}

//* *** CONNECTOR ATTRIBUTES

function getConnectorAttributes(
  connectorId: string | string[] | undefined,
  forUser: boolean | undefined
) {
  return RestApi.doGet<ConnectorAttributeResult>("/v1/connector-attributes", {
    pageable: true,
    params: {
      connectors: Array.isArray(connectorId) ? connectorId.join() : connectorId,
      "for-user": forUser,
    },
  });
}

function createConnectorAttributes(dto: Models.ConnectorAttributeUpdateDto) {
  return RestApi.doPost<string>("/v1/connector-attributes", dto);
}

function deleteConnectorAttribute(id: string) {
  return RestApi.doDelete(`/v1/connector-attributes/${id}`);
}

function updateConnectorAttribute(
  dto: Models.ConnectorAttributeUpdateDto,
  connectorAttributeId: string
) {
  return RestApi.doPut<void>(
    `/v1/connector-attributes/${connectorAttributeId}`,
    dto,
    { returnRawData: true }
  );
}
