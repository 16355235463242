import React, { useState } from "react";

import { confirmAlert, ReactConfirmAlertProps } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

type ButtonProps = {
  label: string;
  type: "confirm" | "cancel";
  onClick: () => void;
};

interface IProps {
  title?: string;
  message?: string;
  buttons?: Array<ButtonProps>;
}

type CustomUIProps = {
  title?: string;
  message?: string;
  onClose: () => void;
  buttons?: Array<ButtonProps>;
};

function CustomUI({ title, message, onClose, buttons }: CustomUIProps) {
  const [open, setOpen] = useState(true);
  const toggle = () => {
    if (open) {
      setTimeout(() => onClose(), 300); // wait for Modal fade-out
    }
    setOpen(!open);
  };

  return (
    <Modal className="modal-dialog-centered" isOpen={open}>
      {title && <ModalHeader>{title}</ModalHeader>}
      {message && (
        <ModalBody>
          <p>{message}</p>
        </ModalBody>
      )}
      {buttons && (
        <ModalFooter>
          {buttons.map((btn, i) => (
            <Button
              key={i}
              color={btn.type === "confirm" ? "primary" : "dark"}
              outline={btn.type === "cancel"}
              onClick={() => {
                toggle();
                btn.onClick();
              }}
            >
              {btn.label}
            </Button>
          ))}
        </ModalFooter>
      )}
    </Modal>
  );
}

export function confirm(options: IProps) {
  const defaultProps: ReactConfirmAlertProps = {
    closeOnEscape: true,
    closeOnClickOutside: true,
    customUI: ({ title, message, onClose }) => (
      <CustomUI
        title={title}
        message={message}
        onClose={onClose}
        buttons={options.buttons}
      />
    ),
  };

  confirmAlert({ ...options, ...defaultProps });
}
