import { DataResponse } from "../../data/AppModels";
import { ConnectorTypeQueryAttributeDto } from "../../data/dto/type/types";
import RestApi from "../../services/RestApi";

export interface ConnectorTypeQueryAttributesTransport {
  getConnectorTypeQueryAttributes: () => Promise<
    DataResponse<ConnectorTypeQueryAttributeDto[]>
  >;
  getConnectorTypeQueryAttribute: (
    id: string
  ) => Promise<ConnectorTypeQueryAttributeDto>;
}

export function createConnectorTypeQueryAttributesTransport(): ConnectorTypeQueryAttributesTransport {
  return {
    getConnectorTypeQueryAttributes,
    getConnectorTypeQueryAttribute,
  };
}

function getConnectorTypeQueryAttributes() {
  return RestApi.doGet<DataResponse<ConnectorTypeQueryAttributeDto[]>>(
    "/v1/connector-type-query-attributes",
    { returnRawData: true }
  );
}

function getConnectorTypeQueryAttribute(id: string) {
  return RestApi.doGet<ConnectorTypeQueryAttributeDto>(
    `/v1/connector-type-query-attributes/${id}`
  );
}
