import { action, computed, configure, observable } from "mobx";

import { IInstallation, IInstallationResult } from "../../data/AppModels";
import RestApi from "../../services/RestApi";
import BasicStore from "../BasicStore";

export interface IInstallationStore extends BasicStore {
  openedInstallation: IInstallation | undefined;
  installations: IInstallation[];
  dataLoading: boolean;

  openInstallation(id: string): void;

  closeInstallation(): void;

  fetchInstallations(): Promise<IInstallation[]>;
}

// strict mode
configure({ enforceActions: "observed" });

class InstallationStore implements InstallationStore {
  isInstallationSensitive = () => false;

  @observable openedInstallationData: IInstallation | undefined = undefined;

  @observable installationsData: IInstallation[] = [];

  @observable dataLoadingMark = false;

  @computed
  get openedInstallation(): IInstallation | undefined {
    return this.openedInstallationData;
  }

  @computed
  get installations(): IInstallation[] {
    return this.installationsData;
  }

  @computed
  get dataLoading(): boolean {
    return this.dataLoadingMark;
  }

  @action
  fetchInstallations() {
    this.setLoadingData(true);

    return RestApi.installations().then((data: IInstallationResult) => {
      if (data && data.content) {
        this.setInstallationsData(data.content);
        return data.content;
      }
      return [];
    });
  }

  @action
  openInstallation(id: string) {
    this.setLoadingData(true);
    RestApi.installation(id).then((data: IInstallation) => {
      if (data) {
        this.setOpenedInstallation(data);
      }
    });
  }

  @action
  closeInstallation() {
    this.setOpenedInstallation(undefined);
  }

  @action.bound
  setLoadingData(loading: boolean) {
    this.dataLoadingMark = loading;
  }

  @action.bound
  setOpenedInstallation(data: IInstallation | undefined) {
    this.openedInstallationData = data;
    this.dataLoadingMark = false;
  }

  @action.bound
  setInstallationsData(data: IInstallation[]) {
    this.installationsData = data;
    this.dataLoadingMark = false;
  }

  clear(): void {
    this.installationsData = [];
    this.openedInstallationData = undefined;
    this.dataLoadingMark = false;
  }
}

export default InstallationStore;
