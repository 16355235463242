import {
  IDataResponse,
  IDataSetDataRow,
  IPageableResultBase,
} from "../data/AppModels";
import RestApi from "../services/RestApi";
import { createCRUDApi, CRUDApi, toApiParams } from "./apiCalls";
import { DataSetDto } from "./dto/data-set/DataSetDto";
import DataSetOrigin from "./dto/data-set/DataSetOrigin";
import { DataSetUpdateDto } from "./dto/data-set/DataSetUpdateDto";
import PageToLoad from "./PageToLoad";

export interface DataSetApiFilter {
  installations?: string | string[];
  search?: string;
  widgetTemplateId?: string;
  widgetId?: string; // UUID
  origins?: DataSetOrigin[];
  types?: string[]; // UUID
}

export interface DataSetApi
  extends CRUDApi<DataSetDto, DataSetUpdateDto, DataSetApiFilter> {
  getData: (
    dataSetId: string,
    pageToLoad?: PageToLoad
  ) => Promise<IPageableResultBase<IDataSetDataRow>>;
  recreate: (
    dataSetId: string
  ) => Promise<IDataResponse<{ scriptExecutorJobId: string }>>;
  reRun: (
    dataSetId: string
  ) => Promise<IDataResponse<{ scriptExecutorJobId: string }>>;
}

export default function createDataSetApi(): DataSetApi {
  return {
    ...createCRUDApi<DataSetDto, DataSetUpdateDto, DataSetApiFilter>(
      "v1/data-sets"
    ),
    getData: (dataSetId: string, pageToLoad) =>
      RestApi.doGet<IPageableResultBase<IDataSetDataRow>>(
        `v1/data-sets/${dataSetId}/data`,
        { params: toApiParams(pageToLoad) }
      ),
    recreate: (dataSetId: string) =>
      RestApi.doPut(`/v1/data-sets/${dataSetId}/recreate`, {}),
    reRun: (dataSetId: string) =>
      RestApi.doPut(`/v1/data-sets/${dataSetId}/re-run`, {}),
  };
}
