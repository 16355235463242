import React from "react";

import { Container } from "reactstrap";
import "./Page.scss";
import classNames from "classnames";

export interface IProps {
  fluid?: boolean;
  fullHeight?: boolean;
  className?: string | object;
  onClick?: () => void;
}

class Page extends React.Component<IProps, any> {
  static defaultProps = {
    fluid: true,
  };

  render() {
    return (
      <Container
        fluid={this.props.fluid}
        className={classNames(
          "page-container",
          this.props.fullHeight ? "page-full-height" : false,
          this.props.className
        )}
        onClick={this.props.onClick}
      >
        {this.props.children}
      </Container>
    );
  }
}

export default Page;
