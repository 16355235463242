import { IBreadcrumbItem } from "../../data/AppModels";

import React from "react";

import classNames from "classnames";

import "./PageBreadcrumb.scss";
import { Link } from "react-router-dom";

export interface IProps {
  breadcrumb?: IBreadcrumbItem[];
  className?: string;
}

class PageBreadcrumb extends React.Component<IProps, any> {
  render() {
    const size = this.props.breadcrumb ? this.props.breadcrumb.length : 0;

    // @ts-ignore
    const items = this.props.breadcrumb.map((item, index) => {
      if (size === index + 1) {
        return (
          <li className="breadcrumb-item" key="breadcrumb-last-item">
            <span className={size > 1 ? "last" : ""}>{item.name}</span>
          </li>
        );
      }
      return (
        <li className="breadcrumb-item" key={index}>
          <Link to={item.link} className="breadcrumb-link">
            {item.name}
          </Link>
        </li>
      );
    });

    return (
      <div className={classNames("page-breadcrumb", this.props.className)}>
        <ul>{items}</ul>
      </div>
    );
  }
}

export default PageBreadcrumb;
