enum DataSetOrigin {
  SCRIPT = "SCRIPT",
  UPLOADED_FILE = "UPLOADED_FILE",
  GOOGLE_DRIVE = "GOOGLE_DRIVE",
  CUSTOM = "CUSTOM",
  MERGED = "MERGED",
  CONNECTOR_QUERY = "CONNECTOR_QUERY",
  ASSISTANT = "ASSISTANT",
}

export default DataSetOrigin;
