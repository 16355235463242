import React from "react";

import { Table } from "reactstrap";

import * as Models from "../../data/AppModels";
import CommonWidget from "./CommonWidget";

export default class TableWidget extends CommonWidget {
  createRows = (table: Models.ITable) => {
    const rows: JSX.Element[] = [];
    const tableRows = table.rows;
    let rowKey = 1;
    if (tableRows && tableRows.length !== 0) {
      tableRows.forEach((row) => {
        const rowTds: JSX.Element[] = [];
        let colKey = 1;
        row.cells.forEach((cell) => {
          rowTds.push(<td key={`${rowKey}.${colKey}`}>{cell.value}</td>);
          colKey++;
        });
        rows.push(<tr key={rowKey}>{rowTds}</tr>);
        rowKey++;
      });
    }

    return rows;
  };

  createTableCard = (table: Models.ITable | null) => {
    if (!table) {
      return null;
    }

    let tableComponent: JSX.Element | null = null;
    if (table.columns && table.columns.length !== 0) {
      const tableCols = this.createTableColumns(table);
      const tableRows = this.createRows(table);

      tableComponent = (
        <Table responsive size="sm">
          <thead>{tableCols}</thead>
          <tbody>{tableRows}</tbody>
        </Table>
      );
    }
    return tableComponent;
  };

  createTableColumns = (table: Models.ITable) => {
    const columns = [];
    const tableColumns = table.columns;
    if (tableColumns && tableColumns.length !== 0) {
      const rowThs: JSX.Element[] = [];
      tableColumns.forEach((col) => {
        rowThs.push(<th key={col.name}>{col.name}</th>);
      });
      // @ts-ignore
      columns.push(<tr key={0}>{rowThs}</tr>);
    }
    return columns;
  };

  createCardBody = () => this.createTableCard(this.state.tableData);
}
