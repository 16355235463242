import React from "react";

import { useTranslation } from "react-i18next";
// @ts-ignore
import { ReactComponent as GoogleDriveSvg } from "simple-icons/icons/googledrive.svg";
// @ts-ignore
import { ReactComponent as PythonSvg } from "simple-icons/icons/python.svg";

import "./TypeIcon.scss";
import DataSetOrigin from "../../../api/dto/data-set/DataSetOrigin";

export enum WidgetType {
  CATALOGUE,
  FILE_UPLOAD,
  GOOGLE_DRIVE,
}

export interface WidgetTypeIconProps {
  widgetType: WidgetType;
}

export function WidgetTypeIcon({ widgetType }: WidgetTypeIconProps) {
  let iconComponent;
  switch (widgetType) {
    case WidgetType.CATALOGUE:
      iconComponent = <i className="icon-book-open" />;
      break;
    case WidgetType.FILE_UPLOAD:
      iconComponent = <i className="icon-cloud-upload" />;
      break;
    case WidgetType.GOOGLE_DRIVE:
      iconComponent = <GoogleDriveSvg style={{ fill: "currentcolor" }} />;
      break;
    default:
      iconComponent = <i className="icon-question" />;
      break;
  }

  return <span className="type-icon">{iconComponent}</span>;
}

export interface DataSetOriginIconProps {
  origin: DataSetOrigin;
  tooltip?: boolean;
}

export function DataSetOriginIcon({ origin, tooltip }: DataSetOriginIconProps) {
  const { t } = useTranslation();

  let iconComponent;
  switch (origin) {
    case DataSetOrigin.CONNECTOR_QUERY:
      iconComponent = <i className="icon-link" />;
      break;
    case DataSetOrigin.CUSTOM:
      iconComponent = <i className="icon-pencil" />;
      break;
    case DataSetOrigin.UPLOADED_FILE:
      iconComponent = <i className="icon-cloud-upload" />;
      break;
    case DataSetOrigin.SCRIPT:
      iconComponent = <PythonSvg style={{ fill: "currentcolor" }} />;
      break;
    case DataSetOrigin.GOOGLE_DRIVE:
      iconComponent = <GoogleDriveSvg style={{ fill: "currentcolor" }} />;
      break;
    case DataSetOrigin.MERGED:
      iconComponent = <i className="icon-shuffle" />;
      break;
    case DataSetOrigin.ASSISTANT:
      iconComponent = <i className="icon-bubbles" />;
      break;
    default:
      iconComponent = <i className="icon-question" />;
      break;
  }

  return (
    <span className="type-icon">
      {iconComponent}
      {tooltip === true && (
        <span className="tooltip-anchor" data-tip={t(`ds.origin.${origin}`)} />
      )}
    </span>
  );
}
