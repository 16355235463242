import React from "react";

import ChartDataLabels from "chartjs-plugin-datalabels";
import { LabelOptions } from "chartjs-plugin-datalabels/types/options";
import * as _ from "lodash";
import {
  Bar,
  Bubble,
  Doughnut,
  HorizontalBar,
  Line,
  Pie,
  Polar,
  Radar,
  Scatter,
} from "react-chartjs-2";

import * as Models from "../../data/AppModels";
import { ChartType } from "../../data/AppModels";
import AppUtils from "../../data/AppUtils";
import { generateLabels } from "./chart/ChartUtils";
import CommonWidget, { IProps as CommonWidgetProps } from "./CommonWidget";

interface IChartOptions {
  tooltips: any;
  legend: any;
  layout?: any;
  maintainAspectRatio: any;
  onResize: any;
  onHover: any;
  events: any;
  cursorIsIn: boolean;
  idActivePart: any;
  activeColor: any;
  scales: any;
  plugins: any;
}

export default class ChartWidget extends CommonWidget {
  chartRef: any;

  chartInstance: any;

  constructor(properties: CommonWidgetProps) {
    super(properties);
    this.chartInstance = null;
  }

  createCardBody = () => (
    <div className="chart-wrapper">
      {this.createChart(this.state.chartData)}
    </div>
  );

  setChartReference = (reference: any) => {
    this.chartRef = reference;

    if (reference && reference.chartInstance) {
      this.chartUpdateBySize(reference.chartInstance);
    }
  };

  chartUpdateBySize = (chart: any) => {
    // console.log("chart.onResize", chart);
    if (_.isNil(chart)) {
      return;
    }
    if (chart.config.type !== "pie") {
      return;
    }
    const { width } = chart;
    const { height } = chart;
    const { options } = chart;
    if (width > 300 || height > 300) {
      options.legend.display = true;
    } else {
      options.legend.display = false;
    }
    if (width > height) {
      options.legend.position = "right";
    } else {
      options.legend.position = "bottom";
    }
    chart.options = options;
    chart.update();
  };

  // @todo - mark hovered part rather by border color than by setting it yellow
  // chartData.datasets[0].borderColor[data[0]["_index"]] = '#aaa';
  chartChangeByColor = (
    mouseEvent: any,
    data: any,
    options: any,
    chartData: any
  ) => {
    if (mouseEvent.type === "mousemove" && data.length > 0) {
      if (data[0]._chart.config.type === "pie") {
        this.chartInstance = data[0]._chart.chart;
        if (!options.cursorIsIn || data[0]._index !== options.idActivePart) {
          options.cursorIsIn = true;
          if (options.idActivePart !== -1) {
            chartData.datasets[0].backgroundColor[options.idActivePart] =
              options.activeColor;
            options.activeColor =
              chartData.datasets[0].backgroundColor[data[0]._index];
          } else {
            options.activeColor =
              chartData.datasets[0].backgroundColor[data[0]._index];
          }
          chartData.datasets[0].backgroundColor[data[0]._index] =
            AppUtils.ACTIVE_CHART_COLOR;
          options.idActivePart = data[0]._index;
          this.chartInstance.update();
        }
      } else {
      }
    } else if (options.cursorIsIn) {
      options.cursorIsIn = false;
      chartData.datasets[0].backgroundColor[options.idActivePart] =
        options.activeColor;
      // options.idActivePart = -1;
      // options.activeColor = "";
      if (this.chartInstance !== null) {
        this.chartInstance.update();
      }
    }
  };

  createChart = (chartData: Models.ChartDataDto | null) => {
    if (!chartData) {
      return;
    }

    const parent = this;
    // color of hover part in pie chart
    const options: IChartOptions = {
      tooltips: {
        enabled: true,
      },
      activeColor: "",
      idActivePart: -1,
      cursorIsIn: false,
      legend: {
        display: true,
        labels: {
          boxWidth: 16,
          // fontColor: "#000000"
        },
      },
      scales: {},
      plugins: {
        datalabels: {},
      },
      events: ["mousemove", "mouseout", "click"],
      maintainAspectRatio: false,
      onResize: _.debounce(function (chart: any) {
        parent.chartUpdateBySize(chart);
      }, 250),
      onHover: (mouseEvent: any, data: any) => {
        parent.chartChangeByColor(mouseEvent, data, options, chartData);
      },
    };

    // activate data labels plugin
    const plugins: any = [ChartDataLabels];

    let chart: JSX.Element | undefined;
    // let chartData: Models.IChartData = this.state.chartData;

    // @todo - consider element.borderWidth = 1 for better readability
    if (chartData.chartType === Models.ChartType.PIE) {
      // console.log(chartData);
      options.legend.labels.generateLabels = generateLabels;
      options.layout = { padding: { bottom: 5 } };
      chartData.datasets.forEach((element) => {
        for (let index = 0; index < element.data.length; index++) {
          // element.hoverBackgroundColor.push(activePartColor);
        }
      });
    }

    // scale from 0
    if (
      ChartType.BAR === chartData.chartType ||
      ChartType.HORIZONTAL_BAR === chartData.chartType ||
      ChartType.LINE === chartData.chartType
    ) {
      options.scales = {
        yAxes: [
          {
            // stacked: true,
            ticks: {
              beginAtZero: chartData.chartOptions?.scaleFromZero,
              // suggestedMin: 10,
              // suggestedMax: 100,
              // min: 0
            },
          },
        ],
        xAxes: [
          {
            // stacked: true,
            ticks: {
              beginAtZero: chartData.chartOptions?.scaleFromZero,
              // suggestedMin: 10,
              // suggestedMax: 100,
              // min: 0
            },
          },
        ],
      };
    }

    // label options
    if (
      ChartType.BAR === chartData.chartType ||
      ChartType.HORIZONTAL_BAR === chartData.chartType
    ) {
      if (chartData.chartOptions?.displayValues) {
        const labelOptions: LabelOptions = {
          align: "start",
          color: "white",
          anchor: "end",
          clamp: true,
          clip: true,
          display: true,
        };

        options.plugins.datalabels = labelOptions;
      } else {
        options.plugins.datalabels = {
          display: false,
        };
      }
    } else {
      options.plugins.datalabels = {
        display: false,
      };
    }

    // if (Models.ChartType.BAR===chartData.chartType || Models.ChartType.HORIZONTAL_BAR===chartData.chartType){
    //   options.scales = {
    //     xAxes: [{
    //       // stacked: true,
    //       // gridLines: {
    //       //   offset: true,
    //       //   offsetGridLines: true,
    //       //   circular: true,
    //       //   lineWidth: 5
    //       // }
    //     }],
    //     yAxes: [{
    //       // stacked: true,
    //       ticks: {
    //         beginAtZero: true,
    //         // suggestedMin: 10,
    //         // suggestedMax: 100,
    //         // min: 0
    //       }
    //     }]
    //   }
    // }

    if (chartData) {
      const data = {
        labels: chartData.labels,
        datasets: chartData.datasets,
      };

      switch (chartData.chartType) {
        case ChartType.BAR:
          chart = (
            <Bar
              data={data}
              options={options}
              plugins={plugins}
              ref={this.setChartReference}
            />
          );

          break;

        case ChartType.PIE:
          chart = (
            <Pie
              data={data}
              options={options}
              plugins={plugins}
              ref={this.setChartReference}
            />
          );

          break;
        case ChartType.LINE:
          chart = (
            <Line
              data={data}
              options={options}
              plugins={plugins}
              ref={this.setChartReference}
            />
          );

          break;
        case ChartType.DOUGHT:
          chart = (
            <Doughnut
              data={data}
              options={options}
              plugins={plugins}
              ref={this.setChartReference}
            />
          );

          break;
        case ChartType.SCATTER:
          chart = (
            <Scatter
              data={data}
              options={options}
              plugins={plugins}
              ref={this.setChartReference}
            />
          );

          break;
        case ChartType.HORIZONTAL_BAR:
          chart = (
            <HorizontalBar
              data={data}
              options={options}
              plugins={plugins}
              ref={this.setChartReference}
            />
          );

          break;
        case ChartType.RADAR:
          chart = (
            <Radar
              data={data}
              options={options}
              plugins={plugins}
              ref={this.setChartReference}
            />
          );

          break;
        case ChartType.POLAR:
          chart = (
            <Polar
              data={data}
              options={options}
              plugins={plugins}
              ref={this.setChartReference}
            />
          );

          break;
        case ChartType.BUBBLE:
          chart = (
            <Bubble
              data={data}
              options={options}
              plugins={plugins}
              ref={this.setChartReference}
            />
          );

          break;
      }
    }
    return chart;
  };
}
