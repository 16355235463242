import { useCallback } from "react";

import { useObserver } from "mobx-react";

import { IUser, Workspace, WorkspaceConnection } from "../data/AppModels";
import {
  EntityRelationType,
  IAbstractEntityRelationStore,
} from "./EntityRelationStores";
import {
  useEntityRelationStore,
  useUserStore,
  useWorkspaceStore,
} from "./useStores";

export const getConnectionEntityKey = (type?: EntityRelationType) => {
  if (type) {
    switch (type) {
      case EntityRelationType.WORKSPACE_CONNECTOR:
        return "connectorId";
      case EntityRelationType.WORKSPACE_DASHBOARD:
        return "dashboardId";
      case EntityRelationType.WORKSPACE_DATASET:
        return "dataSetId";
      case EntityRelationType.WORKSPACE_DATASOURCE:
        return "dataSourceId";
      case EntityRelationType.WORKSPACE_MEMBER:
        return "usersId";
      default:
        break;
    }
  }
  return undefined;
};

export function useRelatedWorkspaceIds(type?: EntityRelationType) {
  const entityRelationStore =
    useEntityRelationStore<IAbstractEntityRelationStore<WorkspaceConnection>>(
      type
    );
  const { connections } = useObserver(() => ({
    connections: entityRelationStore?.entities || [],
  }));
  return useCallback(
    (entityId: string) => {
      const connectionEntityKey = getConnectionEntityKey(type);
      if (connectionEntityKey && entityId.length > 0) {
        entityRelationStore?.findRelationByEntityId(entityId);
        return connections
          .filter((connection) => connection[connectionEntityKey] === entityId)
          .map((connection) => connection.workspaceId);
      }
      return [];
    },
    [connections, entityRelationStore, type]
  );
}

export function useEntitiesRelatedToWorkspace(type?: EntityRelationType) {
  const entityRelationStore =
    useEntityRelationStore<IAbstractEntityRelationStore<WorkspaceConnection>>(
      type
    );
  const { connections } = useObserver(() => ({
    connections: entityRelationStore?.entities || [],
  }));
  return useCallback(
    (workspaceId: string) => {
      const connectionEntityKey = getConnectionEntityKey(type);
      if (connectionEntityKey) {
        return connections
          .filter((connection) => connection.workspaceId === workspaceId)
          .map((connection) => connection[connectionEntityKey]);
      }
      return [];
    },
    [connections, type]
  );
}

export type EntityWorkspaceRelation = {
  workspace: Workspace;
  members: IUser[];
};

export default function useEntityWorkspaceRelation(
  type: EntityRelationType | undefined
) {
  const workspaceStore = useWorkspaceStore();
  const userStore = useUserStore();
  const { getWorkspace, getUser } = useObserver(() => ({
    getWorkspace: workspaceStore?.getOne,
    getUser: userStore?.getOne,
  }));
  const getRelatedWorkspacesIds = useRelatedWorkspaceIds(type);
  const getRelatedMemberIds = useEntitiesRelatedToWorkspace(
    EntityRelationType.WORKSPACE_MEMBER
  );

  return useCallback(
    (entityId: string | undefined) => {
      const relatedWorkspaceIds = getRelatedWorkspacesIds(entityId || "");

      const relatedWorkspaces: EntityWorkspaceRelation[] = [];
      if (getWorkspace) {
        relatedWorkspaceIds
          .map((workspaceId) => getWorkspace(workspaceId, true))
          .forEach((workspace) => {
            if (workspace) {
              const memberIds = getRelatedMemberIds(workspace.id);
              const members = getUser
                ? (memberIds
                    .map((memberId) => getUser(memberId, true))
                    .filter((member) => member !== null) as IUser[])
                : [];
              relatedWorkspaces.push({
                workspace,
                members,
              });
            }
          });
      }

      // create an array with users where each user is only once (union of all member lists in all related workspaces)
      const relatedUsers = [
        ...relatedWorkspaces
          .flatMap((relatedWorkspace) => relatedWorkspace.members)
          .reduce(
            (map, user) => map.set(user.id, user),
            new Map<string, IUser>()
          )
          .values(),
      ];

      return {
        workspaces: relatedWorkspaces,
        users: relatedUsers,
      };
    },
    [getWorkspace, getUser, getRelatedWorkspacesIds, getRelatedMemberIds]
  );
}
