import { ConnectorTypeDto } from "../../data/dto/type/types";
import { AbstractStore, EntityFilter, IAbstractStore } from "../AbstractStore";
import StoreNames from "../storeNames";
import { ConnectorTypesTransport } from "./connectorTypesTransport";

export type IConnectorTypesStore = IAbstractStore<
  ConnectorTypeDto,
  EntityFilter
>;

export default class ConnectorTypesStore
  extends AbstractStore<ConnectorTypeDto, EntityFilter>
  implements IConnectorTypesStore
{
  transport: ConnectorTypesTransport;

  constructor(transport: ConnectorTypesTransport) {
    super(StoreNames.CONNECTOR_TYPES);

    this.transport = transport;
  }

  apiFetchAll = async () => this.transport.getConnectorTypes();

  apiFetchOne = async (id: string) => this.transport.getConnectorType(id);
}
