import { DataResponse } from "../../data/AppModels";
import { ConnectorTypeQueryDto } from "../../data/dto/type/types";
import RestApi from "../../services/RestApi";

export interface ConnectorTypeQueriesTransport {
  getConnectorTypeQueries: () => Promise<DataResponse<ConnectorTypeQueryDto[]>>;
  getConnectorTypeQuery: (id: string) => Promise<ConnectorTypeQueryDto>;
}

export function createConnectorTypeQueriessTransport(): ConnectorTypeQueriesTransport {
  return {
    getConnectorTypeQuery,
    getConnectorTypeQueries,
  };
}

function getConnectorTypeQueries() {
  return RestApi.doGet<DataResponse<ConnectorTypeQueryDto[]>>(
    "/v1/connector-type-queries",
    { returnRawData: true }
  );
}

function getConnectorTypeQuery(id: string) {
  return RestApi.doGet<ConnectorTypeQueryDto>(
    `/v1/connector-type-queries/${id}`
  );
}
