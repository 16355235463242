import { DataResponse } from "../../data/AppModels";
import { ConnectorTypeDto } from "../../data/dto/type/types";
import RestApi from "../../services/RestApi";

export interface ConnectorTypesTransport {
  getConnectorTypes: () => Promise<DataResponse<ConnectorTypeDto[]>>;
  getConnectorType: (id: string) => Promise<ConnectorTypeDto>;
}

export function createConnectorTypesTransport(): ConnectorTypesTransport {
  return {
    getConnectorType,
    getConnectorTypes,
  };
}

function getConnectorTypes() {
  return RestApi.doGet<DataResponse<ConnectorTypeDto[]>>(
    "/v1/connector-types",
    { returnRawData: true }
  );
}

function getConnectorType(id: string) {
  return RestApi.doGet<ConnectorTypeDto>(`/v1/connector-types/${id}`);
}
