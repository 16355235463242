// strict mode
import { action, computed, configure, observable } from "mobx";

import {
  ITaskData,
  ITaskItemData,
  ITaskItemDataResult,
} from "../data/AppModels";
import RestApi from "../services/RestApi";
import BasicStore from "./BasicStore";

configure({ enforceActions: "observed" });

export interface ITasksStore extends BasicStore {
  taskItems: ITaskItemData[];

  fetchData(): void;

  markAsInProgress(id: string): void;

  markAsCompleted(id: string): void;

  createTask(data: ITaskData): void;
}

class TasksStore implements ITasksStore {
  isInstallationSensitive = () => true;

  @observable tasks: ITaskItemData[] = [];

  @computed
  get taskItems(): ITaskItemData[] {
    return this.tasks;
  }

  @action
  fetchData(): void {
    RestApi.loadTasksV1().then((data: ITaskItemDataResult) => {
      this.setTaskListData(data);
    });
  }

  @action.bound
  setTaskListData(data: ITaskItemDataResult) {
    this.tasks = data.content;
  }

  @action
  markAsCompleted(id: string): void {
    RestApi.taskCompleteV1(id).then(() => {
      this.fetchData();
    });
  }

  @action
  markAsInProgress(id: string): void {
    RestApi.taskInProgressV1(id).then(() => {
      this.fetchData();
    });
  }

  @action
  createTask(data: ITaskData): void {
    RestApi.createTaskV1(data).then(() => {
      this.fetchData();
    });
  }

  clear(): void {
    this.tasks = [];
  }
}

export default TasksStore;
