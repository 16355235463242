import { ConnectorDto } from "../../api/dto/connector/ConnectorDto";
import {
  ConnectorAttributeDto,
  ConnectorAttributeUpdateDto,
  ConnectorTypeAttributeDto,
} from "../../data/AppModels";
import AbstractEntityStore, {
  IAbstractEntityStore,
} from "../AbstractEntityStore";
import { EntityFilter } from "../AbstractStore";
import StoreNames from "../storeNames";
import { ConnectorStoreTransport } from "./connectorTransports";
import ConnectorTypeAttributeStore from "./ConnectorTypeAttributeStore";
import UserConnector from "./domain/UserConnector";
import UserConnectorAttribute from "./domain/UserConnectorAttribute";

export type IUserConnectorStore = IAbstractEntityStore<
  UserConnector,
  UserConnector,
  EntityFilter
>;

export default class UserConnectorStore
  extends AbstractEntityStore<UserConnector, UserConnector, EntityFilter>
  implements IUserConnectorStore
{
  installationSensitive = true;

  replaceOnDelete = true;

  connectorTypeAttributesStore: ConnectorTypeAttributeStore;

  transport: ConnectorStoreTransport;

  constructor(
    connectorTypeAttributesStore: ConnectorTypeAttributeStore,
    transport: ConnectorStoreTransport
  ) {
    super(StoreNames.USER_CONNECTORS);
    this.connectorTypeAttributesStore = connectorTypeAttributesStore;
    this.transport = transport;
  }

  createUserConnector = (
    dto: ConnectorDto,
    attributes: ConnectorAttributeDto[],
    types: ConnectorTypeAttributeDto[]
  ) => {
    const connectorAttributes: UserConnectorAttribute[] = [];
    // pick all types which can be defined by user
    types
      .filter(
        (at) =>
          at.connectorTypeId === dto.connectorTypeId && at.userDefined === true
      )
      .forEach((cat) => {
        // find existing or create empty
        const attribute = attributes.find(
          (a) =>
            a.connectorId === dto.id && a.connectorTypeAttributeId === cat.id
        );
        if (attribute) {
          connectorAttributes.push({
            ...attribute,
            type: attribute.connectorTypeAttribute,
            changed: false,
          });
        } else {
          connectorAttributes.push({
            id: "",
            type: cat,
            installationId: dto.installationId,
            changed: false,
          });
        }
      });

    const userConnector: UserConnector = {
      ...dto,
      attributes: connectorAttributes,
    };

    console.info(`created user connector :: ${JSON.stringify(userConnector)}`);

    return userConnector;
  };

  apiFetchAll = async () => {
    const page = await this.transport.getConnectors(true);
    const connectors = page.content;
    const connectorIds = connectors.map((c) => c.id);

    // get created attributes
    const attributes = (
      await this.transport.getConnectorAttributes(connectorIds, true)
    ).content;
    // get types to create schema
    const attributeTypes =
      (await this.connectorTypeAttributesStore?.all()) || [];
    const usersConnectors: UserConnector[] = [];
    connectors.forEach((connector) => {
      usersConnectors.push(
        this.createUserConnector(connector, attributes, attributeTypes)
      );
    });

    return {
      ...page,
      content: usersConnectors,
    };
  };

  apiCreate = (data: unknown) => {
    throw new Error("unsupported operation");
  };

  apiDelete = async (id: string) => {
    const entity = this.entities.find((e) => e.id === id);

    if (entity) {
      const promises: Promise<any>[] = [];
      entity.attributes
        .filter((attr) => attr.id)
        .forEach((attribute) => {
          promises.push(this.transport.deleteConnectorAttribute(attribute.id));
        });

      await Promise.all(promises);
    }
  };

  apiFetchOne = async (id: string) => {
    const connectorDto = await this.transport.getConnector(id);
    const attributes = (await this.transport.getConnectorAttributes(id, true))
      .content;
    // get types to create schema
    const attributeTypes =
      (await this.connectorTypeAttributesStore?.all()) || [];

    return this.createUserConnector(connectorDto, attributes, attributeTypes);
  };

  apiUpdate = async (id: string, updateData: UserConnector) => {
    const promises: Promise<any>[] = [];
    console.info(
      `Store update :: items :: ${JSON.stringify(
        updateData.attributes.filter((attr) => attr.changed)
      )}`
    );
    // just update attributes
    updateData.attributes
      .filter((attr) => attr.changed)
      .forEach((attr) => {
        const dtoU: ConnectorAttributeUpdateDto = {
          forUser: true,
          connectorId: updateData.id,
          connectorTypeAttributeId: attr.type.id,
          value: attr.value,
        };
        console.info(`dto :: ${JSON.stringify(dtoU)}`);
        // update
        if (attr.id !== "") {
          promises.push(this.transport.updateConnectorAttribute(dtoU, attr.id));
        } else {
          promises.push(this.transport.createConnectorAttributes(dtoU));
        }
      });
    await Promise.all(promises);
    return Promise.resolve(undefined);
  };

  updateEntity = (
    entity: UserConnector | undefined,
    id: string,
    updateData: UserConnector
  ) => undefined; // force fetch

  newEntity = (id: string, createData: UserConnector) => {
    throw new Error("unsupported operation");
  };
}
