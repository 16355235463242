import { action, computed, configure, observable } from "mobx";

import { DataResponse, Entity, IPageableResultBase } from "../data/AppModels";
import BasicStore from "./BasicStore";

export interface IAbstractStore<T extends Entity, F extends EntityFilter>
  extends BasicStore {
  name: string;
  entities: T[];

  /**
   * return all fetched, or call api, store and return stored
   */
  all(): Promise<T[]>;

  fetchAll(filter?: F): Promise<T[]>;

  getOne(id: string, fetchIfMissing?: boolean): T | null;

  fetchOne(id: string): Promise<T>;
}

// strict mode
configure({ enforceActions: "observed" });

export interface EntityFilter {}

export abstract class AbstractStore<T extends Entity, F extends EntityFilter>
  implements IAbstractStore<T, F>
{
  name: string;

  allFetched = false;

  installationSensitive = false;

  isInstallationSensitive = () => this.installationSensitive;

  fetchingAll: Promise<T[]> | undefined = undefined;

  fetchingOne = new Map<string, Promise<T>>();

  @observable entitiesArray: T[] = [];

  constructor(name: string) {
    this.name = name;
  }

  @computed
  get entities(): T[] {
    if (this.entitiesArray.length === 0 && !this.allFetched) {
      // not fetched yet, do it now
      this.fetchAll();
    }
    return this.entitiesArray;
  }

  @computed
  get getOne(): (id: string, fetchIfMissing?: boolean) => T | null {
    const _entities = this.entitiesArray;
    return (id, fetchIfMissing = true) => {
      const entity = _entities.find((et) => et.id === id);
      if (entity === undefined && fetchIfMissing) {
        this.fetchOne(id);
      }
      return entity || null;
    };
  }

  abstract apiFetchAll: (
    filter?: F
  ) => Promise<IPageableResultBase<T> | DataResponse<T[]>>;

  @action
  _apiFetchAll = async (filter?: F) => {
    const data = await this.apiFetchAll(filter);

    if (data) {
      if ("content" in data) {
        return data.content;
      }
      if ("data" in data) {
        return data.data;
      }
    }

    return [];
  };

  @action
  all = async () => {
    if (this.fetchingAll) {
      console.log(
        this.name,
        "already fetching all => ignoring this fetchAll request"
      );
      return this.fetchingAll;
    }
    if (this.allFetched) {
      return this.entitiesArray;
    }
    const all = await this.fetchAll();
    return all;
  };

  @action
  fetchAll = async (filter?: F) => {
    if (this.fetchingAll) {
      console.log(
        this.name,
        "already fetching all => ignoring this fetchAll request"
      );
      return this.fetchingAll;
    }
    try {
      this.fetchingAll = this._apiFetchAll(filter);
      const entities = await this.fetchingAll;
      this.fetchingAll = undefined;
      this.setEntities(entities);
      this.allFetched = true;
      return entities;
    } catch (error) {
      this.fetchingAll = undefined;
      console.warn(this.name, "could not fetch all", error);
      this.allFetched = true;
      this.setEntities([]);
      throw error;
    }
  };

  abstract apiFetchOne: (id: string) => Promise<T>;

  @action
  fetchOne = async (id: string, forceFetch = false) => {
    if (this.fetchingAll) {
      console.log(
        this.name,
        "already fetching all => ignoring this fetchOne request for id:",
        id
      );
      const all = await this.fetchingAll;
      const entityData = all.find((entity) => entity.id === id);
      if (entityData) {
        return entityData;
      }
      console.warn(this.name, `could not fetch one: ${id}`);
      throw new Error(`could not fetch one: ${id}`);
    }
    const activeFetchPromise = this.fetchingOne.get(id);
    if (activeFetchPromise) {
      console.log(
        this.name,
        "already fetching this one => ignoring this fetchOne request for id:",
        id
      );
      return activeFetchPromise;
    }
    try {
      if (!forceFetch) {
        const entity = this.entitiesArray.find((entity) => entity.id === id);
        if (entity) {
          return entity;
        }
      }
      const fetchPromise = this.apiFetchOne(id);
      this.fetchingOne.set(id, fetchPromise);
      const newEntityData = await fetchPromise;
      this.fetchingOne.delete(id);
      const existingEntity = this.entitiesArray.find(
        (entity) => entity.id === id
      );

      // replace if existed before
      if (existingEntity) {
        const entityIndex = this.entitiesArray.indexOf(existingEntity);
        const _entities = this.entitiesArray.filter(
          (entity) => entity.id !== id
        );
        _entities.splice(entityIndex, 0, newEntityData);
        this.setEntities(_entities);
      } else {
        // add at top
        const _entities = [...this.entitiesArray];
        _entities.splice(0, 0, newEntityData);
        this.setEntities(_entities);
      }

      return newEntityData;
    } catch (error) {
      this.fetchingOne.delete(id);
      console.warn(this.name, `could not fetch one: ${id}`, error);
      throw error;
    }
  };

  @action.bound
  setEntities(entities: T[]) {
    this.entitiesArray = entities;
  }

  @action
  clear(): void {
    this.allFetched = false;
    this.entitiesArray = [];
  }
}
