import React, { useState } from "react";

import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";

export interface Props {
  items: JSX.Element[];
}

const DashboardPageHeaderDropdown = ({ items }: Props) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <Dropdown className="ml-3" isOpen={isOpen} toggle={() => setOpen(!isOpen)}>
      <DropdownToggle>
        <i className="icon-options-vertical" />
      </DropdownToggle>
      <DropdownMenu container={document.body}>{items}</DropdownMenu>
    </Dropdown>
  );
};

export default DashboardPageHeaderDropdown;
