export default class AppConstants {
  // Local storage constants

  static LS_ACCESS_TOKEN = "accessToken";

  static LS_USER_LANGUAGE = "userLanguage";

  static SELECTED_INSTALLATION = "selectedInstallation";

  // Routes

  static RT_ROOT = "/";

  static RT_PASSWORD_RESET = "/pr";

  static RT_EXTERNAL = "/ext";

  static RT_EMBED_WIDGET = "/embed/widget";

  static RT_L = "/layout";

  static RT_L_ERROR_404 = "/layout/error404";

  static RT_L_DASHBOARD = "/layout/dashboard";

  static RT_L_DASHBOARD_VIEW = "/layout/dashboard/view/:id";

  static RT_L_DASHBOARD_PROPERTIES_VIEW = "/layout/dashboard-properties/:id";

  static RT_L_DASHBOARD_CATALOGUES = "/layout/dashboard/catalogues";

  static RT_L_DASHBOARD_CATALOGUES_DETAIL =
    "/layout/dashboard/catalogues/detail/:id";

  static RT_L_DASHBOARD_WIDGET_CONFIG =
    "/layout/dashboard/configwidget/:widgetId";

  static RT_L_DS = "/layout/ds";

  static RT_L_DS_ADD_CUSTOM = "/layout/ds/add/custom";

  static RT_L_DS_ADD_SCRIPT = "/layout/ds/add/script";

  static RT_L_DS_ADD_UPLOAD = "/layout/ds/add/upload";

  static RT_L_DS_ADD_UPLOAD_FILE = "/layout/ds/add/upload/:id";

  static RT_L_DS_ADD_DATASOURCE = "/layout/ds/add/datasource";

  static RT_L_DS_EDIT = "/layout/ds/edit/:id";

  static RT_L_DS_EDIT_SCRIPT = "/layout/ds/edit-script/:id"; // should be the same as above

  static RT_L_DS_CONFIGURE_UPDATE_SCHEDULES =
    "/layout/ds/configure-script-schedules/:id";

  static RT_L_DS_PREVIEW = "/layout/ds/preview/:id";

  static RT_L_DS_LOGS = "/layout/ds/logs/:id";

  static RT_L_DS_PREVIEW_$ = "/layout/ds/preview/:id$:context";

  static RT_L_DS_PREVIEW_JSON = "/layout/ds/preview-json/:id";

  static RT_L_DS_PREVIEW_JSON_$ = "/layout/ds/preview-json/:id$:context";

  static RT_L_DS_DOCGEN = "/layout/ds/docgen/:id";

  static RT_L_DS_CREATEWIDGET = "/layout/ds/createwidget/:datasetId";

  static RT_L_DS_MERGE = "/layout/ds/merge/:datasetIds";

  static RT_L_WORKSPACES = "/layout/workspaces";

  static RT_L_DATA_SOURCE_REST_EDIT = "/layout/datasources/rest/edit/:id";

  static RT_L_WORKSPACES_ADD = "/layout/workspaces/add";

  static RT_L_WORKSPACES_EDIT = "/layout/workspaces/edit/:id";

  static RT_L_WORKSPACES_MEMBERS = "/layout/workspaces/members/:id";

  static RT_L_WORKSPACES_DASHBOARDS = "/layout/workspaces/dashboards/:id";

  static RT_L_WORKSPACES_DATASETS = "/layout/workspaces/datasets/:id";

  static RT_L_WORKSPACES_DATASOURCES = "/layout/workspaces/datasources/:id";

  static RT_L_WORKSPACES_CONNECTORS = "/layout/workspaces/connectors/:id";

  static RT_L_ADMIN = "/layout/admin";

  static RT_L_ADMIN_USERS = "/layout/admin/users";

  static RT_L_ADMIN_USERS_ADD = "/layout/admin/users/add";

  static RT_L_ADMIN_USERS_EDIT = "/layout/admin/users/edit/:id";

  static RT_L_ADMIN_SUPER_USER_EDIT = "/layout/admin/users/superuseredit/:id";

  static RT_L_ADMIN_PG = "/layout/admin/pg";

  static RT_L_ADMIN_PG_ADD = "/layout/admin/pg/add";

  static RT_L_ADMIN_PG_EDIT = "/layout/admin/pg/edit/:id";

  static RT_L_ADMIN_CONNECTORS = "/layout/admin/connectors";

  static RT_L_SYSADMIN = "/layout/sys-admin";

  static RT_L_SYSADMIN_INST = "/layout/sys-admin/inst";

  static RT_L_SYSADMIN_INST_ADD = "/layout/sys-admin/inst/add";

  static RT_L_SYSADMIN_INST_DETAIL = "/layout/sys-admin/inst/detail/:id";

  static RT_L_SYSADMIN_INFO = "/layout/sys-admin/info";

  static RT_L_USER_PROFILE = "/layout/user-profile";

  // static CONFIGURE_WIDGET = '/layout/configureWidget'; // TODO not used

  static joinUrl(url: string, data: {}) {
    let final: string = url;

    for (const property in data) {
      // @ts-ignore
      const value = data[property];
      final = final.replace(`/:${property}`, `/${value}`);
      final = final.replace(`$:${property}`, `$${value}`);
    }

    return final;
  }
}
