import { ConnectorTypeQueryDto } from "../../data/dto/type/types";
import { AbstractStore, EntityFilter, IAbstractStore } from "../AbstractStore";
import StoreNames from "../storeNames";
import { ConnectorTypeQueriesTransport } from "./connectorTypeQueriesTransport";

export type IConnectorTypeQueriesStore = IAbstractStore<
  ConnectorTypeQueryDto,
  EntityFilter
>;

export default class ConnectorTypeQueriesStore
  extends AbstractStore<ConnectorTypeQueryDto, EntityFilter>
  implements IConnectorTypeQueriesStore
{
  transport: ConnectorTypeQueriesTransport;

  constructor(transport: ConnectorTypeQueriesTransport) {
    super(StoreNames.CONNECTOR_TYPE_QUERIES);

    this.transport = transport;
  }

  apiFetchAll = async () => this.transport.getConnectorTypeQueries();

  apiFetchOne = async (id: string) => this.transport.getConnectorTypeQuery(id);
}
