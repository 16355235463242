import React from "react";

import classNames from "classnames";

import { WidgetType } from "../../data/AppModels";
import ChartWidget from "./ChartWidget";
import { IProps as CommonWidgetProps } from "./CommonWidget";
import InfoWidget from "./InfoWidget";
import TableWidget from "./TableWidget";

export interface Props extends CommonWidgetProps {
  id: string;
  widgetType: WidgetType;
}

export function WidgetBody(props: Props) {
  const { widgetType } = props;
  switch (widgetType) {
    case WidgetType.CHART:
      return <ChartWidget {...props} />;
    case WidgetType.TABLE:
      return <TableWidget {...props} />;
    case WidgetType.INFO:
      return <InfoWidget {...props} />;
    default:
      console.log(`unexpected widget with type${widgetType}`, props);
  }
  return null;
}

export function widgetWrapper(
  content: JSX.Element,
  widgetId: string,
  isFullscreen?: boolean
) {
  return (
    <div
      key={widgetId}
      className={classNames("grid-item", isFullscreen ? "fullscreen" : false)}
    >
      {content}
    </div>
  );
}

export default function Widget(props: Props) {
  return widgetWrapper(<WidgetBody {...props} />, props.id, props.isFullscreen);
}
