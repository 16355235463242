import React, { useEffect, useState } from "react";
import "./PageHeader.scss";

import classNames from "classnames";
import {
  Button,
  ButtonProps,
  Input,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";

import { IBreadcrumbItem } from "../../data/AppModels";
import PageBreadcrumb from "./PageBreadcrumb";
import PageHeadline from "./PageHeadline";
import usePageHeaderPadding from "./usePageHeaderPadding";

export interface IProps {
  headline: string;
  customControl?: JSX.Element;
  primaryButton?: ButtonProps | ButtonProps[];
  secondaryButtons?: ButtonProps | ButtonProps[];
  deleteButton?: ButtonProps;
  backButton?: ButtonProps;
  breadcrumb?: IBreadcrumbItem[];
  className?: string;
  headlineEditable?: (newValue: string) => void;
  headlineEditPlaceholder?: string;
}

const wrap = (component: JSX.Element, key?: string) => (
  <div key={key} className="page-header-item">
    {component}
  </div>
);

export default function PageHeader(props: IProps) {
  usePageHeaderPadding();
  const [editing, setEditing] = useState(
    !!props.headlineEditable && props.headline.length === 0
  );
  const [inputValue, setInputValue] = useState(props.headline);
  useEffect(() => {
    setEditing(!!props.headlineEditable && props.headline.length === 0);
    setInputValue(props.headline);
  }, [props.headline, props.headlineEditable]);
  const hasBreadcrumb = props.breadcrumb && props.breadcrumb.length > 0;
  const primaryButtons = props.primaryButton
    ? Array.isArray(props.primaryButton)
      ? props.primaryButton
      : [props.primaryButton]
    : null;
  const secondaryButtons = props.secondaryButtons
    ? Array.isArray(props.secondaryButtons)
      ? props.secondaryButtons
      : [props.secondaryButtons]
    : null;
  return (
    <div
      className={classNames(
        "page-header page-box",
        hasBreadcrumb ? "with-breadcrumb" : false,
        props.className
      )}
    >
      <div className="page-header-left">
        <PageHeadline>
          {props.headlineEditable && !editing && (
            <span className="editable" onClick={() => setEditing(true)}>
              {inputValue || <>&nbsp;</>}
              <i className="icon-pencil" />
            </span>
          )}
          {props.headlineEditable && editing && (
            <InputGroup>
              <Input
                autoFocus
                onChange={(event) => setInputValue(event.target.value)}
                onKeyUp={(event) => {
                  if (event.key === "Enter" && inputValue) {
                    setEditing(false);
                    if (props.headlineEditable)
                      props.headlineEditable(inputValue);
                  }
                }}
                value={inputValue}
                placeholder={props.headlineEditPlaceholder}
              />
              {props.headline && (
                <InputGroupAddon addonType="append">
                  <Button
                    color="secondary"
                    className="btn-icon"
                    outline
                    onClick={() => {
                      setInputValue(props.headline);
                      setEditing(false);
                    }}
                  >
                    <i className="icon-close" />
                  </Button>
                </InputGroupAddon>
              )}
              <InputGroupAddon addonType="append">
                <Button
                  color="primary"
                  className="btn-icon"
                  disabled={!inputValue}
                  onClick={() => {
                    setEditing(false);
                    if (props.headlineEditable)
                      props.headlineEditable(inputValue);
                  }}
                >
                  <i className="icon-check" />
                </Button>
              </InputGroupAddon>
            </InputGroup>
          )}
          {!props.headlineEditable && (
            <span className="text">{props.headline || <>&nbsp;</>}</span>
          )}
        </PageHeadline>
        <div className="line" />
        {hasBreadcrumb && <PageBreadcrumb breadcrumb={props.breadcrumb} />}
      </div>
      <div className="page-header-right">
        {props.customControl && wrap(props.customControl)}
        {primaryButtons &&
          primaryButtons.map((btnProps, i) =>
            wrap(<Button {...btnProps} color="primary" />, `${i}`)
          )}
        {secondaryButtons &&
          secondaryButtons.map((btnProps, i) =>
            wrap(<Button {...btnProps} color="secondary" />, `${i}`)
          )}
        {props.deleteButton &&
          wrap(<Button {...props.deleteButton} outline color="danger" />)}
        {props.backButton &&
          wrap(<Button {...props.backButton} outline color="dark" />)}
      </div>
    </div>
  );
}
