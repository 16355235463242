import React, { Component } from "react";

import classNames from "classnames";
import i18next from "i18next";
import { cloneDeep, isEmpty, isNil } from "lodash";
import { Card, CardBody } from "reactstrap";

import * as Models from "../../data/AppModels";
import {
  FileType,
  IControlsWidgetContext,
  IWidgetContext,
  ParameterType,
} from "../../data/AppModels";
import AppUtils from "../../data/AppUtils";
import WorkspaceEntityOperations from "../../data/WorkspaceEntityOperations";
import RestApi from "../../services/RestApi";
import { confirm } from "../modal/Confirm";
import WidgetControls from "./common/WidgetControls";
import WidgetHeader from "./common/WidgetHeader";

import "./TableWidget.scss";
import "./CommonWidget.scss";
import WidgetPresentationType from "./WidgetPresentationType";

export interface IProps {
  widget: Models.IWidget | Models.IDashboardWidgetExternal;
  doEdit?: (widgetId: string) => void;
  doRemove?: (widgetId: string) => void;
  doShare?: (widgetId: string) => void;
  doSwitchDashboard?: (widgetId: string) => void;
  isPreview?: boolean;
  isFullscreen?: boolean;
  doFullscreen?: any;
  isNew?: boolean;
  presentationType: WidgetPresentationType;
  dragging?: boolean;
  filterKeys?: string[];
}

export interface IState {
  title: string;
  canEdit: boolean;
  canRemove: boolean;
  tableData: Models.ITable | null;
  chartData: Models.ChartDataDto | null;
  infoData: Models.IInfoData | null;
  widgetParameters: Models.IWidgetParameters | null;
  widgetContext: IWidgetContext;
  menuDropdownOpen: boolean;
  fetched: boolean;
}

function isWidgetExternal(object): object is Models.IDashboardWidgetExternal {
  return object.widgetVisualisation !== undefined;
}

export default abstract class CommonWidget extends Component<IProps, IState> {
  state: IState;

  isTable = false;

  mounted = false;

  constructor(properties: IProps, isTable = false) {
    super(properties);

    this.isTable = isTable;

    this.state = {
      title: "",
      canEdit: false,
      canRemove: false,
      tableData: null,
      chartData: null,
      infoData: null,
      widgetParameters: null,
      widgetContext: {
        date: null,
        dateFrom: null,
        dateTo: null,
        fullScreen: this.props.isFullscreen || false,
        filterKeys: undefined,
      },
      menuDropdownOpen: false,
      fetched: false,
    };
  }

  static getDerivedStateFromProps(props: IProps, state: IState) {
    const title = !isWidgetExternal(props.widget)
      ? props.widget.name
      : props.widget.title;
    const hasEditCallback =
      props.doEdit || props.doShare || props.doSwitchDashboard;
    const hasRemoveCallback = props.doRemove;
    const canEdit =
      hasEditCallback &&
      !isWidgetExternal(props.widget) &&
      props.widget.operations.includes(WorkspaceEntityOperations.EDIT);
    const canRemove =
      hasRemoveCallback &&
      !isWidgetExternal(props.widget) &&
      props.widget.operations.includes(WorkspaceEntityOperations.DELETE);

    // filter keys has changed
    const fetch =
      JSON.stringify(props.filterKeys || "") !==
      JSON.stringify(state.widgetContext.filterKeys || "");

    if (
      title !== state.title ||
      canEdit !== state.canEdit ||
      canRemove !== state.canRemove ||
      fetch
    ) {
      return {
        ...state,
        title,
        canEdit,
        canRemove,
        widgetContext: {
          filterKeys: props.filterKeys,
        },
        fetched: !fetch,
      };
    }

    return state;
  }

  componentDidMount() {
    this.mounted = true;
    return this.fetchData();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  updateData = (data: any) => {
    if (!this.mounted) {
      console.log(
        "widget will not update with new data, because it was unmounted -> dropping this data"
      );
      return;
    }

    const state = cloneDeep(this.state);

    state.tableData = data.tableData;
    state.chartData = AppUtils.hackColors(data.chartData);
    state.infoData = data.infoData;

    const date: Date | null = this.findDate(data.widgetParameters);
    const dateFrom: Date | null = this.findDateFrom(data.widgetParameters);
    const dateTo: Date | null = this.findDateTo(data.widgetParameters);

    let isInterval = false;
    let isDate = false;

    let parameter = this.findParameter(
      data.widgetParameters,
      ParameterType.INTERVAL
    );
    if (parameter) {
      isInterval = true;
    }
    parameter = this.findParameter(data.widgetParameters, ParameterType.DATE);
    if (parameter) {
      isDate = true;
    }

    state.widgetParameters = {
      date,
      dateFrom,
      dateTo,
      isInterval,
      isDate,
    };

    this.setState(state);
  };

  fetchData = () => {
    console.log("fetching widget data");
    this.setState({ ...this.state, fetched: true });
    if (this.props.presentationType === WidgetPresentationType.EXTERNAL) {
      RestApi.loadWidgetDataExternalV1(
        this.props.widget.id,
        this.state.widgetContext
      ).then((data) => {
        this.updateData(data);
      });
    } else {
      RestApi.loadWidgetDataV1(
        this.props.widget.id,
        this.state.widgetContext
      ).then((data) => {
        this.updateData(data);
      });
    }
  };

  findParameter = (widgetParameters: any, type: ParameterType) => {
    if (widgetParameters) {
      const parameters = widgetParameters.parameters.filter(
        (p: any) => p.parameter.type === type
      );

      if (!isEmpty(parameters)) {
        return parameters[0].parameter;
      }
    }

    return null;
  };

  // TODO  reaftor
  findDateFrom = (widgetParameters: Date | null) => {
    const parameter = this.findParameter(
      widgetParameters,
      ParameterType.INTERVAL
    );

    if (!isNil(parameter)) {
      return new Date(parameter.dateFrom);
    }
    return null;
  };

  findDateTo = (widgetParameters: Date | null) => {
    const parameter = this.findParameter(
      widgetParameters,
      ParameterType.INTERVAL
    );
    if (!isEmpty(parameter)) {
      return new Date(parameter.dateTo);
    }

    return null;
  };

  findDate = (widgetParameters: Date | null) => {
    const parameter = this.findParameter(widgetParameters, ParameterType.DATE);
    if (!isEmpty(parameter)) {
      return new Date(parameter.date);
    }
    return null;
  };

  removeMe = () => {
    if (this.state.canRemove && this.props.doRemove) {
      confirm({
        message: i18next.t("widgets.confirm.delete"),
        buttons: [
          {
            label: i18next.t("yes"),
            type: "confirm",
            onClick: () =>
              this.props.doRemove
                ? this.props.doRemove(this.props.widget.id)
                : undefined,
          },
          {
            label: i18next.t("no"),
            type: "cancel",
            onClick: () => {},
          },
        ],
      });
    }
  };

  shareMe = () => {
    if (this.state.canEdit && this.props.doShare) {
      this.props.doShare(this.props.widget.id);
    }
  };

  switchDashboard = () => {
    if (this.state.canEdit && this.props.doSwitchDashboard) {
      this.props.doSwitchDashboard!(this.props.widget.id);
    }
  };

  export = (exportType: FileType) => {
    console.log(
      "export trigger",
      exportType,
      this.props.widget.id,
      this.state.widgetParameters
    );
    RestApi.exportFile(
      this.props.widget.id,
      this.state.widgetParameters,
      exportType
    ).then((res: any) => {
      if (res && res.size && res.size > 0) {
        const blob = res;
        const link = document.createElement("a");

        // create a blobURI pointing to our Blob
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", `download.${exportType}`);

        // some browser needs the anchor to be in the doc
        document.body.append(link);
        link.click();
        link.remove();

        // in case the Blob uses a lot of memory
        window.addEventListener(
          "focus",
          (e) => URL.revokeObjectURL(link.href),
          {
            once: true,
          }
        );
      } else {
        console.warn("CommonWidget :: export :: return document size 0");
      }
    });
  };

  editWidget = () => {
    if (this.state.canEdit && this.props.doEdit) {
      this.props.doEdit(this.props.widget.id);
    }
  };

  showFullscreen = () => {
    this.setState(
      (prevState) => ({
        widgetContext: {
          ...prevState.widgetContext,
          fullScreen: !prevState.widgetContext.fullScreen,
        },
      }),
      () => {
        this.fetchData();
        this.props.doFullscreen();
      }
    );
  };

  controlsChanged = (data: IControlsWidgetContext) => {
    this.setState(
      (prevState: IState) => ({
        widgetContext: {
          ...prevState.widgetContext,
          date: data.date,
          dateFrom: data.dateFrom,
          dateTo: data.dateTo,
          fullScreen: prevState.widgetContext
            ? prevState.widgetContext.fullScreen
            : false,
          filterKeys: prevState.widgetContext.filterKeys,
        },
      }),
      () => {
        this.fetchData();
      }
    );
  };

  dropdownToggled = (menuDropdownOpen: boolean) =>
    this.setState({ menuDropdownOpen });

  abstract createCardBody: () => React.ReactNode;

  customCardBodyClass = () => "";

  render() {
    let cardHeader: JSX.Element | null = null;
    let controls: JSX.Element | null = null;

    if (!this.state.fetched) {
      this.fetchData();
    }

    if (!this.props.isPreview) {
      cardHeader = (
        <WidgetHeader
          title={this.state.title}
          isFullscreen={this.props.isFullscreen || false}
          isTable={this.isTable}
          onEdit={this.state.canEdit ? this.editWidget : undefined}
          onRemove={this.state.canRemove ? this.removeMe : undefined}
          onFullscreen={this.showFullscreen}
          onExport={this.export}
          onShare={this.state.canEdit ? this.shareMe : undefined}
          onSwitchDashboard={
            this.state.canEdit ? this.switchDashboard : undefined
          }
          onRefresh={this.fetchData}
          widgetId={this.props.widget.id}
          isNew={this.props.isNew}
          presentationType={this.props.presentationType}
          onDropdownToggle={this.dropdownToggled}
          dragging={this.props.dragging}
        />
      );
      controls = (
        <WidgetControls
          widget={this.props.widget}
          onApply={this.controlsChanged}
          widgetParameters={this.state.widgetParameters}
        />
      );
    }

    return (
      <Card
        style={{ height: "100%" }}
        className={classNames(
          this.props.isFullscreen ? "dashboard-card" : "dashboard-card",
          this.props.isPreview ? "fullHeight" : false,
          this.state.menuDropdownOpen ? "menu-active" : false
        )}
      >
        {cardHeader}
        <CardBody>
          <div className="card-body-inner">
            {controls}
            <div
              className={classNames(
                "card-body-content",
                this.customCardBodyClass()
              )}
            >
              {this.createCardBody()}
            </div>
          </div>
        </CardBody>
      </Card>
    );
  }
}
