enum Operations {
  // installations
  CREATE_INSTALLATION = "CREATE_INSTALLATION",
  READ_INSTALLATION = "READ_INSTALLATION",
  DELETE_INSTALLATION = "DELETE_INSTALLATION",

  // users
  CREATE_USER = "CREATE_USER",
  READ_USER = "READ_USER",
  DELETE_USER = "DELETE_USER",

  // permissions
  CREATE_PERMISSION_GROUP = "CREATE_PERMISSION_GROUP",
  READ_PERMISSION_GROUP = "READ_PERMISSION_GROUP",
  DELETE_PERMISSION_GROUP = "DELETE_PERMISSION_GROUP",

  // connectors
  CREATE_CONNECTOR = "CREATE_CONNECTOR",
  READ_CONNECTOR = "READ_CONNECTOR",
  DELETE_CONNECTOR = "DELETE_CONNECTOR",

  // data sources
  CREATE_DATA_SOURCE = "CREATE_DATA_SOURCE",
  READ_DATA_SOURCE = "READ_DATA_SOURCE",
  DELETE_DATA_SOURCE = "DELETE_DATA_SOURCE",

  // data sets
  CREATE_DATA_SET = "CREATE_DATA_SET",
  READ_DATA_SET = "READ_DATA_SET",
  DELETE_DATA_SET = "DELETE_DATA_SET",

  // scheduling
  CREATE_DATA_SET_SCHEDULE = "CREATE_DATA_SET_SCHEDULE",
  READ_DATA_SET_SCHEDULE = "READ_DATA_SET_SCHEDULE",
  DELETE_DATA_SET_SCHEDULE = "DELETE_DATA_SET_SCHEDULE",

  // scripts
  CREATE_SCRIPT_DEFINITION = "CREATE_SCRIPT_DEFINITION",
  READ_SCRIPT_DEFINITION = "READ_SCRIPT_DEFINITION",
  DELETE_SCRIPT_DEFINITION = "DELETE_SCRIPT_DEFINITION",

  // sharing - widget
  CREATE_WIDGET_SHARE = "CREATE_WIDGET_SHARE",
  READ_WIDGET_SHARE = "READ_WIDGET_SHARE",
  DELETE_WIDGET_SHARE = "DELETE_WIDGET_SHARE",

  // sharing - external
  SHARE_WIDGET_EXTERNAL = "SHARE_WIDGET_EXTERNAL",

  // sharing - dataset
  CREATE_DATA_SET_SHARE = "CREATE_DATA_SET_SHARE",
  READ_DATA_SET_SHARE = "READ_DATA_SET_SHARE",
  DELETE_DATA_SET_SHARE = "DELETE_DATA_SET_SHARE",

  // widgets and dashboards
  CREATE_WIDGET = "CREATE_WIDGET",
  READ_WIDGET = "READ_WIDGET",
  DELETE_WIDGET = "DELETE_WIDGET",

  CREATE_DASHBOARD = "CREATE_DASHBOARD",
  READ_DASHBOARD = "READ_DASHBOARD",
  DELETE_DASHBOARD = "DELETE_DASHBOARD",

  // assistant
  ASSISTANT = "ASSISTANT",

  // ****************************************************
  // CUSTOM OPERATIONS KNOWN ONLY BY THE UI
  // ****************************************************
  READ_APP_INFO = "READ_APP_INFO",
}

export default Operations;
