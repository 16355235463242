import React, { useEffect, useState, useCallback } from "react";

import { useTranslation } from "react-i18next";
import Select, {
  components,
  OptionProps,
  OptionTypeBase,
  OptionsType,
  SingleValueProps,
} from "react-select";
import { toast } from "react-toastify";
import { Button, Modal, ModalHeader, Alert } from "reactstrap";
import ButtonGroup from "reactstrap/lib/ButtonGroup";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalFooter from "reactstrap/lib/ModalFooter";

import createDashboardApi from "../../api/DashboardApi";
import { IUser } from "../../data/AppModels";
import { EntityRelationType } from "../../store/EntityRelationStores";
import { useDashboardStore, useWidgetStore } from "../../store/useStores";
import { EntityWorkspaceRelationInPopover } from "../entities/EntityWorkspaceRelation";
import usePresentationEntityName, {
  EntityNameDefinition,
} from "../entities/usePresentationEntityName";

import "./WidgetSwitchBetweenDashboardsModal.scss";
import useEntityWorkspaceRelation from "../../store/workspaceStoreHooks";
import { useCurrentUserId } from "../../store/userStoreHooks";
import UserListItem from "../entities/UserListItem";
import { getSelectTheme } from "../../data/AppUtils";
import { Dashboard } from "../../store/DashboardStore";

export interface Props {
  widgetId: string;
  oldDashboardId: string;

  submit: () => void;
  cancel: () => void;
}

interface OptionData extends OptionTypeBase {
  dashboardId: string;
  nameDefinition: EntityNameDefinition;
}

type OptionContentProps = {
  data: OptionData;
};

const OptionContent = ({ data }: OptionContentProps) => {
  const { t } = useTranslation();
  const { dashboardId, nameDefinition } = data as OptionData;
  return (
    <div className="dashboard-select-option">
      {!nameDefinition.title && !nameDefinition.subtitle && (
        <span className="unnamed">{t("unnamedDashboard")}</span>
      )}
      <span>{nameDefinition.fragment}</span>
      <EntityWorkspaceRelationInPopover
        entityId={dashboardId}
        type={EntityRelationType.WORKSPACE_DASHBOARD}
        target="span"
        placement="right"
      />
    </div>
  );
};

const Option = (props: OptionProps<OptionData>) => {
  const data = props.data as OptionData;
  return (
    <components.Option {...props}>
      <OptionContent data={data} />
    </components.Option>
  );
};

const SingleValue = (props: SingleValueProps<OptionData>) => {
  const data = props.data as OptionData;
  return (
    <components.SingleValue {...props}>
      <OptionContent data={data} />
    </components.SingleValue>
  );
};

type UserListProps = {
  users: (IUser | undefined)[];
  label: string;
  type: "gain" | "lose";
};

const getSortedUserList = (users: (IUser | undefined)[]) => {
  const cleanList = users.filter((user) => user !== undefined) as IUser[];
  return cleanList.sort((u1, u2) => u1.name.localeCompare(u2.name));
};

const UserList = ({ users, label, type }: UserListProps) => (
  <Alert color={type === "gain" ? "success" : "danger"}>
    <h5>
      <i
        className={type === "gain" ? "icon-user-follow" : "icon-user-unfollow"}
      />
      {label}
    </h5>
    <div>
      {getSortedUserList(users).map((user) => (
        <UserListItem user={user} />
      ))}
    </div>
  </Alert>
);

const WidgetSwitchBetweenDashboardsModal = (props: Props) => {
  const { t } = useTranslation();

  const myUserId = useCurrentUserId();
  const dashboardApi = createDashboardApi();

  const dashboardStore = useDashboardStore();
  const widgetStore = useWidgetStore();

  const [toDashboardId, setToDashboardId] = useState<string | undefined>(
    undefined
  );
  const [dashboardOptions, setDashboardOptions] = useState<
    OptionsType<OptionData>
  >([]);
  const [fetch, setFetch] = useState<boolean>(true);

  const entityName = usePresentationEntityName();

  const getWorkspaceRelation = useEntityWorkspaceRelation(
    EntityRelationType.WORKSPACE_DASHBOARD
  );

  const usersOfOldDashboard = getWorkspaceRelation(props.oldDashboardId).users;
  const userIdsOfOldDashboard = usersOfOldDashboard.reduce(
    (map, user) => map.set(user.id, user),
    new Map<string, IUser>()
  );
  const oldDashboardOnlyMine =
    usersOfOldDashboard.length === 1 && usersOfOldDashboard[0].id === myUserId;

  const usersOfNewDashboard = getWorkspaceRelation(toDashboardId).users;
  const userIdsOfNewDashboard = usersOfNewDashboard.reduce(
    (map, user) => map.set(user.id, user),
    new Map<string, IUser>()
  );

  const otherUsersInNewDashboard = usersOfNewDashboard.filter(
    (user) => user.id !== myUserId
  );

  const usersWhoLoseAccess = [...userIdsOfOldDashboard.keys()]
    .filter((userId) => !userIdsOfNewDashboard.has(userId))
    .map((userId) => userIdsOfOldDashboard.get(userId));

  const usersWhoGainAccess = [...userIdsOfNewDashboard.keys()]
    .filter((userId) => !userIdsOfOldDashboard.has(userId))
    .map((userId) => userIdsOfNewDashboard.get(userId));

  const loadDashboardOptions = useCallback(
    async (filterDashboardId: string) => {
      const dashboards: Dashboard[] | undefined =
        await dashboardStore?.fetchDashboards();

      const options: OptionsType<OptionData> = dashboards!
        .filter(
          (dsa) =>
            dsa.id !== filterDashboardId && dashboardStore?.canEdit(dsa.id)
        )
        .map((dsa) => {
          const entName = entityName(dsa);
          return {
            value: dsa.id,
            label: `${entName.title} ${entName.subtitle}`,
            dashboardId: dsa.id,
            nameDefinition: entName,
          };
        });

      setDashboardOptions(options);
    },
    [dashboardStore, entityName]
  );

  useEffect(() => {
    if (fetch) {
      loadDashboardOptions(props.oldDashboardId);
      setFetch(false);
    }
  }, [fetch, loadDashboardOptions, props.oldDashboardId]);

  const switchDashboard = useCallback(
    async (
      widgetId: string,
      fromDashboardId: string,
      toDashboardId: string | undefined
    ) => {
      if (toDashboardId) {
        await dashboardApi.addWidgetToDashboard(toDashboardId, widgetId);
        // use store which represents state of dashboard component
        await widgetStore?.removeWidgetFromDashboard(fromDashboardId, widgetId);

        toast.success(t("widget.toast.dashboard.switch"));
      }
    },
    [widgetStore, dashboardApi, t]
  );

  return (
    <Modal
      isOpen
      className="modal-dialog-centered modal-dialog-upload-data modal-lg"
    >
      <ModalHeader>{t("widgetDashboardSwitch")}</ModalHeader>
      <ModalBody className="widget-switch-modal">
        <Select
          placeholder="..."
          options={dashboardOptions}
          theme={getSelectTheme()}
          onChange={(value) => {
            if (value) {
              const _value = value as OptionData;
              setToDashboardId(_value.dashboardId);
            }
          }}
          components={{
            Option,
            SingleValue,
          }}
        />
        {toDashboardId &&
          oldDashboardOnlyMine &&
          otherUsersInNewDashboard.length > 0 && (
            <UserList
              label={t("widgetSwitchToShared")}
              users={otherUsersInNewDashboard}
              type="gain"
            />
          )}
        {toDashboardId &&
          !oldDashboardOnlyMine &&
          usersWhoGainAccess.length > 0 && (
            <UserList
              label={t("widgetSwitchGain")}
              users={usersWhoGainAccess}
              type="gain"
            />
          )}
        {toDashboardId &&
          !oldDashboardOnlyMine &&
          usersWhoLoseAccess.length > 0 && (
            <UserList
              label={t("widgetSwitchLose")}
              users={usersWhoLoseAccess}
              type="lose"
            />
          )}
      </ModalBody>

      <ModalFooter>
        <ButtonGroup>
          <Button
            className="float-left mr-1"
            color="primary"
            onClick={() => {
              switchDashboard(
                props.widgetId,
                props.oldDashboardId,
                toDashboardId
              );
              props.submit();
            }}
            disabled={!toDashboardId}
          >
            {t("btnSubmit")}
          </Button>
          <Button
            className="float-left mr-1"
            color="secondary"
            onClick={props.cancel}
          >
            {t("btnCancel")}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

export default WidgetSwitchBetweenDashboardsModal;
