import React, { useCallback, useEffect, useState } from "react";

import Select from "react-select";

import { useDashboardStore } from "../../store/useStores";
import SelectOption from "../../model/SelectOption";
import { getSelectTheme } from "../../data/AppUtils";
import createDataSetApi from "../../api/DataSetApi";
import "./DashboardKeysDropDown.scss";
import { UserProperty } from "../../data/AppModels";
import RestApi from "../../services/RestApi";

export interface Props {
  dashboardId: string;
  selectedFilterKey: string | undefined;
  selectFilterKey: (filterKey: string | undefined) => void;
}

const DASHBOARD_DROPDOWN_USER_PROPERTY_KEY = "dashboard_dropdown_filter_key";

interface DashboardDropdownUserProperty {
  values: { dashboardId: string; key: string }[];
}

const DashboardKeysDropDown = ({
  dashboardId,
  selectedFilterKey,
  selectFilterKey,
}: Props) => {
  const dashboardStore = useDashboardStore();
  const dataSetApi = createDataSetApi();

  const [options, setOptions] = useState<SelectOption[]>([]);
  const [showKeysFilter, setShowKeysFilter] = useState<boolean>(false);
  const [loadedDashboardId, setLoadedDashboardId] = useState<string>();

  const selectedOption = (): SelectOption | undefined =>
    selectedFilterKey !== undefined
      ? options.find((option) => option.value === selectedFilterKey)
      : undefined;

  const updateUserProperty = useCallback(
    async (key: string, userProperty: UserProperty | undefined) => {
      let dashboardUserProperty: DashboardDropdownUserProperty | undefined;
      if (userProperty && userProperty.value) {
        dashboardUserProperty = JSON.parse(userProperty.value);
      }

      if (dashboardUserProperty) {
        const stored = dashboardUserProperty.values.find(
          (p) => p.dashboardId === dashboardId
        );
        if (stored) {
          stored.key = key;
        } else {
          dashboardUserProperty.values.push({ dashboardId, key });
        }
      } else {
        dashboardUserProperty = { values: [{ dashboardId, key }] };
      }

      await RestApi.saveUserPropertyV1({
        key: DASHBOARD_DROPDOWN_USER_PROPERTY_KEY,
        value: JSON.stringify(dashboardUserProperty),
      });
    },
    [dashboardId]
  );

  const saveKey = async (key: string) => {
    const userProperty = await RestApi.loadUserPropertyV1(
      DASHBOARD_DROPDOWN_USER_PROPERTY_KEY
    );
    await updateUserProperty(key, userProperty);
  };

  const load = useCallback(
    async (dataSetId: string) => {
      // load options
      const data = await dataSetApi.getData(dataSetId, { page: 0, size: 999 });
      const keysOptions = data.content
        .filter((row) => row.rowIndex > 0)
        .map((row) => {
          const option: SelectOption = {
            value: row.cells[0].value,
            label: row.cells[1].value,
          };

          return option;
        });

      setOptions(keysOptions);

      // load last saved key
      const userProperty = await RestApi.loadUserPropertyV1(
        DASHBOARD_DROPDOWN_USER_PROPERTY_KEY
      );
      let selectKey: string | undefined;
      let dashboardUserProperty: DashboardDropdownUserProperty | undefined;
      if (userProperty && userProperty.value) {
        dashboardUserProperty = JSON.parse(userProperty.value);
        if (dashboardUserProperty) {
          const stored = dashboardUserProperty.values.find(
            (p) => p.dashboardId === dashboardId
          );
          if (stored) {
            selectKey = stored.key;
          }
        }
      }
      // if stored key does not exists, get first from options
      if (!selectKey) {
        if (keysOptions.length !== 0) {
          selectKey = keysOptions[0].value;

          // store as last used key
          await updateUserProperty(selectKey, userProperty);

          await RestApi.saveUserPropertyV1({
            key: DASHBOARD_DROPDOWN_USER_PROPERTY_KEY,
            value: JSON.stringify(dashboardUserProperty),
          });
        }
      }
      // check if exists in options
      if (keysOptions.find((opt) => opt.value === selectKey)) {
        selectFilterKey(selectKey);
      }
    },
    [dataSetApi, dashboardId, selectFilterKey, updateUserProperty]
  );

  const fetch = useCallback(async () => {
    const dashboard = await dashboardStore?.getDashboard(dashboardId);
    if (dashboard && dashboard.dataSetFilter) {
      setShowKeysFilter(true);
      await load(dashboard.dataSetFilter.id);
    } else {
      setShowKeysFilter(false);
    }
  }, [dashboardStore, load, dashboardId]);

  useEffect(() => {
    if (loadedDashboardId !== dashboardId) {
      setLoadedDashboardId(dashboardId);
      fetch();
    }
  }, [dashboardId, fetch, loadedDashboardId]);

  return (
    <>
      {showKeysFilter && (
        <div className="dashboard-keys-dropdown">
          <Select
            placeholder=""
            options={options}
            theme={getSelectTheme()}
            menuPortalTarget={document.body}
            value={selectedOption()}
            onChange={(value: any) => {
              if (value) {
                selectFilterKey(value.value);
                saveKey(value.value);
              } else {
                selectFilterKey(undefined);
              }
            }}
          />
        </div>
      )}
    </>
  );
};

export default DashboardKeysDropDown;
