import { DataSetLabelTypeDto } from "../api/dto/data-set-label-type/DataSetLabelTypeDto";
import { UserDtoV2UsersStateEnum } from "../openapi";
import { ConnectorTypeAttributeKeys } from "./dto/type/ConnectorTypeAttributeKeys";
import {
  ConnectorTypeQueryAttributeDto,
  ConnectorTypeQueryDto,
} from "./dto/type/types";
import PermissionTypes from "./PermissionTypes";
import WorkspaceEntityOperations from "./WorkspaceEntityOperations";
import WorkspaceOperations from "./WorkspaceOperations";

export enum ChartType {
  PIE = "PIE",
  BAR = "BAR",
  LINE = "LINE",
  DOUGHT = "DOUGHT",
  SCATTER = "SCATTER",
  HORIZONTAL_BAR = "HORIZONTAL_BAR",
  RADAR = "RADAR",
  POLAR = "POLAR",
  BUBBLE = "BUBBLE",
}

export enum SourceType {
  L = "L",
  DATA = "DATA",
}

export enum WidgetType {
  CHART = "CHART",
  TABLE = "TABLE",
  INFO = "INFO",
}

export enum ParameterType {
  DATE = "DATE",
  INTERVAL = "INTERVAL",
}

export enum Language {
  DEFAULT = "en-GB",
  EN = "en-GB",
  SK = "sk-SK",
  DE = "de-DE",
}

export enum DataSetStatus {
  QUEUE = "QUEUED",
  UPDATING = "UPDATING",
  UPDATE_ERROR = "UPDATE_ERROR",
  UPDATE_CANCELED = "UPDATE_CANCELED",
  UPDATED = "UPDATED",
}

export enum FileType {
  CSV = "CSV",
  PDF = "PDF",
  XLSX = "XLSX",
}

export enum IDataSetCreateOptions {
  dataSource = "DATA_SOURCE",
  script = "SCRIPT",
}

export enum JobExecutorStatus {
  PENDING = "PENDING",
  RUNNING = "RUNNING",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
  CANCELED = "CANCELED",
}

export enum CodeEditorLanguage {
  python = "python",
}

export enum WidgetInfoFunction {
  VALUE = "VALUE", // just display first value in row
  SUM = "SUM", // sum all values
  COUNT = "COUNT", // count
  MAX = "MAX",
  MIN = "MIN",
  AVG = "AVG",
}

export function languageFromValue(value: string): Language {
  const result = Object.values(Language).filter(
    (key) => key.toString() === value
  );
  return result[0] ?? Language.DEFAULT;
}

export interface IAuthData {
  username: string;
  password: string;
}

export interface IWidgetParameters {
  date: Date | null;
  dateFrom: Date | null;
  dateTo: Date | null;
  isInterval: boolean;
  isDate: boolean;
}

export interface IWidget extends WorkspaceEntity {
  widgetSourceId: string;
  widgetTemplateId: string;
  widgetVisualisationId: string;
}

export interface IWidgetVisualisationData {
  id: string;
  key: WidgetType;
}

export interface ICatalogue {
  id: string;
  installationId: string;
  name: string;
  description: string;
}

export interface ICatalogueCategory {
  id: string;
  installationId: string;
  catalogueId: string;
  key: string;
}

export interface ICatalogueWidgetTemplate {
  id: string;
  key: string;
  title: string;
  description: string;
  confPredefinedId: string;
  widgetVisualisation: IWidgetVisualisationData;
  chartType: string;
}

export interface IWidgetCatalogItem {
  iconClassName: string;
  id: string;
  title: string;
}

export interface IWidgetConfigurationTableData {
  id: string;
}

export interface IWidgetConfigurationColumnData {
  id: string;
  tableId: string;
  count: boolean;
  groupBy: boolean;
  distinct: boolean;
  orderBy: boolean;
}

export interface IWidgetConfigurationSourceData {
  label: string;
  tables: IWidgetConfigurationTableData[];
  columns: IWidgetConfigurationColumnData[];
}

export interface WidgetConfigurationChartDto {
  axisXColumns: IWidgetConfigurationColumnData[];
  axisYColumns: IWidgetConfigurationColumnData[];
  chartOptions?: WCChartOptionsDto; // custom options
}

export interface WCChartOptionsDto {
  scaleFromZero: boolean;
  displayValues: boolean;
}

export interface WidgetConfigurationTabDto {
  selectedColumns: IWidgetConfigurationColumnData[];
}

export interface IDsWidgetConfigPredefinedItemData {
  id: string;
  name: string;
}

export interface IDsWidgetConfigPredefinedData {
  id: string;
  groupByData: IDsWidgetConfigPredefinedGroupByData[];
  splitByData: IDsWidgetConfigPredefinedGroupByData[];
  pickerTypes: WidgetPickerType[];
  defaultWidgetType: WidgetType | null;
}

export interface IDsWidgetConfigPredefinedGroupByData {
  id: string;
  name: string;
}

export enum WidgetPickerType {
  DATE = "DATE",
  INTERVAL = "INTERVAL",
}

export interface WidgetConfigurationInfoDto {
  columnId: string;
  function: string;
}

export interface WidgetConfigurationDto {
  dataSetId: string | null;
  widgetName: string;
  widgetDescription: string;
  widgetVisualisationId: string;
  widgetSourceTypeData: SourceType;
  widgetTypeData: WidgetType;
  chartTypeData: ChartType | undefined;
  configurationInfo: WidgetConfigurationInfoDto | null;
  configurationChart?: WidgetConfigurationChartDto;
  configurationTab: WidgetConfigurationTabDto | null;
  groupByMan: boolean;
  groupByAk: boolean;
  widgetId: string | null;
  predefinedWidgetConfigurationId: string | null;
  groupByFieldIds: string[];
  splitByFieldIds: string[];
  pickerType: WidgetPickerType | null;
  intDefFrom: string | null;
  intDefTo: string | null;
}

export interface IDatasetD {
  label: string;
  backgroundColor: string[];
  backgroundColorOriginal: string[];
  borderColor: string[];
  borderWidth: number;
  hoverBackgroundColor: string[];
  hoverBorderColor: string[];
  data: string;
}

export interface IDataSource extends WorkspaceEntity {
  connectorId: string;
  connectorTypeQueryId: string;
}

export interface ChartDataDto {
  chartType: ChartType;
  labels: string[];
  datasets: IDatasetD[];
  chartOptions?: WCChartOptionsDto; // custom options from configuration
}

export interface GridCell {
  row: number;
  col: number;
  value?: string;
  format?: string;
  readOnly: boolean;
  width: number;
  className?: string;
}

export interface IInfoData {
  text: string;
  value: any;
}

export interface IColumn {
  id: string;
  name: string;
}

export interface ICell {
  value: string;
}

export interface IRow {
  id: string;
  cells: ICell[];
}

export interface ITable {
  id: string;
  name: string;
  columns: IColumn[];
  rows: IRow[];
  data: any;
}

export interface TableDataDto {
  header: string[];
  rows: string[][];
}

export interface DataSetPreviewJsonDto {
  data: any; // json
}

export type TableDataDtoPageable =
  IPageableResultBase<DatasetPreviewRowInterface>;

export interface DatasetPreviewRowInterface {
  row: string[] | null;
  header: string[] | null;
}

export interface UserProperty {
  key: string;
  value: string;
}

export interface IDataSelect {
  data: [][];
}

export interface IDataRange {
  rangeFrom: ILocation;
  rangeTo: ILocation;
}

export interface IDataSheetRange {
  start: ISheetLocation;
  end: ISheetLocation;
}

export interface ILocation {
  row: number;
  col: number;
}

export interface ISheetLocation {
  i: number;
  j: number;
}

export interface IDataSetSchedule {
  id: string | null;
  name: string;
  cronExpression: string;
  dataSetId: string;
  description: string;
  installationId: string;
  fullDependenciesUpdate: boolean;
}

export type IDataSetScheduleResult = IPageableResultBase<IDataSetSchedule>;

export interface IDatasourceCreate {
  name: string | null;
  selectedRange: IDataRange | null;
  firstRowHeader: boolean;
  fileId: string | null;
}

export interface GoogleDriveFile {
  id: string;
  oauthToken: string;
  mimeType: string;
  name: string;
  serviceId: string;
  type: string;
  lastEditedUtc: Date;
}

export interface IGoogleTokenFromCodeDto {
  code: string;
}

export interface IGoogleTokenFromCodeDtoR {
  refreshToken: string;
}

export interface GoogleConfiguration {
  oauthToken: string;
  clientId: string;
  apiKey: string;
}

export interface IControlsWidgetContext {
  date: Date | null;
  dateFrom: Date | null;
  dateTo: Date | null;
}

export interface IWidgetContext {
  date: Date | null;
  dateFrom: Date | null;
  dateTo: Date | null;
  fullScreen: boolean;
  filterKeys?: string[];
}

//* *************** DATASET *************************************//

export interface IDSSelectData {
  selectedRange: IDataRange | null;
  firstRowHeader: boolean;
}

export interface IDSSheetCell {
  colId: string;
  colIndex: number;
  value: string;
  formattedValue?: string;
}

export interface IDSSheetData {
  name: string | null;
  position: number;
  dataSetTableId: string;
}

export interface IDSSheetCells {
  sheetId: string;
  range: IDataRange | undefined;
  cells: IDSSheetCell[][];
}

export interface IDSSLoadData {
  selectData: IDSSelectData | null;
  selectedSheetId: string | null;
  name: string | null;
  sheets: IDSSheetData[];
  sheetCells: IDSSheetCells[] | null;
}

export interface IDSDataSetDataPageable
  extends IPageableResultBase<IDataSetDataRow> {
  content: IDataSetDataRow[];
}

export interface IDataSetDataRow {
  rowIndex: number;
  cells: IDSSheetCell[];
}

export interface IDSSCreateCustomData {
  name: string | null;
}

export interface IDSBuildData {
  name: string | null;
  selectData: IDSSelectData | null;
  dataSetTableId: string;
}

export interface IDSSRow {
  position: number;
}

export interface IDSSColumn {
  position: number;
}

export interface IDSSCellsUpdate {
  range: IDataRange;
  cells: IDSSheetCell[][];
}

export interface IDSDataSetUpdate {
  dateSetName: string | null;
  firstRowHeader: boolean;
  removeRows?: IDSSRow[];
  addRows?: IDSSRow[];
  removeColumns?: IDSSColumn[];
  addColumns?: IDSSColumn[];
  cellsUpdates?: IDSSCellsUpdate[];
}

export interface IDSInfoLoadData {
  dataSetName: string;
  columns: DsMetaColumnDto[];
}

export interface IDSDataSetData {
  dataSetName: string;
  firstRowHeader: boolean;
  range: IDataRange;
  cells: IDSSheetCells[];
}

/* -------- DS MERGE */
export interface IDsMergeColumn {
  dataSourceId: string;
  columnId: string;
}

export interface IDsMergeConfiguration {
  dataSources: string[];
  columns: IDsMergeColumn[];

  joinColumns: IDsMergeColumn[];
}

export interface IDsMergeData {
  dsName: string | null;
  mergeConfigurationData: IDsMergeConfiguration;
}

export interface IUsersData {
  id: string;
  name: string;
}

interface IResultPageable {
  offset: number;
  pageSize: number;
  pageNumber: number;
  paged: boolean;
  unpaged: boolean;
}

interface IResultSortable {
  unsorted: boolean;
  sorted: boolean;
  empty: boolean;
}

export interface Entity {
  id: string;
}

export interface InstallationEntity extends Entity {
  installationId: string;
}

export interface NamedEntity extends Entity {
  name: string;
  description?: string;
}

export interface WorkspaceEntity extends NamedEntity, InstallationEntity {
  operations: WorkspaceEntityOperations[];
}

export interface ModifiableEntity extends Entity {
  changed: boolean;
}

export interface PresettableEntity extends Entity {
  preSet: boolean;
  usersId: string;
}

export function isPresettableEntity(
  entity: Entity
): entity is PresettableEntity {
  const presettable = entity as PresettableEntity;
  return presettable.preSet !== undefined && presettable.usersId !== undefined;
}

export interface DataResponse<T> {
  infoMessages: [];
  errorMessages: [];
  data: T;
}

export interface IPageableResultBase<T> {
  pageable: IResultPageable;
  totalElements: number;
  last: boolean;
  number: number;
  sort: IResultSortable;
  size: number;
  numberOfElements: number;
  first: boolean;
  empty: boolean;
  content: T[];
}

export type PageableEntitiesWithIdAndName = IPageableResultBase<Entity>;

export type IUsersGroupsFindResultData = IPageableResultBase<IUsersData>;

export interface IDataSourceShareWithData {
  userIdsAdd: string[];
  userGroupsAdd: string[];
}

export interface IWidgetShareWithData {
  userIdsAdd: string[];
  userGroupsAdd: string[];
  text: string | null;
}

export interface IShareWithExternalData {
  widgetIds: string[];
  notifyEmailAdress: string | null;
  text: string | null;
}

export interface DsMetaColumnDto {
  id: string;
  name: string;
  dsId: string;
  dsName: string;
  columnType?: DsMetaColumnDtoType;
}

export enum DsMetaColumnDtoType {
  DATE = "DATE",
  TIME = "TIME",
  DATE_TIME = "DATE_TIME",
  TEXT = "TEXT", // string
  UNDETECTED = "UNDETECTED",
}

export interface IDsJoinColumns {
  id: string;
  leftColumn: DsMetaColumnDto;
  rightColumn: DsMetaColumnDto;
}

export interface IDsTemplate {
  id: string | null;
  name: string;
  data: string;
}

/** * ----- NOTIFICATIONS   */
export interface INotificationsInfoData {
  count: number;
}

export interface INotificationDatasetSharedData {
  dataSetId: string;
  title: string;
  sharedAt: string | null;
  sharedBy: INotificationSharedByData;
}

export interface INotificationWidgetSharedData {
  widgetId: string;
  title: string;
  customText: string;
  sharedAt: string | null;
  sharedBy: INotificationSharedByData;
}

export interface INotificationSharedByData {
  usersId: string | null;
  usersName: string | null;
}

export interface INotificationTaskCreatedData {
  title: string;
  tasksId: string;
}

export enum ENotificationType {
  W_SHARED = "W_SHARED",
  DS_SHARED = "DS_SHARED",
  TASK_CREATED = "TASK_CREATED",
  TASK_INPROGRESS = "TASK_INPROGRESS",
  TASK_COMPLETED = "TASK_COMPLETED",
}

export interface INotificationData {
  id: string;
  type: ENotificationType;
  notificationState: string;
  widget: INotificationWidgetSharedData | null;
  dataSet: INotificationDatasetSharedData | null;
  task: INotificationTaskCreatedData | null;
}

export type INotificationDataResult = IPageableResultBase<INotificationData>;

/*   GENERATOR   */
export interface IGenerateData {
  customEmail: string | null;
  usersCount: number;
  password: string;
  datastsCount: number;
  dsRowsCount: number;
  widgetsCount: number;
  notificationsCount: number;
}

export interface IGenerateOutputData {
  userEmails: string[];
}

/*  TASKS */
export interface ITaskData {
  name: string | null;
  text: string | null;
  assignedToId: string | null;
}

export type ITaskItemDataResult = IPageableResultBase<ITaskItemData>;

export interface ITaskItemData {
  id: string;
  name: string;
  text: string;
  status: TaskStatusData;
}

export enum TaskStatusData {
  IN_PROGRESS = "IN_PROGRESS",
  CREATED = "CREATED",
  COMPLETED = "COMPLETED",
}

export function taskStatusFromValue(value: string): TaskStatusData | undefined {
  const result = Object.values(TaskStatusData).filter(
    (key) => key.toString() === value
  );
  return result[0] ?? undefined;
}

export enum NotificationType {
  ERROR = "alert-danger",
  WARN = "alert-warning",
  INFO = "alert-primary",
  SUCCESS = "alert-success",
}

export interface INotification {
  type: NotificationType;
  message: string;
}

export interface IMention {
  id: string;
  value: string;
}

export interface IDataResponse<T = any> {
  infoMessages: string[];
  errorMessages: string[];
  data: T;
}

export interface IUser extends IUsersData {
  username: string;
  email: string;
  createdDate?: string;
  usersState?: UserDtoV2UsersStateEnum;
}

export interface ICurrentUser extends IUser {
  userPermissions: IUserPermissions | undefined;
}

export interface ICreateUser {
  name: string;
  username: string;
  email: string;
  permissionGroupIds?: string[];
  updatePermGroups: boolean;
}

export interface IResetPasswordResponse {
  id?: string;
  resetPasswordLink: string;
  resetPasswordToken: string;
}

export interface IUserPermissionGroup {
  id: string;
  name: string;
  permissionGroupId: string;
  installationId: string;
}

export interface IUserPermissions {
  operations: string[] | null;
  superUser: boolean;
}

export interface IInstallation {
  id: string;
  name: string;
  description: string;
  licence: ILicenceInfo | undefined;
  activeUsersCount: number;
}

export interface IInstallationFormData {
  name: string;
  description: string;
}

export interface IInstallationCreate {
  name: string;
  description: string;
}

export interface ILicenceInfo {
  expires: Date;
}

export interface IPermissionGroup {
  id: string;
  name: string;
  description: string;
  types: IPermissionType[]; // ids of IPermission
}

export interface IPermissionGroupWithInstallations extends IPermissionGroup {
  installations: IInstallation[];
}

export interface ICreatePermissionGroup {
  name: string;
  description: string;
  permissionTypeIds: string[];
}

export interface IPermissionGroupUsers {
  id: string;
  permissionGroupId: string;
  usersId: string;
}

export interface IPermissionGroupPermission {
  id: string;
  permissionGroupId: string;
  permissionId: string;
}

export interface IPermissionGroupInstallation {
  id: string;
  permissionGroupId: string;
  installationId: string;
}

export interface IPermissionType {
  id: string;
  area: string; // Enum
  key: PermissionTypes;
}

export interface IMenuItem {
  id: string;
  name: string;
  icon: string;
  active?: boolean;
  onClick?: any;
}

export interface IBreadcrumbItem {
  name: string;
  link?: any;
}

export interface ICatalogueCategoryData {
  key: string;
  name: string;
  description: string;
  img: string;
}

export interface IWidgetConfig {
  groupByData: { id: string; name: string }[];
  id: string;
  pickerTypes: string[];
  splitByData: { id: string; name: string }[];
  widgetVisualisationId: string;
}

export interface IDashboardWidgetExternal {
  desc: string;
  id: string;
  title: string;
  widgetVisualisation: IWidgetVisualisationData;
}

export type IWidgetsResult = IPageableResultBase<IWidget>;

export type IWidgetsVisualisationsResult =
  IPageableResultBase<IWidgetVisualisationData>;

export type IUserResult = IPageableResultBase<IUser>;

export type IInstallationResult = IPageableResultBase<IInstallation>;

export type IPermissionGroupResult = IPageableResultBase<IPermissionGroup>;

export type IPermissionGroupUsersResult =
  IPageableResultBase<IPermissionGroupUsers>;

export type IPermissionGroupPermissionsResult =
  IPageableResultBase<IPermissionGroupPermission>;

export type IPermissionGroupInstallationResult =
  IPageableResultBase<IPermissionGroupInstallation>;

export type ICatalogueResult = IPageableResultBase<ICatalogue>;

export type ICatalogueCategoryResult = IPageableResultBase<ICatalogueCategory>;

export type ICatalogueWidgetTemplateResult =
  IPageableResultBase<ICatalogueWidgetTemplate>;

export type IDataSourceResult = IPageableResultBase<IDataSource>;

export type FieldTypeResult = IPageableResultBase<FieldTypeDto>;

export type DataSetLabelTypeResult = IPageableResultBase<DataSetLabelTypeDto>;

export type ConnectorAttributeResult =
  IPageableResultBase<ConnectorAttributeDto>;

export type ScriptDefinitionLabelResult =
  IPageableResultBase<ScriptDefinitionLabelDto>;

export interface IConnectorResult extends IPageableResultBase<IConnector> {
  content: IConnector[];
}

// Connectors
export interface IConnector extends WorkspaceEntity {
  connectorTypeId: string;
  perUser: boolean;
}

export interface DataSourceDto extends InstallationEntity, WorkspaceEntity {
  connectorId: string;
  connectorTypeQueryId: string;
  connectorTypeQuery: ConnectorTypeQueryDto;
  name: string;
  description: string;
}

export interface DataSourceUpdateDto {
  connectorId: string;
  connectorTypeQueryId: string;
  name: string;
  description: string;
}

export interface ConnectorTypeAttributeDto extends Entity {
  connectorTypeId: string;
  key: ConnectorTypeAttributeKeys;
  userDefined: boolean;
}

export interface ConnectorAttributeDto extends InstallationEntity {
  connectorId: string;
  connectorTypeAttributeId: string;
  connectorTypeAttribute: ConnectorTypeAttributeDto;
  usersId?: string;
  value?: string;
}

export interface IConnectorQuery {
  id: string;
  connectorTypeId: string;
  key: string;
}

export interface ConnectorAttributeUpdateDto {
  connectorId: string;
  connectorTypeAttributeId: string;
  value: string | undefined;
  forUser?: boolean; // directly for user
}

export interface IDatasourceQueryAttribute {
  id: string;
  datasourceId: string;
  installationId: string;
  connectorTypeQueryAttributeId: string;
  value: string;
}

export interface DataSourceQueryAttributeDto extends InstallationEntity {
  connectorTypeQueryAttributeId: string;
  connectorTypeQueryAttribute: ConnectorTypeQueryAttributeDto;
  dataSourceId: string;
  value: any | string | undefined;
}

export interface DataSourceQueryAttributeUpdateDto {
  connectorTypeQueryAttributeId: string;
  dataSourceId: string;
  value: any | string | undefined;
}

export interface TestQueryAttributeDto {
  connectorTypeQueryAttributeId: string;
  value: any | string | undefined;
}

export interface DataSourceTestDto {
  queryAttributes: TestQueryAttributeDto[];
}

export interface DataSourceTestResultDto {
  jsonData: any;
}

export interface IConnectorTypeQueryAttribute {
  connectorTypeQueryId: string;
  id: string;
  key: string;
}

export interface LFilter {
  manAks: { man: string; ak: string }[];
}

export interface IDataSetCreateDataFromSource {
  name: string;
  description?: string;
  dataSourceId: string;
}

export interface ScriptDefinitionLabelDto extends InstallationEntity {
  scriptDefinitionId: string;
  fieldTypeId: string | undefined;
  dataSetLabelTypeId: string | undefined;
  position: number | undefined;
}

export interface ScriptDefinitionLabelDtoU {
  scriptDefinitionId?: string;
  fieldTypeId: string | undefined;
  dataSetLabelTypeId: string | undefined;
  position: number;
}

export interface Version {
  version: string;
  commit: string;
  buildTime: string;
}

export interface VersionDto {
  api: Version | undefined;
  lconnector: Version | undefined;
  executor: Version | undefined;
}

export interface FieldTypeDto {
  id: string;
  key: string;
}

export interface AssitentResponse {
  createdWidgetId?: string;
  message?: string;
  errorMessage?: string;
  report: {
    config: string;
    knownActions: string;
    sentenceReport: {
      errorMsg: string;
      finished: string;
      inputText: string;
      resolvedCodes: string[];
      start: string;
    };
    usedDataSet: {
      id: string;
      name: string;
    };
  };
}

export interface AssistantPersonDto {
  id: string;
  name: string;
  surname: string;
}

export interface Workspace extends NamedEntity, PresettableEntity {
  operations: WorkspaceOperations[];
}

export interface WorkspacesPageable extends IPageableResultBase<Workspace> {
  content: Workspace[];
}

export interface WorkspaceCreate {
  name: string;
  description: string;
}

export interface WorkspaceConnection {
  id: string;
  workspaceId: string;
}

export type WorkspaceConnectionPageable<T extends WorkspaceConnection> =
  IPageableResultBase<T>;

interface WorkspaceCreateRelation {
  workspaceId: string;
}

export interface WorkspaceWidgetRelation extends WorkspaceConnection {
  widgetId: string;
}

export type WorkspaceWidgetRelationPageable =
  WorkspaceConnectionPageable<WorkspaceWidgetRelation>;

export interface WorkspaceWidgetCreateRelation extends WorkspaceCreateRelation {
  widgetId: string;
}

export interface WorkspaceScriptDefinitionRelation extends WorkspaceConnection {
  scriptDefinitionId: string;
}

export type WorkspaceScriptDefinitionRelationPageable =
  WorkspaceConnectionPageable<WorkspaceScriptDefinitionRelation>;

export interface WorkspaceScriptDefinitionCreateRelation
  extends WorkspaceCreateRelation {
  scriptDefinitionId: string;
}

export interface WorkspaceDataSourceRelation extends WorkspaceConnection {
  dataSourceId: string;
}

export type WorkspaceDataSourceRelationPageable =
  WorkspaceConnectionPageable<WorkspaceDataSourceRelation>;

export interface WorkspaceDataSourceCreateRelation
  extends WorkspaceCreateRelation {
  dataSourceId: string;
}

export interface WorkspaceDataSetRelation extends WorkspaceConnection {
  dataSetId: string;
}

export type WorkspaceDataSetRelationPageable =
  WorkspaceConnectionPageable<WorkspaceDataSetRelation>;

export interface WorkspaceDataSetCreateRelation
  extends WorkspaceCreateRelation {
  dataSetId: string;
}

export interface WorkspaceDashboardRelation extends WorkspaceConnection {
  dashboardId: string;
}

export type WorkspaceDashboardRelationPageable =
  WorkspaceConnectionPageable<WorkspaceDashboardRelation>;

export interface WorkspaceDashboardCreateRelation
  extends WorkspaceCreateRelation {
  dashboardId: string;
}

export interface WorkspaceConnectorRelation extends WorkspaceConnection {
  connectorId: string;
}

export type WorkspaceConnectorRelationPageable =
  WorkspaceConnectionPageable<WorkspaceConnectorRelation>;

export interface WorkspaceConnectorCreateRelation
  extends WorkspaceCreateRelation {
  connectorId: string;
}

export interface WorkspaceMemberRelation extends WorkspaceConnection {
  usersId: string;
  workspaceMemberTypeId: string;
}

export type WorkspaceMemberRelationPageable =
  WorkspaceConnectionPageable<WorkspaceMemberRelation>;

export interface WorkspaceMemberCreateRelation extends WorkspaceCreateRelation {
  usersId: string;
  workspaceMemberTypeId: string;
}

export enum WorkspaceMemberTypeKey {
  MEMBER = "MEMBER",
  MANAGER = "MANAGER",
  OWNER = "OWNER",
}

export interface WorkspaceMemberType {
  id: string;
  key: WorkspaceMemberTypeKey;
}

export type WorkspaceMemberTypePageable =
  IPageableResultBase<WorkspaceMemberType>;

export interface ServerFileDto {
  name: string;
  path: string;
  directory: boolean;
  files: ServerFileDto[] | undefined;
}
