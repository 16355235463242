import { ConnectorTypeAttributeDto } from "../../data/AppModels";
import { AbstractStore, EntityFilter, IAbstractStore } from "../AbstractStore";
import StoreNames from "../storeNames";
import { ConnectorTypeAttributeStoreTransport } from "./connectorTypeAttributesTransports";

export type IConnectorTypeAttributeStore = IAbstractStore<
  ConnectorTypeAttributeDto,
  EntityFilter
>;

export default class ConnectorTypeAttributeStore
  extends AbstractStore<ConnectorTypeAttributeDto, EntityFilter>
  implements IConnectorTypeAttributeStore
{
  transport: ConnectorTypeAttributeStoreTransport;

  constructor(
    connectorTypeAttributeStoreTransport: ConnectorTypeAttributeStoreTransport
  ) {
    super(StoreNames.CONNECTOR_TYPE_ATTRIBUTES);

    this.transport = connectorTypeAttributeStoreTransport;
  }

  apiFetchAll = async () => this.transport.getConnectorTypeAttributes();

  apiFetchOne = async (id: string) =>
    this.transport.getConnectorTypeAttribute(id);
}
