import { action, computed, configure, observable } from "mobx";

import createDashboardApi from "../api/DashboardApi";
import {
  IWidget,
  IWidgetVisualisationData,
  IDashboardWidgetExternal,
} from "../data/AppModels";
import RestApi from "../services/RestApi";
import BasicStore from "./BasicStore";

export interface IWidgetStore extends BasicStore {
  widgetItems(dashboardId: string): IWidget[];
  widgetExternalItems: IDashboardWidgetExternal[];
  widgetVisualisationsItems: IWidgetVisualisationData[];

  fetchDashboardWidgetItems(dashboardId: string | undefined): Promise<void>;

  fetchDashboardExternalWidgetItems(): Promise<void>;

  removeWidgetFromDashboard(
    dashboardId: string,
    widgetId: string
  ): Promise<void>;

  addWidgetToDashboard(dashboardId: string, widgetId: string): Promise<void>;
}

// strict mode
configure({ enforceActions: "observed" });

class WidgetStore implements IWidgetStore {
  isInstallationSensitive = () => true;

  dashboardApi = createDashboardApi();

  @observable widgetItemsData: Map<string, IWidget[]> = new Map();

  @observable widgetExternalItemsData: IDashboardWidgetExternal[] = [];

  @observable widgetVisualisationsItemsData: IWidgetVisualisationData[] = [];

  @computed
  get widgetItems(): (dashboardId: string) => IWidget[] {
    const _widgetItems = this.widgetItemsData;
    return (dashboardId: string) => _widgetItems.get(dashboardId) || [];
  }

  @computed
  get widgetExternalItems(): IDashboardWidgetExternal[] {
    return this.widgetExternalItemsData;
  }

  @computed
  get widgetVisualisationsItems(): IWidgetVisualisationData[] {
    return this.widgetVisualisationsItemsData;
  }

  @action
  async removeWidgetFromDashboard(dashboardId: string, widgetId: string) {
    try {
      await this.dashboardApi.removeWidgetFromDashboard(dashboardId, widgetId);
      await this.fetchDashboardWidgetItems(dashboardId);
    } catch (error) {
      console.warn("could not remove widget from dashboard", error);
      throw error;
    }
  }

  @action
  async addWidgetToDashboard(dashboardId: string, widgetId: string) {
    try {
      await this.dashboardApi.addWidgetToDashboard(dashboardId, widgetId);
      await this.fetchDashboardWidgetItems(dashboardId);
    } catch (error) {
      console.warn("could not add widget to dashboard", error);
      throw error;
    }
  }

  loadWidgets = async (dashboardId: string | undefined) => {
    const data = await RestApi.loadWidgetsv1(dashboardId);
    if (data && data.content) {
      return data.content;
    }
    return [];
  };

  loadVisualisations = async () => {
    const data = await RestApi.loadWidgetsVisualisationsv1();
    if (data && data.content) {
      return data.content;
    }
    return [];
  };

  @action
  async fetchDashboardWidgetItems(dashboardId: string | undefined) {
    try {
      const [widgets, visualisations] = await Promise.all([
        this.loadWidgets(dashboardId),
        this.loadVisualisations(),
      ]);
      this.setWidgetsData(dashboardId || "", widgets, visualisations);
    } catch (error) {
      console.warn("could not fetch dashboard widget items", error);
      this.setWidgetsData(dashboardId || "", [], []);
      throw error;
    }
  }

  @action
  async fetchDashboardExternalWidgetItems() {
    try {
      const data = await RestApi.loadWidgetsExternalV1();
      this.setExternalData(data);
    } catch (error) {
      console.warn("could not fetch external dashboard widget items", error);
      this.setExternalData([]);
      throw error;
    }
  }

  @action.bound
  setWidgetsData(
    dashboardId: string,
    widgetData: IWidget[],
    widgetVisualisations: IWidgetVisualisationData[]
  ) {
    const newWidgetItemsData = new Map(this.widgetItemsData);
    newWidgetItemsData.set(dashboardId, widgetData);
    this.widgetItemsData = newWidgetItemsData;
    this.widgetVisualisationsItemsData = widgetVisualisations;
  }

  @action.bound
  setExternalData(data: IDashboardWidgetExternal[]) {
    this.widgetExternalItemsData = data;
  }

  clear(): void {
    this.widgetItemsData = new Map();
    this.widgetExternalItemsData = [];
    this.widgetVisualisationsItemsData = [];
  }
}

export default WidgetStore;
