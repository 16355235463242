import React from "react";

import classNames from "classnames";
import * as Popper from "popper.js";
import {
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Badge,
  Button,
} from "reactstrap";

import { EntityRelationType } from "../../store/EntityRelationStores";
import useEntityWorkspaceRelation from "../../store/workspaceStoreHooks";
import usePresentationEntityName from "./usePresentationEntityName";

import "./EntityWorkspaceRelation.scss";
import { useCurrentUserId } from "../../store/userStoreHooks";

import { useTranslation } from "react-i18next";

import UserListItem from "./UserListItem";

export type Props = {
  entityId: string;
  type: EntityRelationType;
};

export default function EntityWorkspaceRelation({ entityId, type }: Props) {
  const myUserId = useCurrentUserId();
  const getEntityPresentationName = usePresentationEntityName();

  const { workspaces: _relatedWorkspaces, users: _relatedUsers } =
    useEntityWorkspaceRelation(type)(entityId);

  const relatedWorkspaceNames = _relatedWorkspaces
    .map((relatedWorkspace) => relatedWorkspace.workspace)
    .map((workspace) => ({
      id: workspace.id,
      nameDefinition: getEntityPresentationName(workspace),
    }))
    .sort((ws1, ws2) => {
      const comparedTitle = ws1.nameDefinition.title.localeCompare(
        ws2.nameDefinition.title
      );
      if (comparedTitle === 0) {
        const subtitle1 = ws1.nameDefinition.subtitle || "";
        const subtitle2 = ws1.nameDefinition.subtitle || "";
        return subtitle1.localeCompare(subtitle2);
      }
      return comparedTitle;
    });

  const relatedUsers = _relatedUsers
    .filter((user) => user.id !== myUserId)
    .sort((u1, u2) => u1.name.localeCompare(u2.name));

  return (
    <div className="entity-workspace-relation">
      {relatedWorkspaceNames.map(
        ({ id, nameDefinition: { fragment, subtitle, tooltip, myPreset } }) => (
          <div
            key={id}
            className={classNames(
              "workspace-name",
              subtitle && "with-subtitle"
            )}
          >
            <i className="icon-folder mr-2" />
            {fragment}
          </div>
        )
      )}
      {relatedUsers.length > 0 && (
        <>
          <hr />
          {relatedUsers.map((user) => (
            <UserListItem user={user} />
          ))}
        </>
      )}
    </div>
  );
}

export type InPopoverProps = Props & {
  placement?: Popper.Placement;
  target?: "button" | "div" | "span";
};

export function EntityWorkspaceRelationInPopover({
  entityId,
  type,
  placement = "bottom",
  target = "button",
}: InPopoverProps) {
  const { t } = useTranslation();

  const popoverId = `ws_${entityId}`;

  const myUserId = useCurrentUserId();

  const { workspaces: _relatedWorkspaces, users: _relatedUsers } =
    useEntityWorkspaceRelation(type)(entityId);

  const relatedUsers = _relatedUsers.filter((user) => user.id !== myUserId);

  return (
    <>
      {React.createElement(target === "button" ? Button : target, {
        id: popoverId,
        color: "light",
        className:
          "text-dark with-icon entity-workspace-relation-popover-target",
        children: (
          <>
            {_relatedWorkspaces.length > 0 && (
              <>
                <i className="icon-folder" />
                <Badge>{_relatedWorkspaces.length}</Badge>
              </>
            )}
            {_relatedWorkspaces.length === 0 && (
              <span className="entity-workspace-relation-empty">
                {t("relatedWorkspacesEmpty")}
              </span>
            )}
            {relatedUsers.length > 0 && (
              <>
                <i className="icon-people ml-3" />
                <Badge>{relatedUsers.length}</Badge>
              </>
            )}
          </>
        ),
      })}
      {_relatedWorkspaces.length > 0 && (
        <UncontrolledPopover
          key={popoverId}
          target={popoverId}
          placement={placement}
          trigger="hover focus"
          delay={{ show: 0, hide: 250 }}
          modifiers={{
            preventOverflow: {
              enabled: true,
            },
          }}
        >
          <PopoverHeader>{t("relatedWorkspaces")}</PopoverHeader>
          <PopoverBody>
            <EntityWorkspaceRelation entityId={entityId} type={type} />
          </PopoverBody>
        </UncontrolledPopover>
      )}
    </>
  );
}
