import { set } from "lodash";

import { IPageableResultBase } from "../data/AppModels";
import RestApi from "../services/RestApi";
import PageToLoad from "./PageToLoad";

export interface CRUDApi<T, U, F> {
  get: (id: string) => Promise<T>;
  getAll: (
    pageToLoad?: PageToLoad,
    filter?: F,
    sort?: SortAttribute[]
  ) => Promise<IPageableResultBase<T>>;
  create: (dtoU: U) => Promise<string>;
  update: (id: string, dtoU: U) => Promise<void>;
  delete: (id: string) => Promise<void>;
}

export interface SortAttribute {
  key: string;
  sort?: "asc" | "desc";
}

export function createCRUDApi<T, U, F>(apiPath: string): CRUDApi<T, U, F> {
  return {
    create(dtoCreate: U): Promise<string> {
      return RestApi.doPost<string>(apiPath, dtoCreate);
    },
    delete(id: string): Promise<void> {
      return RestApi.doDelete(`${apiPath}/${id}`);
    },
    get(id: string): Promise<T> {
      return RestApi.doGet<T>(`${apiPath}/${id}`);
    },
    getAll(
      pageToLoad?: PageToLoad,
      filter?: F,
      sort?: SortAttribute[]
    ): Promise<IPageableResultBase<T>> {
      return getAll<T, F>(apiPath, pageToLoad, filter, sort);
    },
    update(id: string, dtoUpdate: U): Promise<void> {
      return RestApi.doPut<void>(`${apiPath}/${id}`, dtoUpdate);
    },
  };
}

export function toApiParams<F>(
  pageToLoad?: PageToLoad,
  filter?: F,
  sort?: SortAttribute[]
) {
  const params = {};

  if (filter) {
    // eslint-disable-next-line guard-for-in
    for (const property in filter) {
      const value = filter[property];
      set(params, property, Array.isArray(value) ? value.join() : value);
    }
  }
  // TODO consider asc, desc
  if (sort && sort.length > 0) {
    set(
      params,
      "sort",
      Array.isArray(sort)
        ? sort.map((sortAttribute) => sortAttribute.key).join()
        : sort
    );
  }
  if (pageToLoad) {
    set(params, "page", pageToLoad.page);
    set(params, "size", pageToLoad.size);
  }

  return params;
}

function getAll<T, F>(
  apiPath: string,
  pageToLoad?: PageToLoad,
  filter?: F,
  sort?: SortAttribute[]
) {
  return RestApi.doGet<IPageableResultBase<T>>(apiPath, {
    pageable: true,
    params: toApiParams(pageToLoad, filter, sort),
  });
}

function getFull<T, F>(
  apiPath: string,
  pageToLoad?: PageToLoad,
  filter?: F,
  sort?: SortAttribute[]
) {
  return RestApi.doGet<T[]>(apiPath, {
    pageable: false,
    params: toApiParams(pageToLoad, filter, sort),
  });
}

export interface GetApi<T, F = any> {
  get: (id: string) => Promise<T>;
  getAll: (filter?: F, sort?: SortAttribute[]) => Promise<T[]>;
}

export function createGetApi<T, F>(apiPath: string): GetApi<T, F> {
  return {
    get(id: string): Promise<T> {
      return RestApi.doGet<T>(`${apiPath}/${id}`);
    },
    getAll(filter?: F, sort?: SortAttribute[]): Promise<T[]> {
      return getFull<T, F>(apiPath, undefined, filter, sort);
    },
  };
}
