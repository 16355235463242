import { ConnectorTypeAttributeDto, DataResponse } from "../../data/AppModels";
import RestApi from "../../services/RestApi";

export interface ConnectorTypeAttributeStoreTransport {
  getConnectorTypeAttributes: () => Promise<
    DataResponse<ConnectorTypeAttributeDto[]>
  >;
  getConnectorTypeAttribute: (id: string) => Promise<ConnectorTypeAttributeDto>;
}

export function createConnectorTypeAttributeStoreTransport(): ConnectorTypeAttributeStoreTransport {
  return {
    getConnectorTypeAttributes,
    getConnectorTypeAttribute,
  };
}

function getConnectorTypeAttributes() {
  return RestApi.doGet<DataResponse<ConnectorTypeAttributeDto[]>>(
    "/v1/connector-type-attributes",
    {
      returnRawData: true,
    }
  );
}

function getConnectorTypeAttribute(id: string) {
  return RestApi.doGet<ConnectorTypeAttributeDto>(
    `/v1/connector-type-attributes/${id}`
  );
}
