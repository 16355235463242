import { action, configure } from "mobx";

import { Entity } from "../data/AppModels";
import { AbstractStore, EntityFilter, IAbstractStore } from "./AbstractStore";

export interface IAbstractEntityStore<
  T extends Entity = Entity,
  C = any,
  F extends EntityFilter = EntityFilter
> extends IAbstractStore<T, F> {
  update(id: string, updateData: C): Promise<T>;

  create(createData: C): Promise<T>;

  delete(id: string): Promise<void>;
}

// strict mode
configure({ enforceActions: "observed" });

abstract class AbstractEntityStore<T extends Entity, C, F>
  extends AbstractStore<T, F>
  implements IAbstractEntityStore<T, C>
{
  installationSensitive = true;

  replaceOnDelete = false;

  isInstallationSensitive = () => this.installationSensitive;

  abstract apiUpdate: (id: string, updateData: C) => Promise<void>;

  abstract updateEntity: (
    entity: T | undefined,
    id: string,
    updateData: C
  ) => T | undefined;

  @action
  update = async (id: string, updateData: C) => {
    try {
      await this.apiUpdate(id, updateData);
    } catch (error) {
      console.warn(this.name, `could not update:${id}`, updateData, error);
      throw error;
    }
    const currentEntity = this.entitiesArray.find((entity) => entity.id === id);
    let updatedEntity = this.updateEntity(currentEntity, id, updateData);
    if (!updatedEntity) {
      const entity = await this.fetchOne(id, true);
      updatedEntity = entity;
    }

    // replace if existed before
    if (currentEntity) {
      const entityIndex = this.entitiesArray.indexOf(currentEntity);
      const _entities = this.entitiesArray.filter((entity) => entity.id !== id);
      _entities.splice(entityIndex, 0, updatedEntity);
      this.setEntities(_entities);
    }

    return updatedEntity;
  };

  abstract apiCreate: (createData: C) => Promise<string | undefined>;

  abstract newEntity: (id: string, createData: C) => T | undefined;

  @action
  create = async (createData: C) => {
    let id = "";
    try {
      const newId = await this.apiCreate(createData);
      if (!newId) {
        throw new Error(`${this.name} no id returned for new entity`);
      }
      id = newId;
      // internally create object
      const entity = this.newEntity(id, createData);
      if (entity) {
        const _entities = this.entitiesArray.filter(
          (entity) => entity.id !== id
        );
        _entities.push(entity);
        this.setEntities(_entities);
        return entity;
      }
      // if note defined , fetch from api, is assigned to entities list
      const _entity = await this.fetchOne(id, true);
      return _entity;
    } catch (error) {
      console.warn(this.name, "could not create", createData, error);
      throw error;
    }
  };

  abstract apiDelete: (id: string) => Promise<void>;

  @action
  delete = async (id: string) => {
    try {
      await this.apiDelete(id);
      if (this.replaceOnDelete) {
        await this.fetchOne(id, true);
      } else {
        const _entities = this.entitiesArray.filter(
          (entity) => entity.id !== id
        );
        if (_entities.length !== this.entitiesArray.length) {
          this.setEntities(_entities);
        }
      }
    } catch (error) {
      console.warn(this.name, `could not delete${id}`, error);
      throw error;
    }
  };

  @action.bound
  setEntities(entities: T[]) {
    this.entitiesArray = entities;
  }

  @action
  clear(): void {
    this.allFetched = false;
    this.entitiesArray = [];
  }
}

export default AbstractEntityStore;
