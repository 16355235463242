import React, { Component } from "react";

import * as _ from "lodash";
import GooglePicker from "react-google-picker";
import { Trans } from "react-i18next";
import { Card, CardBody, CardText, CardTitle } from "reactstrap";

import DataSetOrigin from "../../../../api/dto/data-set/DataSetOrigin";
import { GoogleConfiguration } from "../../../../data/AppModels";
import RestApi from "../../../../services/RestApi";
import { DataSetOriginIcon } from "../../../widgets/dataSet/TypeIcon";

export interface IProps {
  onClick: () => void;
  filePreview: (fileId: string) => void;
  className?: string;
}

export interface IState {
  oauthToken: string;
  clientId: string | null;
  apiKey: string | null;
}

interface GDrivePicker {
  onChoose: () => void;
}

export default class GoogleDriveCard extends Component<IProps, IState> {
  state: IState;

  gdrivePickerRef: React.RefObject<GDrivePicker>;

  constructor(properties) {
    super(properties);

    this.state = {
      oauthToken: "",
      clientId: null,
      apiKey: null,
    };

    this.onClick = this.onClick.bind(this);
    this.filePreview = this.filePreview.bind(this);
    this.gdrivePickerRef = React.createRef<GDrivePicker>();
  }

  componentDidMount(): void {
    RestApi.googleConfigurationV1().then((data: GoogleConfiguration) => {
      const { state } = this;
      state.oauthToken = data.oauthToken;
      state.clientId = data.clientId;
      state.apiKey = data.apiKey;

      this.setState(state);
    });
  }

  onClick(): void {
    if (this.gdrivePickerRef.current) {
      this.gdrivePickerRef.current.onChoose();
    }
    this.props.onClick();
  }

  filePreview(fileId: string): void {
    this.props.filePreview(fileId);
  }

  render() {
    let picker: JSX.Element | null = null;

    if (_.isNil(this.state.clientId) || _.isNil(this.state.apiKey)) {
      console.warn("api key or client id not found");
    } else {
      picker = (
        <GooglePicker
          ref={this.gdrivePickerRef}
          clientId={this.state.clientId}
          developerKey={this.state.apiKey}
          scope={["https://www.googleapis.com/auth/drive.readonly"]}
          onChange={(data: any) => {
            console.debug("on change:", JSON.stringify(data));

            if (data.action === "picked") {
              const file = data.docs[0];

              RestApi.createDSFromGoogleFileV1({
                id: file.id,
                oauthToken: this.state.oauthToken,
                mimeType: file.mimeType,
                name: file.name,
                serviceId: file.serviceId,
                type: file.type,
                lastEditedUtc: new Date(file.lastEditedUtc),
              }).then((fileId: string) => {
                this.filePreview(fileId);
              });
            }
          }}
          onAuthenticate={(token: any) => {
            console.log("oauth token:", token);
            const { state } = this;
            state.oauthToken = token;
            this.setState(state);
          }}
          onAuthFailed={(data: any) => console.log("on auth failed:", data)}
          multiselect
          navHidden
          authImmediate={false}
          mimeTypes={[
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/x-vnd.oasis.opendocument.spreadsheet",
            "application/vnd.oasis.opendocument.text",
            "application/vnd.google-apps.spreadsheet",
            "text/csv",
          ]}
          viewId="DOCS"
        />
      );
    }

    if (!picker) {
      return null;
    }

    return (
      <Card
        className={`m-card ${this.props.className || ""}`}
        color="light"
        onClick={this.onClick}
      >
        <div className="gdrive-picker">{picker}</div>
        <div
          className="btn btn-link m-card-link"
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <CardBody className="m-card-body">
            <DataSetOriginIcon origin={DataSetOrigin.GOOGLE_DRIVE} />
            <CardTitle className="font-weight-bold">
              <Trans i18nKey="googleDrive" />
            </CardTitle>
            <CardText className="text-center">
              <Trans i18nKey="googleDriveDescr" />
            </CardText>
          </CardBody>
        </div>
      </Card>
    );
  }
}
