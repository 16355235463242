import { IDashboardStore } from "./DashboardStore";
import { IDataSetTemplateStore } from "./dataSet/DataSetTemplateStore";
import { IDatasourceMergeStore } from "./dataSet/DatasourceMergeStore";
import {
  EntityRelationType,
  getEntityRelationStoreName,
  IAbstractEntityRelationStore,
} from "./EntityRelationStores";
import { IInstallationStore } from "./installations/InstallationStore";
import { INotificationsStore } from "./notifications/NotificationsStore";
import { ITasksStore } from "./TasksStore";
import { IUserStore } from "./UserStore";
import { IWidgetStore } from "./WidgetStore";
import { IWorkspaceStore } from "./WorkspaceStore";

export type Stores = {
  notificationsStore?: INotificationsStore;
  widgetStore?: IWidgetStore;
  tasksStore?: ITasksStore;
  mergeStore?: IDatasourceMergeStore;
  dataSetTemplateStore?: IDataSetTemplateStore;
  installationStore?: IInstallationStore;
  dashboardStore?: IDashboardStore;
  userStore?: IUserStore;
  workspaceStore?: IWorkspaceStore;
};

export default class AllStores {
  basicStores: Stores = {};

  constructor(stores: Stores) {
    this.basicStores = stores;
  }

  onInstallationChange() {
    Object.entries(this.basicStores).forEach(([storeName, store]) => {
      if (store) {
        if (store.isInstallationSensitive()) {
          console.log("clearing store (installation sensitive):", storeName);
          store.clear();
        }
      }
    });
  }

  getEntityRelationStore<ST extends IAbstractEntityRelationStore>(
    type: EntityRelationType
  ) {
    const storeName = getEntityRelationStoreName(type);
    const store = this.basicStores[storeName];
    if (store) {
      return store as ST;
    }
  }

  clearAll() {
    Object.entries(this.basicStores).forEach(([storeName, store]) => {
      if (store) {
        console.log("clearing store (all):", storeName);
        store.clear();
      }
    });
  }
}
