export function generateLabels(chart: any) {
  const { data } = chart;
  if (data.labels.length && data.datasets.length) {
    return data.labels.map(function (label: any, i: any) {
      const meta = chart.getDatasetMeta(0);
      const ds = data.datasets[0];
      const arc = meta.data[i];
      const custom = (arc && arc.custom) || {};
      const getValueAtIndexOrDefault = function (source, idx: any, def: any) {
        return typeof source[idx] === "undefined" ? def : source[idx];
      };
      const arcOptions = chart.options.elements.arc;
      const fill = custom.backgroundColor
        ? custom.backgroundColor
        : getValueAtIndexOrDefault(
            ds.backgroundColor,
            i,
            arcOptions.backgroundColor
          );
      const stroke = custom.borderColor
        ? custom.borderColor
        : getValueAtIndexOrDefault(ds.borderColor, i, arcOptions.borderColor);
      const bw = custom.borderWidth
        ? custom.borderWidth
        : getValueAtIndexOrDefault(ds.borderWidth, i, arcOptions.borderWidth);
      return {
        // And finally :
        text: `${label}: ${ds.data[i]}`,
        fillStyle: fill,
        strokeStyle: stroke,
        lineWidth: bw,
        hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
        index: i,
      };
    });
  }
  return [];
}
