enum StoreNames {
  WORKSPACE = "workspaceStore",
  USER = "userStore",
  ENTITY_RELATION = "entity_relation",
  USER_CONNECTORS = "user_connectors",
  CONNECTOR_TYPES = "connector_types",
  CONNECTOR_TYPE_QUERIES = "connector_type_queries",
  CONNECTOR_TYPE_QUERY_ATTRIBUTES = "connector_type_query_attributes",
  CONNECTOR_TYPE_ATTRIBUTES = "CONNECTOR_TYPE_ATTRIBUTES",
  CONNECTORS = "CONNECTORS",
  DATA_SOURCES = "DATA_SOURCES",
}

export default StoreNames;
