/* eslint-disable react/sort-comp */

import React, { Component } from "react";

import classNames from "classnames";
import i18next from "i18next";
import {
  Button,
  CardHeader,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

import createDataSetApi from "../../../api/DataSetApi";
import DataSetOrigin from "../../../api/dto/data-set/DataSetOrigin";
import { FileType, DataSetStatus } from "../../../data/AppModels";
import AppUtils from "../../../data/AppUtils";
import RestApi from "../../../services/RestApi";
import WidgetPresentationType from "../WidgetPresentationType";
import WidgetShowEmbedAction from "./WidgetShowEmbedAction";

interface IProps {
  title: string;
  onShare?: () => void;
  onEdit?: () => void;
  onRemove?: () => void;
  onExport: (type: FileType) => void;
  onFullscreen: () => void;
  onRefresh: () => void;
  onSwitchDashboard?: () => void;
  isFullscreen: boolean;
  isTable: boolean;
  widgetId: string;
  isNew?: boolean;
  presentationType: WidgetPresentationType;
  onDropdownToggle(isOpen: boolean): void;
  dragging?: boolean;
}

interface IState {
  dataExportExpanded: boolean;
  dropdownOpen: boolean;
  dataSetId?: string;
  dataSetStatus?: DataSetStatus;
  dataSetOrigin?: DataSetOrigin;
}

export default class WidgetHeader extends Component<IProps, IState> {
  state: IState;

  metaDataUpdateTimeout: number | null;

  dataSetApi = createDataSetApi();

  constructor(properties: IProps) {
    super(properties);

    this.metaDataUpdateTimeout = null;

    this.state = {
      dataExportExpanded: false,
      dropdownOpen: false,
    };
  }

  static getDerivedStateFromProps(props: IProps, state: IState): IState {
    return {
      ...state,
      // turn the dropdown off while dragging, keep existing value otherwise
      dropdownOpen: props.dragging ? false : state.dropdownOpen,
      dataExportExpanded: props.dragging ? false : state.dataExportExpanded,
    };
  }

  componentDidMount() {
    this.loadDataSetMetadata();
  }

  componentWillUnmount() {
    if (this.metaDataUpdateTimeout) {
      clearInterval(this.metaDataUpdateTimeout);
    }
  }

  scheduleDataSetMetaDataUpdate = () => {
    this.metaDataUpdateTimeout = window.setInterval(() => {
      console.log(
        `current dataset status=${this.state.dataSetStatus} :: widgetId=${this.props.widgetId} widgetTitle="${this.props.title}" datasetId=${this.state.dataSetId}`
      );
      if (
        AppUtils.isUpdatingInProgress(
          this.state.dataSetStatus || null,
          this.state.dataSetOrigin || null
        )
      ) {
        this.loadDataSetMetadata();
      } else if (this.metaDataUpdateTimeout) {
        clearInterval(this.metaDataUpdateTimeout);
        this.metaDataUpdateTimeout = null;
        console.log(
          `dataset update finished -> reload the widget :: widgetId=${this.props.widgetId} widgetTitle="${this.props.title}" datasetId=${this.state.dataSetId}`
        );
        this.props.onRefresh();
      }
    }, 1000);
  };

  onUpdateData = async () => {
    if (this.metaDataUpdateTimeout === null) {
      if (!AppUtils.needsUpdateScheduleOnServer(this.state.dataSetOrigin)) {
        console.log(
          `dataset update does not need to be scheduled -> reload widget :: widgetId=${this.props.widgetId} widgetTitle="${this.props.title}" datasetId=${this.state.dataSetId}`
        );
        this.props.onRefresh();
      } else if (this.state.dataSetId) {
        try {
          await RestApi.updateDatasetData(this.state.dataSetId);
          console.log(
            `dataset update was scheduled :: widgetId=${this.props.widgetId} widgetTitle="${this.props.title}" datasetId=${this.state.dataSetId}`
          );
          this.setState(
            {
              dataSetStatus: DataSetStatus.QUEUE,
            },
            () => {
              this.scheduleDataSetMetaDataUpdate();
            }
          );
        } catch (error) {
          console.log(
            `could not schedule dataset update :: widgetId=${this.props.widgetId} widgetTitle="${this.props.title}" datasetId=${this.state.dataSetId}`
          );
          this.setState({
            dataSetStatus: DataSetStatus.UPDATE_ERROR,
          });
        }
      } else {
        console.log(
          `no dataset available -> can not schedule dataset update :: widgetId=${this.props.widgetId} widgetTitle="${this.props.title}"`
        );
      }
    } else {
      console.log(
        `dataset update is already scheduled :: widgetId=${this.props.widgetId} widgetTitle="${this.props.title}" datasetId=${this.state.dataSetId}`
      );
    }
  };

  onDataExportExpanded = () => {
    this.setState((previousState) => ({
      dataExportExpanded: !previousState.dataExportExpanded,
    }));
  };

  loadDataSetMetadata = async () => {
    if (this.props.widgetId) {
      try {
        const dataSetData = await this.dataSetApi.getAll(undefined, {
          widgetId: this.props.widgetId,
        });
        if (dataSetData.content && dataSetData.content.length > 0) {
          const dataSet = dataSetData.content[0];
          console.log(
            `fetched dataset metadata :: widgetId=${this.props.widgetId} widgetTitle="${this.props.title}" :: datasetId=${dataSet.id} status=${dataSet.status} origin=${dataSet.origin}`
          );
          this.setState({
            dataSetId: dataSet.id,
            dataSetStatus: dataSet.status,
            dataSetOrigin: dataSet.origin,
          });
        } else {
          console.log(
            `no dataset metadata returned :: widgetId=${this.props.widgetId} widgetTitle="${this.props.title}"`
          );
        }
      } catch (error) {
        console.log(
          `could not load dataset metadata :: widgetId=${this.props.widgetId} widgetTitle="${this.props.title}"`
        );
      }
    }
  };

  dropdownToggle = () => {
    this.setState((previousState) => {
      const open = !previousState.dropdownOpen;
      const newState = {
        dataExportExpanded: previousState.dataExportExpanded,
        dropdownOpen: open,
      };

      if (!newState.dropdownOpen) {
        newState.dataExportExpanded = false;
      }

      this.props.onDropdownToggle(open);

      return newState;
    });
  };

  exportData = (type: FileType) => () => {
    this.props.onExport(type);
  };

  goFullscreen = () => this.props.onFullscreen();

  render() {
    const datasetStatus: DataSetStatus | null =
      this.state.dataSetStatus || null;
    const datasetOrigin: DataSetOrigin | null =
      this.state.dataSetOrigin || null;

    const updateInProgress = AppUtils.isUpdatingInProgress(
      datasetStatus,
      datasetOrigin
    );
    const buttonData = AppUtils.getUpdateData(datasetStatus, datasetOrigin);

    const exportSubItemsClassname = `dropdown-sub-item${
      this.state.dataExportExpanded ? "" : " d-none"
    }`;

    return (
      <CardHeader className="d-flex align-items-center">
        {this.props.isNew && (
          <span className="widget-name-tag widget-name-tag-new">new</span>
        )}
        <span className="widget-name w-100" title={this.props.title}>
          {this.props.title}
          {updateInProgress && (
            <span
              className={`ml-2 ${buttonData.customClassName}`}
              title={buttonData.title}
            >
              <i className="icon-refresh" />
            </span>
          )}
        </span>
        <div
          className={classNames(
            "widget-actions"
            // this.props.isFullscreen ? 'fullscreen' : false
          )}
        >
          {this.props.presentationType !== WidgetPresentationType.EMBEDDED && (
            <Button
              outline
              className="btn-icon"
              color="primary"
              onClick={this.goFullscreen}
            >
              <i
                className={
                  this.props.isFullscreen
                    ? "icon-size-actual"
                    : "icon-size-fullscreen"
                }
              />
            </Button>
          )}
          {this.props.presentationType !== WidgetPresentationType.EXTERNAL && (
            <Dropdown
              className="ml-1"
              direction="right"
              isOpen={this.state.dropdownOpen}
              toggle={this.dropdownToggle}
            >
              <DropdownToggle className="btn-icon" outline color="primary">
                <i className="icon-options-vertical" />
              </DropdownToggle>
              <DropdownMenu
                // style={{visibility: this.props.dragging ? 'hidden' : 'visible'}}
                // positionFixed
                flip
                modifiers={{
                  keepTogether: {
                    enabled: false,
                  },
                  preventOverflow: {
                    enabled: true,
                    boundariesElement: "window",
                    escapeWithReference: true,
                    priority: ["bottom", "left", "top", "right"],
                    padding: 8,
                  },
                }}
                right={
                  this.props.presentationType ===
                  WidgetPresentationType.EMBEDDED
                }
              >
                {this.props.onShare && (
                  <DropdownItem
                    key={`${this.props.widgetId}dmShare`}
                    onClick={this.props.onShare}
                  >
                    <i className="icon-share" /> {i18next.t("btnShare")}
                  </DropdownItem>
                )}
                {this.props.onEdit && (
                  <DropdownItem
                    key={`${this.props.widgetId}dmEdit`}
                    onClick={this.props.onEdit}
                  >
                    <i className="icon-pencil" /> {i18next.t("btnEdit")}
                  </DropdownItem>
                )}
                {this.props.onSwitchDashboard && (
                  <DropdownItem
                    key={`${this.props.widgetId}dmSwitch`}
                    onClick={this.props.onSwitchDashboard}
                  >
                    <i className="icon-directions" />{" "}
                    {i18next.t("btnSwitchDashboard")}
                  </DropdownItem>
                )}
                {this.state.dataSetId && (
                  <DropdownItem
                    key={`${this.props.widgetId}dmUpdateData`}
                    onClick={this.onUpdateData}
                    title={buttonData.title}
                    disabled={buttonData.disabled}
                  >
                    <i className={`icon-refresh ${buttonData.customClassName}`}>
                      {buttonData.additionalText}
                    </i>
                    {i18next.t("bntUpdateData")}
                  </DropdownItem>
                )}
                {/* Allow export only in table widgets */}
                {this.props.isTable &&
                  this.props.presentationType ===
                    WidgetPresentationType.APP && (
                    <>
                      <DropdownItem
                        key={`${this.props.widgetId}dmExport`}
                        id="dataExportExpanded"
                        toggle={false}
                        onClick={this.onDataExportExpanded}
                      >
                        <i className="icon-cloud-download" />
                        {i18next.t("btnDataExport")}
                        <i
                          className={`ml-auto icon-arrow-${
                            this.state.dataExportExpanded ? "up" : "down"
                          }`}
                        />
                      </DropdownItem>
                      <DropdownItem
                        key={`${this.props.widgetId}dmeXlsx`}
                        className={exportSubItemsClassname}
                        onClick={this.exportData(FileType.XLSX)}
                      >
                        <i className="fa fa-file-excel-o" /> Excel
                      </DropdownItem>
                      <DropdownItem
                        key={`${this.props.widgetId}dmeCsv`}
                        className={exportSubItemsClassname}
                        onClick={this.exportData(FileType.CSV)}
                      >
                        <i className="fa fa-file-text-o" /> CSV
                      </DropdownItem>
                      <DropdownItem
                        key={`${this.props.widgetId}dmePdf`}
                        className={exportSubItemsClassname}
                        onClick={this.exportData(FileType.PDF)}
                      >
                        <i className="fa fa-file-pdf-o" /> PDF
                      </DropdownItem>
                    </>
                  )}
                {this.props.onRemove && (
                  <>
                    <DropdownItem divider />
                    <DropdownItem
                      key={`${this.props.widgetId}dmRemove`}
                      className="text-danger"
                      onClick={this.props.onRemove}
                    >
                      <i className="icon-trash text-danger" />{" "}
                      {i18next.t("btnRemove")}
                    </DropdownItem>
                  </>
                )}
                {process.env.NODE_ENV === "development" &&
                  this.props.presentationType ===
                    WidgetPresentationType.APP && (
                    <WidgetShowEmbedAction
                      key={`${this.props.widgetId}-embed`}
                      widgetId={this.props.widgetId}
                    />
                  )}
              </DropdownMenu>
            </Dropdown>
          )}
        </div>
      </CardHeader>
    );
  }
}
/* eslint-enable react/sort-comp */
