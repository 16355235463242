import React from "react";

import "./Error404.scss";
import i18next from "i18next";
import { useHistory } from "react-router";
import { Button } from "reactstrap";

import AppConstants from "../../data/AppConstants";
import PageHeadline from "../templates/PageHeadline";

export interface Props {
  message?: string;
  error?: any;
  showAction?: boolean;
}

export default function ({ message, error, showAction }: Props) {
  const history = useHistory();
  return (
    <div className="error-page overlay">
      <div className="error-holder bg-danger text-white">
        <PageHeadline className="text-white" type={1}>
          {i18next.t("error.headline")}
        </PageHeadline>
        <p className="error-text">{message}</p>
        {error && error.message && (
          <p className="error-text">{error.message}</p>
        )}
        {showAction !== false && (
          <Button
            color="light"
            className="with-icon"
            onClick={() => {
              history.push(AppConstants.RT_L_DASHBOARD);
            }}
          >
            <i className="icon-home" />
            {i18next.t("error404.action.default")}
          </Button>
        )}
      </div>
    </div>
  );
}
