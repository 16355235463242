import { action, computed, configure, observable } from "mobx";

import { IUser, ICreateUser, IResetPasswordResponse } from "../data/AppModels";
import RestApi from "../services/RestApi";
import AbstractEntityStore, {
  IAbstractEntityStore,
} from "./AbstractEntityStore";
import { EntityFilter } from "./AbstractStore";
import StoreNames from "./storeNames";

export interface IUserStore extends IAbstractEntityStore<IUser, ICreateUser> {
  resetPassword(id: string, direct?: boolean): Promise<IResetPasswordResponse>;
  getPhoto(id: string, fetchIfMissing?: boolean): string | null;
  fetchPhoto(id: string): Promise<string | null>;
  updatePhoto(id: string, file: File): Promise<string | null>;
  deletePhoto(id: string): Promise<void>;
}
// strict mode
configure({ enforceActions: "observed" });

class UserStore
  extends AbstractEntityStore<IUser, ICreateUser, EntityFilter>
  implements IUserStore
{
  constructor() {
    super(StoreNames.USER);
  }

  installationSensitive = true;

  @observable userPhotos: Map<string, string | null> = new Map();

  @computed
  get getPhoto(): (id: string, fetchIfMissing?: boolean) => string | null {
    const _userPhotos = this.userPhotos;
    return (id, fetchIfMissing = true) => {
      const userPhoto = _userPhotos.get(id);
      if (userPhoto === undefined && fetchIfMissing) {
        this.fetchPhoto(id);
      }
      return userPhoto || null;
    };
  }

  apiFetchAll = () => RestApi.users();

  apiFetchOne = (id: string) => RestApi.user(id);

  apiCreate = async (createData: ICreateUser) => {
    const { id } = await RestApi.createUser(createData);
    return id;
  };

  newEntity = (id: string, createData: ICreateUser) => ({
    id,
    email: createData.email,
    name: createData.name,
    username: createData.username,
  });

  apiUpdate = (id: string, updateData: ICreateUser) =>
    RestApi.updateUser(id, updateData);

  updateEntity = (
    entity: IUser | undefined,
    id: string,
    updateData: ICreateUser
  ) => ({
    ...entity,
    id,
    email: updateData.email,
    name: updateData.name,
    username: updateData.username,
  });

  apiDelete = (id: string) => {
    throw new Error("unsupported operation");
  };

  @action
  resetPassword = async (id: string, direct = false) => {
    try {
      const response = await RestApi.userResetPassword(id, direct);
      return response;
    } catch (error) {
      console.warn(
        `could not initiate users password reset:${id} >> direct:`,
        direct,
        error
      );
      throw error;
    }
  };

  @action
  fetchPhoto = async (id: string, forceFetch = false) => {
    try {
      const currentData = this.userPhotos.get(id);
      if (forceFetch || currentData === undefined) {
        const photoData = await RestApi.loadUserPhotoV1(id);
        if (!photoData) {
          this.setUserPhoto(id, null);
          return null;
        }
        this.setUserPhoto(id, photoData);
        return photoData;
      }
      return currentData;
    } catch (error) {
      console.warn("could not load photo of user:", id, error);
      throw error;
    }
  };

  @action
  updatePhoto = async (id: string, file: File) => {
    try {
      await RestApi.uploadUserPhotoV1(file, id);
    } catch (error) {
      console.warn("could not upload photo of user:", id, error);
      throw error;
    }
    const photoData = await this.fetchPhoto(id, true);
    return photoData;
  };

  @action
  deletePhoto = async (id: string) => {
    try {
      await RestApi.deleteUserPhotoV1(id);
      this.setUserPhoto(id, undefined);
    } catch (error) {
      console.warn("could not delete photo of user:", id, error);
      throw error;
    }
  };

  @action.bound
  setUserPhoto(id: string, photoData: string | null | undefined) {
    const newPhotos = new Map(this.userPhotos);
    if (photoData === undefined && newPhotos.has(id)) {
      newPhotos.delete(id);
      this.userPhotos = newPhotos;
      return;
    }
    const currentPhotoData = newPhotos.get(id);
    if (currentPhotoData !== photoData && photoData !== undefined) {
      newPhotos.set(id, photoData);
      this.userPhotos = newPhotos;
    }
  }

  clear(): void {
    super.clear();
    this.userPhotos = new Map();
  }
}

export default UserStore;
