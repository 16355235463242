import React, { lazy } from "react";

import { HashRouter, Switch, Route } from "react-router-dom";

import AppConstants from "../../data/AppConstants";
import PrivateRoute from "../private-route/PrivateRoute";

const Login = lazy(() => import("../../login/Login"));
const PasswordReset = lazy(() => import("../../login/PasswordReset"));
const Layout = lazy(() => import("../../layout/Layout"));
const External = lazy(() => import("../views/ExternalView"));
const Error404 = lazy(() => import("../error/Error404"));

const AppRouter = () => (
  <HashRouter>
    <Switch>
      <Route path={AppConstants.RT_EXTERNAL} component={External} />
      <Route path={AppConstants.RT_EMBED_WIDGET} component={External} />
      <Route path={AppConstants.RT_PASSWORD_RESET} component={PasswordReset} />
      <PrivateRoute path={AppConstants.RT_L} component={Layout} />
      <Route path={AppConstants.RT_ROOT} component={Login} exact />
      <Route component={Error404} />{" "}
      {/* TODO use some login-like page with app logo */}
    </Switch>
  </HashRouter>
);

export default AppRouter;
