import React from "react";

import classNames from "classnames";

import { useUserPhoto } from "../../store/userStoreHooks";

import "./UserPhoto.scss";

export type Props = {
  userId: string | undefined;
  theme?: "dark" | "light";
  size?: "small" | "big";
  className?: string;
};

export default function UserPhoto({
  userId,
  theme = "light",
  size = "small",
  className,
}: Props) {
  const userPhoto = useUserPhoto(userId);

  return (
    <div
      className={classNames(
        "img-avatar",
        !userPhoto && "icon-only",
        theme,
        size,
        className
      )}
    >
      {userPhoto && (
        <div
          className="user-photo"
          style={{
            backgroundImage: `url(data:image/jpg;base64,${userPhoto})`,
          }}
        />
      )}
      {!userPhoto && <i className="icon-user" />}
    </div>
  );
}
