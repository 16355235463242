import {
  DataSourceDto,
  DataSourceQueryAttributeDto,
  DataSourceQueryAttributeUpdateDto,
  DataSourceUpdateDto,
} from "../../data/AppModels";
import { ConnectorTypeQueryAttributeDto } from "../../data/dto/type/types";
import AbstractEntityStore, {
  IAbstractEntityStore,
} from "../AbstractEntityStore";
import { EntityFilter } from "../AbstractStore";
import { IConnectorTypeQueryAttributesStore } from "../connector/ConnectorTypeQueryAttributesStore";
import StoreNames from "../storeNames";
import { DataSourceTransport } from "./dataSourceTransport";
import DataSource from "./domain/DataSource";
import DataSourceQueryAttribute from "./domain/DataSourceQueryAttribute";

export type IDataSourcesStore = IAbstractEntityStore<
  DataSource,
  DataSource,
  EntityFilter
>;

export default class DataSourcesStore
  extends AbstractEntityStore<DataSource, DataSource, EntityFilter>
  implements IDataSourcesStore
{
  installationSensitive = true;

  replaceOnDelete = false;

  connectorTypeQueryAttributesStore: IConnectorTypeQueryAttributesStore;

  transport: DataSourceTransport;

  constructor(
    connectorTypeQueryAttributesStore: IConnectorTypeQueryAttributesStore,
    transport: DataSourceTransport
  ) {
    super(StoreNames.DATA_SOURCES);
    this.transport = transport;
    this.connectorTypeQueryAttributesStore = connectorTypeQueryAttributesStore;
  }

  createDataSource = (
    dto: DataSourceDto,
    queryAttributes: DataSourceQueryAttributeDto[],
    queryTypes: ConnectorTypeQueryAttributeDto[]
  ): DataSource => {
    // for query , create attributes
    const _queryAttributes: DataSourceQueryAttribute[] = queryTypes
      .filter((at) => at.connectorTypeQuery.id === dto.connectorTypeQuery.id)
      .map((type) => {
        // find existing or create empty
        const attribute = queryAttributes.find(
          (a) =>
            a.dataSourceId === dto.id &&
            a.connectorTypeQueryAttribute.id === type.id
        );

        let _attribute: DataSourceQueryAttribute;
        if (attribute) {
          _attribute = {
            ...attribute,
            type: attribute.connectorTypeQueryAttribute,
            changed: false,
          };
        } else {
          _attribute = {
            id: "",
            installationId: "",
            type,
            changed: false,
          };
        }

        return _attribute;
      });

    const dataSource: DataSource = {
      ...dto,
      changed: false,
      queryAttributes: _queryAttributes,
    };

    return dataSource;
  };

  apiFetchAll = async () => {
    const page = await this.transport.getDataSources();
    const dataSources = page.content;
    const dataSourceIds = dataSources.map((c) => c.id);

    // get created query attributes
    const attributes = (
      await this.transport.getDataSourceQueryAttributes(dataSourceIds)
    ).content;
    // get types to create schema
    const queryAttributeTypes =
      (await this.connectorTypeQueryAttributesStore?.all()) || [];
    const _dataSources: DataSource[] = dataSources.map((dto) =>
      this.createDataSource(dto, attributes, queryAttributeTypes)
    );

    return {
      ...page,
      content: _dataSources,
    };
  };

  apiFetchOne = async (id: string): Promise<DataSource> => {
    const dataSourceDto = await this.transport.getDataSource(id);
    const queryAttributes = (
      await this.transport.getDataSourceQueryAttributes(id)
    ).content;
    // get types to create schema
    const queryAttributeTypes =
      (await this.connectorTypeQueryAttributesStore?.all()) || [];

    return this.createDataSource(
      dataSourceDto,
      queryAttributes,
      queryAttributeTypes
    );
  };

  apiCreate = async (dataSource: DataSource) => {
    // console.info('create dataSource :: ' + JSON.stringify(dataSource))
    const dtoU: DataSourceUpdateDto = {
      name: dataSource.name,
      description: dataSource.description || "",
      connectorId: dataSource.connectorId,
      connectorTypeQueryId: dataSource.connectorTypeQuery?.id || "",
    };

    return this.transport.createDataSource(dtoU);
  };

  apiDelete = async (id: string): Promise<void> =>
    this.transport.deleteDataSource(id);

  apiUpdate = async (id: string, dataSource: DataSource): Promise<void> => {
    // update main entity if changed
    if (dataSource.changed) {
      const dtoU: DataSourceUpdateDto = {
        ...dataSource,
        description: dataSource.description || "",
        connectorTypeQueryId: dataSource.connectorTypeQuery?.id!,
      };

      await this.transport.updateDataSource(dtoU, dataSource.id);
    }

    // update or create attributes
    const promises: Promise<any>[] = [];
    console.info(
      `Store update :: items :: ${JSON.stringify(
        dataSource.queryAttributes.filter((attr) => attr.changed)
      )}`
    );
    // just update attributes
    dataSource.queryAttributes
      .filter((attr) => attr.changed)
      .forEach((attr) => {
        const dtoU: DataSourceQueryAttributeUpdateDto = {
          dataSourceId: dataSource.id,
          connectorTypeQueryAttributeId: attr.type.id,
          value: attr.value,
        };
        console.info(`dto :: ${JSON.stringify(dtoU)}`);
        // update
        if (attr.id !== "") {
          promises.push(
            this.transport.updateDataSourceQueryAttribute(dtoU, attr.id)
          );
        } else {
          promises.push(this.transport.createDataSourceQueryAttribute(dtoU));
        }
      });
    await Promise.all(promises);
    return Promise.resolve();
  };

  newEntity = (id: string, createData: DataSource): DataSource | undefined =>
    undefined; // force fetch

  updateEntity = (
    entity: DataSource | undefined,
    id: string,
    updateData: DataSource
  ): DataSource | undefined => undefined; // force fetch
}
