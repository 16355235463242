import React from "react";

import classNames from "classnames";
import "./PageContent.scss";

export interface IProps {
  className?: string;
  padding?: boolean;
  fullscreenContent?: boolean;
}

class PageContent extends React.Component<IProps, any> {
  static defaultProps = {
    padding: true,
  };

  render() {
    return (
      <div
        className={classNames(
          "page-box page-content",
          this.props.padding ? undefined : "no-padding",
          this.props.fullscreenContent ? "fullscreen-content" : false,
          this.props.className
        )}
      >
        {this.props.children}
      </div>
    );
  }
}

export default PageContent;
