import React from "react";

import classNames from "classnames";
import "./PageHeadline.scss";

export interface IProps {
  className?: string;
  type?: number;
}

class PageHeadline extends React.Component<IProps, any> {
  static defaultProps = {
    type: 1,
  };

  render() {
    return React.createElement(`h${this.props.type}`, {
      className: classNames(
        "page-headline",
        `type-h-${this.props.type}`,
        this.props.className
      ),
      children: this.props.children,
    });
  }
}

export default PageHeadline;
