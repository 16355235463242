import React from "react";

import isNil from "lodash/isNil";
import { MobXProviderContext } from "mobx-react";

import { IApplicationStore } from "./ApplicationStore";
import { IConnectorsStore } from "./connector/ConnectorsStore";
import { IConnectorTypeAttributeStore } from "./connector/ConnectorTypeAttributeStore";
import { IConnectorTypeQueriesStore } from "./connector/ConnectorTypeQueriesStore";
import { IConnectorTypesStore } from "./connector/ConnectorTypesStore";
import { IUserConnectorStore } from "./connector/UserConnectorStore";
import { IDashboardStore } from "./DashboardStore";
import { IDataSetTemplateStore } from "./dataSet/DataSetTemplateStore";
import { IDataSourcesStore } from "./dataSource/DataSourcesStore";
import {
  EntityRelationType,
  getEntityRelationStoreName,
  IAbstractEntityRelationStore,
} from "./EntityRelationStores";
import { IInstallationStore } from "./installations/InstallationStore";
import { INotificationsStore } from "./notifications/NotificationsStore";
import StoreNames from "./storeNames";
import { IUserStore } from "./UserStore";
import { IWidgetStore } from "./WidgetStore";
import { IWorkspaceStore } from "./WorkspaceStore";

function useStores() {
  return React.useContext(MobXProviderContext);
}

function useStore<T>(storeName: string) {
  const stores = useStores();
  const store = stores[storeName];
  if (isNil(store)) {
    if (storeName.length > 0) {
      console.warn(`store '${storeName}' does not exists :: stores=`, stores);
    }
    return undefined;
  }
  return store as T;
}

export function useInstallationStore() {
  return useStore<IInstallationStore>("installationStore");
}

export function useNotificationStore() {
  return useStore<INotificationsStore>("notificationsStore");
}

export function useApplicationStore() {
  return useStore<IApplicationStore>("applicationStore");
}

export function useDashboardStore() {
  return useStore<IDashboardStore>("dashboardStore");
}

export function useWidgetStore() {
  return useStore<IWidgetStore>("widgetStore");
}

export function useUserStore() {
  return useStore<IUserStore>(StoreNames.USER);
}

export function useWorkspaceStore() {
  return useStore<IWorkspaceStore>(StoreNames.WORKSPACE);
}

export function useDataSetTemplateStore() {
  return useStore<IDataSetTemplateStore>("dataSetTemplateStore");
}

export function useEntityRelationStore<ST extends IAbstractEntityRelationStore>(
  type: EntityRelationType | undefined
) {
  return useStore<ST>(type ? getEntityRelationStoreName(type) : "");
}

export function useUserConnectorsStore() {
  return useStore<IUserConnectorStore>(StoreNames.USER_CONNECTORS);
}

export function useConnectorTypesStore() {
  return useStore<IConnectorTypesStore>(StoreNames.CONNECTOR_TYPES);
}

export function useConnectorTypeQueriesStore() {
  return useStore<IConnectorTypeQueriesStore>(
    StoreNames.CONNECTOR_TYPE_QUERIES
  );
}

export function useConnectorTypeAttributesStore() {
  return useStore<IConnectorTypeAttributeStore>(
    StoreNames.CONNECTOR_TYPE_ATTRIBUTES
  );
}

export function useConnectorsStore() {
  return useStore<IConnectorsStore>(StoreNames.CONNECTORS);
}

export function useDataSourcesStore() {
  return useStore<IDataSourcesStore>(StoreNames.DATA_SOURCES);
}
