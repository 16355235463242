import { observable, computed, action } from "mobx";

import {
  Workspace,
  WorkspaceCreate,
  WorkspaceMemberType,
} from "../data/AppModels";
import RestApi from "../services/RestApi";
import AbstractEntityStore, {
  IAbstractEntityStore,
} from "./AbstractEntityStore";
import { EntityFilter } from "./AbstractStore";
import StoreNames from "./storeNames";

export interface IWorkspaceStore
  extends IAbstractEntityStore<Workspace, WorkspaceCreate, EntityFilter> {
  init: () => Promise<[Workspace[], WorkspaceMemberType[]]>;
  memberTypes: WorkspaceMemberType[];
}

export default class WorkspaceStore
  extends AbstractEntityStore<Workspace, WorkspaceCreate, EntityFilter>
  implements IWorkspaceStore
{
  constructor() {
    super(StoreNames.WORKSPACE);
  }

  installationSensitive = true;

  @observable memberTypesArray: WorkspaceMemberType[] = [];

  @computed
  get memberTypes(): WorkspaceMemberType[] {
    return this.memberTypesArray;
  }

  apiFetchAll = () => RestApi.workspacesGetAll();

  apiFetchOne = (id: string) => RestApi.workspacesGet1(id);

  apiCreate = (createData: WorkspaceCreate) =>
    RestApi.workspacesPost(createData);

  newEntity = (id: string, createData: WorkspaceCreate) => undefined; // will trigger fetch

  apiUpdate = (id: string, updateData: WorkspaceCreate) =>
    RestApi.workspacesPut1(id, updateData);

  updateEntity = (
    entity: Workspace | undefined,
    id: string,
    updateData: WorkspaceCreate
  ) => undefined; // will trigger fetch

  apiDelete = (id: string) => {
    throw new Error("unsupported operation");
  };

  init = () => Promise.all([this.fetchAll(), this.fetchAllMemberTypes()]);

  @action
  fetchAllMemberTypes = async () => {
    try {
      const data = await RestApi.workspacesGetAllMemberTypes();
      const memberTypes = data && data.content ? data.content : [];
      this.setMemberTypes(memberTypes);
      return memberTypes;
    } catch (error) {
      console.warn(this.name, "could not fetch member types", error);
      this.setMemberTypes([]);
      throw error;
    }
  };

  @action.bound
  setMemberTypes(memberTypes: WorkspaceMemberType[]) {
    this.memberTypesArray = memberTypes;
  }

  clear(): void {
    super.clear();
    this.memberTypesArray = [];
  }
}
