import { useState, useEffect } from "react";

import { useObserver } from "mobx-react";

import { useStoredEntityName } from "./storeHooks";
import { useApplicationStore, useUserStore } from "./useStores";

export function useUserName() {
  const userStore = useUserStore();
  return useStoredEntityName(userStore);
}

export function useCurrentUserId() {
  const applicationStore = useApplicationStore();
  const { currentUser } = useObserver(() => ({
    currentUser: applicationStore?.currentUser,
  }));
  return currentUser?.id;
}

export function useGetUserPhoto() {
  const userStore = useUserStore();
  const { getPhoto } = useObserver(() => ({
    getPhoto: userStore?.getPhoto,
  }));
  return getPhoto;
}

export function useUserPhoto(userId: string | undefined) {
  const getPhoto = useGetUserPhoto();

  const [userPhoto, setUserPhoto] = useState<string | null>(null);

  useEffect(() => {
    if (userId && getPhoto) {
      setUserPhoto(getPhoto(userId));
    } else {
      setUserPhoto(null);
    }
  }, [userId, getPhoto]);

  return userPhoto;
}

export function useCurrentUserPhoto() {
  const userId = useCurrentUserId();
  return useUserPhoto(userId);
}
