import React from "react";

import Loader from "../loader/Loader";

interface LoadingProperties {
  show: boolean;
  style?: {};
}

const Loading = (properties: LoadingProperties) => {
  if (!properties.show) {
    return <></>;
  }

  return (
    <div className="loadingContent" style={properties.style}>
      <Loader />
    </div>
  );
};

export default Loading;
