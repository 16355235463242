import { ConnectorDto } from "../../api/dto/connector/ConnectorDto";
import { ConnectorUpdateDto } from "../../api/dto/connector/ConnectorUpdateDto";
import {
  ConnectorAttributeDto,
  ConnectorAttributeUpdateDto,
  ConnectorTypeAttributeDto,
} from "../../data/AppModels";
import AbstractEntityStore, {
  IAbstractEntityStore,
} from "../AbstractEntityStore";
import { EntityFilter } from "../AbstractStore";
import StoreNames from "../storeNames";
import { ConnectorStoreTransport } from "./connectorTransports";
import ConnectorTypeAttributeStore from "./ConnectorTypeAttributeStore";
import Connector from "./domain/Connector";
import ConnectorAttribute from "./domain/ConnectorAttribute";

export type IConnectorsStore = IAbstractEntityStore<Connector, Connector>;

export default class ConnectorsStore
  extends AbstractEntityStore<Connector, Connector, EntityFilter>
  implements IConnectorsStore
{
  installationSensitive = true;

  replaceOnDelete = false;

  connectorTypeAttributesStore: ConnectorTypeAttributeStore;

  transport: ConnectorStoreTransport;

  constructor(
    connectorTypeAttributesStore: ConnectorTypeAttributeStore,
    transport: ConnectorStoreTransport
  ) {
    super(StoreNames.CONNECTORS);
    this.transport = transport;
    this.connectorTypeAttributesStore = connectorTypeAttributesStore;
  }

  createConnector = (
    dto: ConnectorDto,
    attributes: ConnectorAttributeDto[],
    types: ConnectorTypeAttributeDto[]
  ): Connector => {
    // pick all types which can be defined by connector
    const cAttributes: ConnectorAttribute[] = types
      .filter((at) => at.connectorTypeId === dto.connectorTypeId)
      .map((type) => {
        // find existing or create empty
        const attribute = attributes.find(
          (a) =>
            a.connectorId === dto.id && a.connectorTypeAttributeId === type.id
        );

        let cAtribute: ConnectorAttribute;
        if (attribute) {
          cAtribute = {
            ...attribute,
            type: attribute.connectorTypeAttribute,
            changed: false,
          };
        } else {
          cAtribute = {
            id: "",
            installationId: "",
            type,
            changed: false,
          };
        }

        return cAtribute;
      });

    const connector: Connector = {
      ...dto,
      changed: false,
      attributes: cAttributes,
    };

    return connector;
  };

  apiFetchAll = async () => {
    const page = await this.transport.getConnectors(false);
    const connectors = page.content;
    const connectorIds = connectors.map((c) => c.id);

    // get created attributes
    const attributes = (
      await this.transport.getConnectorAttributes(connectorIds, false)
    ).content;
    // get types to create schema
    const attributeTypes =
      (await this.connectorTypeAttributesStore?.all()) || [];
    const _connectors: Connector[] = connectors.map((dto) =>
      this.createConnector(dto, attributes, attributeTypes)
    );

    return {
      ...page,
      content: _connectors,
    };
  };

  apiFetchOne = async (id: string): Promise<Connector> => {
    const connectorDto = await this.transport.getConnector(id);
    const attributes = (await this.transport.getConnectorAttributes(id, false))
      .content;
    // get types to create schema
    const attributeTypes =
      (await this.connectorTypeAttributesStore?.all()) || [];

    return this.createConnector(connectorDto, attributes, attributeTypes);
  };

  apiCreate = async (connector: Connector) => {
    const dtoU: ConnectorUpdateDto = {
      ...connector,
      description: connector.description!,
      connectorTypeId: connector.connectorType?.id || "",
    };

    return this.transport.createConnector(dtoU);
  };

  apiDelete = async (id: string): Promise<void> =>
    this.transport.deleteConnector(id);

  apiUpdate = async (id: string, connector: Connector): Promise<void> => {
    // update main entity if changed
    if (connector.changed) {
      const dtoU: ConnectorUpdateDto = {
        ...connector,
        description: connector.description!,
        connectorTypeId: connector.connectorType?.id || "",
      };

      await this.transport.updateConnector(dtoU, connector.id);
    }

    // update or create attributes
    const promises: Promise<any>[] = [];
    console.info(
      `Store update :: items :: ${JSON.stringify(
        connector.attributes.filter((attr) => attr.changed)
      )}`
    );
    // just update attributes
    connector.attributes
      .filter((attr) => attr.changed)
      .forEach((attr) => {
        const dtoU: ConnectorAttributeUpdateDto = {
          forUser: false,
          connectorId: connector.id,
          connectorTypeAttributeId: attr.type.id,
          value: attr.value,
        };
        console.info(`dto :: ${JSON.stringify(dtoU)}`);
        // update
        if (attr.id !== "") {
          promises.push(this.transport.updateConnectorAttribute(dtoU, attr.id));
        } else {
          promises.push(this.transport.createConnectorAttributes(dtoU));
        }
      });
    await Promise.all(promises);
    return Promise.resolve();
  };

  newEntity = (id: string, createData: Connector): Connector | undefined =>
    undefined; // force fetch

  updateEntity = (
    entity: Connector | undefined,
    id: string,
    updateData: Connector
  ): Connector | undefined => undefined; // force fetch
}
