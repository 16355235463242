import React, { useState, useMemo, useEffect, useRef } from "react";

import ReactDOM from "react-dom";
import { toast } from "react-toastify";
import { Except } from "type-fest";

import ErrorOverlay from "../components/error/ErrorOverlay";
import LoadingOverlay, {
  LoadingOverlayItem,
} from "../components/loading/LoadingOverlay";

type FnArgs0 = [];
type FnArgs1<ARG1 = any> = [ARG1];
type FnArgs2<ARG1 = any, ARG2 = any> = [ARG1, ARG2];
type FnArgs3<ARG1 = any, ARG2 = any, ARG3 = any> = [ARG1, ARG2, ARG3];

type FunctionArguments = FnArgs0 | FnArgs1 | FnArgs2 | FnArgs3;

type AsyncFunction<RET = unknown, ARGS extends FunctionArguments = []> = (
  ...args: ARGS
) => Promise<RET>;

export interface LoudAsyncComponentProps {
  doLoudAsync: <RET = any, ARGS extends FunctionArguments = FnArgs0>(
    asyncFunction: AsyncFunction<RET, ARGS>,
    options?: LoudAsyncCallOptions<RET, ARGS>
  ) => Promise<RET | undefined>;
}

type ArrowFunction = () => any;
type ArrowFunctionOneArg<T> = (arg: T) => any;

export type SuccessResolution = "none" | "toast";
export type ErrorResolution = "none" | "toast" | "page-overlay";
export type LoadingIndicator = "none" | "page-overlay";

interface LoudAsyncBasicCallOptions {
  loadingIndicator?: LoadingIndicator;
  loadingMessage?: string;
  successResolution?: SuccessResolution;
  successMessage?: string;
  errorResolution: ErrorResolution; // TODO logout -> signout and display some screen with a message
  errorMessage: string;
  errorShowAction?: boolean;
}

export interface LoudAsyncCallOptions<
  RET = any,
  ARGS extends FunctionArguments = FnArgs0
> extends LoudAsyncBasicCallOptions {
  onStart?: ArrowFunction;
  onSuccess?: ArrowFunctionOneArg<RET>;
  onError?: ArrowFunctionOneArg<any>;
  onFinish?: ArrowFunction;
  arguments?: ARGS;
}

const defaultAsyncOptions: LoudAsyncBasicCallOptions = {
  loadingIndicator: "none",
  successResolution: "none",
  errorResolution: "page-overlay",
  errorMessage: "",
};

type ErrorOverlayData = {
  message: string;
  error: any;
  showAction: boolean;
  causedBy: AsyncFunction;
};

const isPageOverlay = (indicator?: LoadingIndicator | ErrorResolution) =>
  indicator === "page-overlay";

export default function withLoudAsync<P extends LoudAsyncComponentProps>(
  Component: React.ComponentType<P>
): React.FC<Except<P, "doLoudAsync">> {
  return (props: Except<P, "doLoudAsync">) => {
    const [loadingOverlays, setLoadingOverlays] = useState<
      LoadingOverlayItem[]
    >([]);
    const [errorOverlay, setErrorOverlay] = useState<ErrorOverlayData | null>(
      null
    );
    const overlayParent = useMemo(() => document.querySelector("main"), []);
    useEffect(() => {
      if (overlayParent) {
        if (loadingOverlays.length > 0 || errorOverlay) {
          overlayParent.classList.add("with-overlay");
        } else {
          overlayParent.classList.remove("with-overlay");
        }
      }
    }, [overlayParent, loadingOverlays, errorOverlay]);

    const doLoudAsync = useRef(
      // it's a ref because it must be the same instance of the function during the entire life of this HOC
      async <RET extends unknown, ARGS extends FunctionArguments>(
        asyncFunction: AsyncFunction<RET, ARGS>,
        options: LoudAsyncCallOptions<RET, ARGS>
      ) => {
        if (
          options &&
          options.successResolution &&
          options.successResolution !== "none" &&
          !options.successMessage
        ) {
          console.warn(
            `success resolution is set to '${options.successResolution}', but no message is provided`
          );
        }
        if (
          options &&
          options.loadingMessage &&
          (options.loadingIndicator === undefined ||
            options.loadingIndicator === "none")
        ) {
          console.warn(
            `loading message is set to '${options.loadingMessage}', but loading indicator is disabled -> message will not be shown`
          );
        }
        if (errorOverlay && errorOverlay.causedBy !== asyncFunction) {
          console.warn(
            "there is already an error overlay active :: any other loud async calls are dropped"
          );
          return undefined;
        }
        const callId = `loading-${Date.now()}`;
        const opts: LoudAsyncCallOptions<RET, ARGS> = {
          ...defaultAsyncOptions,
          ...options,
        };
        if (opts && isPageOverlay(opts.loadingIndicator)) {
          setLoadingOverlays((overlayItems) => [
            ...overlayItems,
            {
              id: callId,
              message: opts.loadingMessage || "",
            },
          ]);
        }
        setErrorOverlay(null);
        if (opts && opts.onStart) {
          opts.onStart();
        }
        try {
          // @ts-ignore - arguments are not required because of functions with zero parameter - but if the function needs arguments
          // and they are actually not provided, then the call might fail in the runtime
          // TODO fix this typechecking nightmare a remove ts-ignore
          const result = await asyncFunction(...(opts.arguments || []));
          if (opts && isPageOverlay(opts.loadingIndicator)) {
            setTimeout(() => {
              setLoadingOverlays((overlayItems) =>
                overlayItems.filter((item) => item.id !== callId)
              );
            }, 100);
          }
          if (opts.successResolution === "toast") {
            toast.success(opts.successMessage);
          }
          if (opts && opts.onSuccess) {
            opts.onSuccess(result);
          }
          if (opts && opts.onFinish) {
            opts.onFinish();
          }
          return result;
        } catch (error) {
          console.error("withLoudAsync error ::", error);
          if (opts && isPageOverlay(opts.loadingIndicator)) {
            setLoadingOverlays((overlayItems) =>
              overlayItems.filter((item) => item.id !== callId)
            );
          }
          if (opts && isPageOverlay(opts.errorResolution)) {
            setErrorOverlay({
              message: opts.errorMessage,
              showAction: opts.errorShowAction !== false,
              error,
              causedBy: asyncFunction,
            });
          }
          if (opts.errorResolution === "toast") {
            if (!errorOverlay) {
              toast.error(opts.errorMessage);
            }
          }
          if (opts && opts.onError) {
            opts.onError(error);
          }
          if (opts && opts.onFinish) {
            opts.onFinish();
          }
          return undefined;
        }
      }
    );

    return (
      <>
        <Component {...(props as P)} doLoudAsync={doLoudAsync.current} />
        {loadingOverlays.length > 0 &&
          overlayParent &&
          ReactDOM.createPortal(
            <LoadingOverlay items={loadingOverlays} />,
            overlayParent
          )}
        {errorOverlay &&
          overlayParent &&
          ReactDOM.createPortal(
            <ErrorOverlay
              message={errorOverlay.message}
              error={errorOverlay.error}
              showAction={errorOverlay.showAction}
            />,
            overlayParent
          )}
      </>
    );
  };
}
