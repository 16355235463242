import isNil from "lodash/isNil";

export enum AccessTokenValueReason {
  INIT = "init",
  INIT_FAILED = "init-failed",
  LOGIN_USER = "login-user",
  LOGIN_EXTERNAL = "login-external",
  LOGOUT = "logout",
  TOKEN_EXPIRED = "token-expired",
  TOKEN_INVALID = "token-invalid",
}

export enum AuthenticationState {
  AUTHENTICATED_USER,
  AUTHENTICATED_EXTERNAL,
  NOT_AUTHENTICATED,
  NOT_AUTHENTICATED_INIT_FAILED,
  NOT_AUTHENTICATED_LOGOUT,
  NOT_AUTHENTICATED_TOKEN_EXPIRED,
  NOT_AUTHENTICATED_TOKEN_INVALID,
}

export type AccessTokenInitOptions = {
  value: string | null;
  reason: AccessTokenValueReason;
};

export const parseToken = (token: string | null | undefined) => {
  if (isNil(token)) return undefined;
  const tokenParts = token.split(".");
  if (tokenParts.length !== 3) return undefined;
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
        })
        .join("")
    );
    return {
      validToken: token,
      payload: JSON.parse(jsonPayload),
    };
  } catch (error) {
    console.warn(`could not parse token: "${token}"`, error);
    return undefined;
  }
};

export type TokenState = "ok" | "invalid" | "expired";

export const validateToken = (token: string | null | undefined) => {
  const parsedToken = parseToken(token || "");
  console.log(
    "authentication.validateToken :: parsedToken:",
    parsedToken ? parsedToken.payload : undefined
  );

  const now = Math.floor(Date.now() / 1000);
  const tokenExpired =
    parsedToken && parsedToken.payload.exp && parsedToken.payload.exp < now;
  if (parsedToken && parsedToken.payload.exp) {
    console.log(
      "authentication.validateToken :: tokenExpired:",
      tokenExpired,
      "- diff:",
      parsedToken.payload.exp - now
    );
  }

  let tokenState: TokenState = "ok";
  if (!parsedToken) {
    tokenState = "invalid";
  }
  if (tokenExpired) {
    tokenState = "expired";
  }
  return { parsedToken, tokenState };
};

export const validateTokenInit = (tokenInit: AccessTokenInitOptions) => {
  const { value, reason } = tokenInit;
  const validatedToken = validateToken(value);
  let finalReason = reason;
  if (value !== null && validatedToken.tokenState === "invalid") {
    finalReason = AccessTokenValueReason.TOKEN_INVALID;
  }
  if (value !== null && validatedToken.tokenState === "expired") {
    finalReason = AccessTokenValueReason.TOKEN_EXPIRED;
  }
  return {
    token: validatedToken.parsedToken?.validToken || null,
    tokenReason: finalReason,
  };
};
