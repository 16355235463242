import React, { useCallback } from "react";

import { useObserver } from "mobx-react";
import { useTranslation } from "react-i18next";
import { SetOptional } from "type-fest";

import { PresettableEntity, NamedEntity } from "../../data/AppModels";
import { useUserName } from "../../store/userStoreHooks";
import { useApplicationStore } from "../../store/useStores";

export interface EntityNameDefinition {
  title: string;
  subtitle?: string;
  tooltip: string;
  fragment: JSX.Element;
  myPreset: boolean;
  presetElementTooltip?: string;
}

export type EntityNameDefinitionGetter = (
  entity: _Entity
) => EntityNameDefinition;

type _Entity = Partial<PresettableEntity> & SetOptional<NamedEntity, "id">;

export default function usePresentationEntityName(): EntityNameDefinitionGetter {
  const applicationStore = useApplicationStore();
  const { myUserId } = useObserver(() => ({
    myUserId: applicationStore?.currentUser?.id,
  }));
  const { t } = useTranslation();
  const getUserName = useUserName();
  return useCallback(
    (entity: _Entity): EntityNameDefinition => {
      const preSetUsersId = entity.preSet === true ? entity.usersId : undefined;
      const myPreset = !!preSetUsersId && preSetUsersId === myUserId;
      const defaultForSomeoneElse =
        !!preSetUsersId && preSetUsersId !== myUserId;
      const title = entity.name;
      const subtitle =
        preSetUsersId && defaultForSomeoneElse
          ? getUserName(preSetUsersId)
          : undefined;
      const presetElementTooltip = t("default");
      const tooltip =
        title +
        (subtitle ? ` · ${subtitle}` : "") +
        (myPreset ? ` · ${presetElementTooltip}` : "");
      const fragment = (
        <>
          {!!title && <span>{title}</span>}
          {!title && <span>&nbsp;</span>}
          {subtitle && <span className="subtitle">{subtitle}</span>}
          {myPreset && (
            <i
              data-tip={presetElementTooltip}
              className="icon-location-pin my-preset"
            />
          )}
        </>
      );
      return {
        title,
        subtitle,
        tooltip,
        fragment,
        myPreset,
        presetElementTooltip: myPreset ? presetElementTooltip : undefined,
      };
    },
    [myUserId, getUserName, t]
  );
}
