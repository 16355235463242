import colorJs from "color";
import i18next from "i18next";
import { find, get } from "lodash";
import moment from "moment";
import { matchPath } from "react-router-dom";
import { Theme } from "react-select";

import DataSetOrigin from "../api/dto/data-set/DataSetOrigin";
import * as Models from "./AppModels";
import {
  DataSetStatus,
  IWidget,
  IWidgetConfig,
  IWidgetVisualisationData,
  WidgetType,
} from "./AppModels";
import { DataSetScriptPurposeTypes } from "./DataSetScriptPurposeTypes";

// const CHART_COLOR_MAP = {
//   '#63c2de':'#33687c', // L1 Light Blue -- indigo - primary color
//   '#4dbd74':'#db455e', // R1 Green  -- pink - DI color
//   '#ffc107':'#5d809a', // L2 Yellow
//   '#f86c6b':'#e7688a', // R2 Red
//   '#20a8d8':'#8599b5', // L3 Blue
//   '#e83e8c':'#ed8ab1', // R3 Pink
//   '#20c997':'#aeb2cf', // L4 Teal
//   '#f8cb00':'#f0abd2', // R4 Orange
//   '#6610f2':'#d5cde7', // L5 Indigo
//   '#17a2b8':'#f4cbec'  // R5 Cyan
// }

// const CHART_SERVER_COLORS = [
//   '#63c2de',
//   '#4dbd74',
//   '#ffc107',
//   '#f86c6b',
//   '#20a8d8',
//   '#e83e8c',
//   '#20c997',
//   '#f8cb00',
//   '#6610f2',
//   '#17a2b8',
// ]

const CHART_UI_COLORS = [
  "#33687C", // indigo
  "#7394c3",
  "#e57fbc",
  "#DB455E", // di
  "#e38a57",
  "#ddc57f",
  "#8eb56c",
  "#0ea177", // green
  "#00c0ae",
  "#26a1b6",
];

const CHART_UI_COLOR_PRIORITY_ORDER = [
  0, 3, 7, 1, 4, 8, 2, 6, 9, 5,
  // 0, 1, 2, 3, 4, 5, 6, 7, 8, 9
];

export enum COLORS {
  PRIMARY = "#33687c",
  PRIMARY_75 = "#688b9b",
  PRIMARY_50 = "#99b1bb",
  PRIMARY_25 = "#cbd7dd",
  DANGER = "#cc6666",
  DANGER_LIGHT = "#ebb2af",
  GRAY_900 = "#131e23",
  GRAY_800 = "#1a3540",
  GRAY_700 = "#354b55",
  GRAY_600 = "#50626b",
  GRAY_500 = "#6b7a81",
  GRAY_400 = "#889398",
  GRAY_300 = "#a5adb0",
  GRAY_200 = "#c3c7c9",
  GRAY_100 = "#ebebeb",
  GRAY_50 = "#f5f5f5",
  GRAY_0 = "#ffffff",
  DI = "rgb(219, 69, 94)",
}

export function getSelectTheme() {
  return (theme: Theme) => ({
    ...theme,
    borderRadius: 8,
    colors: {
      primary: COLORS.PRIMARY,
      primary75: COLORS.PRIMARY_75,
      primary50: COLORS.PRIMARY_50,
      primary25: COLORS.PRIMARY_25,
      danger: COLORS.DANGER,
      dangerLight: COLORS.DANGER_LIGHT,
      neutral0: COLORS.GRAY_0,
      neutral5: COLORS.GRAY_50,
      neutral10: COLORS.GRAY_100,
      neutral20: COLORS.GRAY_200,
      neutral30: COLORS.GRAY_300,
      neutral40: COLORS.GRAY_400,
      neutral50: COLORS.GRAY_500,
      neutral60: COLORS.GRAY_600,
      neutral70: COLORS.GRAY_700,
      neutral80: COLORS.GRAY_800,
      neutral90: COLORS.GRAY_900,
    },
  });
}

export default class AppUtils {
  static ACTIVE_CHART_COLOR = "#f0c674"; // yellow

  static toggleFullscreenCallbacks: any[] = [];

  static toggleFullscreen() {
    AppUtils.toggleFullscreenCallbacks.map((callback) => callback());
  }

  static changeLanguage(lng: Models.Language) {
    // import locale for moment.js
    // when it's done then change 18next language
    // when this is done resolve
    // when anything fails, resolve anyway - TODO - it should revert to original and inform the user
    return new Promise<Models.Language>((resolve) => {
      console.log("going to set language to:", lng);

      const afterMoment = () => {
        moment.locale(lng);
        if (i18next.language !== lng) {
          i18next
            .changeLanguage(lng)
            .then(() => resolve(Models.languageFromValue(i18next.language)))
            .catch((error) => {
              console.warn("could not change 18next language to:", lng, error);
              resolve(Models.languageFromValue(i18next.language));
            });
        } else {
          return resolve(Models.languageFromValue(i18next.language));
        }
      };

      const onMomentImport = () => {
        console.log("moment locale imported successfully for lang:", lng);
        moment.locale(lng);
        afterMoment();
      };

      const onMomentImportError = (error) => {
        console.warn("could not import moment/locale for lang:", lng, error);
        afterMoment();
      };

      const availableMomentLocales = moment.locales();

      switch (lng) {
        case Models.Language.DE:
          if (!availableMomentLocales.includes("de")) {
            // @ts-ignore
            import("moment/locale/de")
              .then(onMomentImport)
              .catch(onMomentImportError);
          } else {
            afterMoment();
          }
          break;
        case Models.Language.SK:
          if (!availableMomentLocales.includes("sk")) {
            // @ts-ignore
            import("moment/locale/sk")
              .then(onMomentImport)
              .catch(onMomentImportError);
          } else {
            afterMoment();
          }
          break;
        default:
          if (!availableMomentLocales.includes("en-gb")) {
            // @ts-ignore
            import("moment/locale/en-gb")
              .then(onMomentImport)
              .catch(onMomentImportError);
          } else {
            afterMoment();
          }
      }
    });
  }

  static addToggleFullscreenCallback(callback) {
    AppUtils.toggleFullscreenCallbacks.push(callback);
  }

  static matchUrl(path: string, pathname: string): boolean {
    const m = matchPath(pathname, {
      path,
      exact: false,
      strict: false,
    });

    return !!m;
  }

  // img: name of img from img file
  static getCatalogueCategoriesData() {
    return [
      {
        key: "L",
        name: i18next.t("catalogueCategory.L.name"),
        description: i18next.t("catalogueCategory.L.description"),
        img: "di-hr.svg",
      },
    ];
  }

  static getWidgetType(
    widget: IWidget | IWidgetConfig,
    widgetVisualisations: IWidgetVisualisationData[]
  ): WidgetType {
    // @ts-ignore
    return get(
      find(widgetVisualisations, { id: widget.widgetVisualisationId }),
      "key"
    );
  }

  static needsUpdateScheduleOnServer(
    origin?: DataSetOrigin,
    scriptPurpose?: DataSetScriptPurposeTypes
  ) {
    return origin
      ? DataSetOrigin.CONNECTOR_QUERY === origin ||
          (origin === DataSetOrigin.SCRIPT && scriptPurpose === "OUTPUT")
      : false;
  }

  static UPDATING_IN_PROGRESS = new Set([
    DataSetStatus.QUEUE,
    DataSetStatus.UPDATING,
  ]);

  static isUpdatingInProgress(
    status: DataSetStatus | null,
    origin: DataSetOrigin | null
  ) {
    return (
      origin &&
      status &&
      this.needsUpdateScheduleOnServer(origin) &&
      this.UPDATING_IN_PROGRESS.has(status)
    );
  }

  static getUpdateData(
    status: DataSetStatus | null | undefined,
    origin: DataSetOrigin | null | undefined,
    scriptPurpose?: DataSetScriptPurposeTypes
  ): {
    title: string;
    customClassName: string;
    disabled: boolean;
    additionalText: string;
    highlight: boolean;
  } {
    let title = "";
    let customClassName = "";
    let disabled = false;
    let additionalText = "";
    let highlight = false;

    if (origin && this.needsUpdateScheduleOnServer(origin, scriptPurpose)) {
      switch (status) {
        case DataSetStatus.QUEUE:
          title = i18next.t("dataset.refreshQueue");
          customClassName = "icon-status-update-queue";
          disabled = true;
          break;
        case DataSetStatus.UPDATING:
          title = i18next.t("dataset.refreshRunning");
          customClassName = "icon-status-update-running";
          disabled = true;
          highlight = true;
          break;
        case DataSetStatus.UPDATE_ERROR:
          title = i18next.t("dataset.refreshError");
          customClassName = "icon-status-update-error";
          additionalText = "!";
          highlight = true;
          break;
        case DataSetStatus.UPDATE_CANCELED:
          title = i18next.t("dataset.refreshCanceled");
          customClassName = "icon-status-update-canceled";
          additionalText = "!";
          break;
        case DataSetStatus.UPDATED:
          title = i18next.t("dataset.refreshUpdated");
          customClassName = "icon-status-update-done";
          break;
        default:
          title = i18next.t("dataset.refresh");
      }
    } else if (status) {
      console.log(
        `origin="${origin}" does not need scheduled server update and status should be null, but status="${status}"`
      );
    }

    if (scriptPurpose === "STATE") {
      disabled = true;
    }

    return {
      title,
      customClassName,
      disabled,
      additionalText,
      highlight,
    };
  }

  static replaceColor(color, uniqueColors: Set<any>) {
    const serverIndex = [...uniqueColors].indexOf(color.toLowerCase()); // CHART_SERVER_COLORS.indexOf(color)
    const usedUiColors = CHART_UI_COLOR_PRIORITY_ORDER.slice(
      0,
      uniqueColors.size
    ).sort();
    // console.log('!!! usedUiColors', usedUiColors, '!!! unique colors', uniqueColors)
    if (serverIndex >= 0) {
      const uiColorIndex = usedUiColors[serverIndex % usedUiColors.length];
      const offset = Math.floor(serverIndex / usedUiColors.length);
      const uiColor = CHART_UI_COLORS[uiColorIndex];
      if (offset > 0) {
        const clr = colorJs(uiColor);
        if (clr.isDark()) {
          // console.log('<><><><> DARK', offset, color, uiColor, uniqueColors)
          return clr
            .rotate(offset * 8)
            .desaturate(offset * 0.2)
            .lighten(offset * 0.2)
            .hex();
        }
        // console.log('<><><><> LIGHT', offset, color, uiColor, uniqueColors)
        return clr
          .rotate(offset * -8)
          .saturate(offset * 0.2)
          .darken(offset * 0.2)
          .hex();
      }
      // console.log('<><><><> REPLACED', color, uiColor, uniqueColors)
      return uiColor;
    }
    // console.log('<><><><> UNTOUCHED', color, uniqueColors)
    return color;
  }

  static hackColors(chartData) {
    // console.log(chartData)
    const colorArrays = [
      "backgroundColor",
      "borderColor",
      "hoverBackgroundColor",
      "hoverBorderColor",
    ];
    if (chartData) {
      colorArrays.forEach((array) => {
        if (Array.isArray(chartData.datasets)) {
          const uniqueColors = new Set();
          chartData.datasets.forEach((dataset) => {
            if (Array.isArray(dataset[array])) {
              dataset[array].forEach((color, i) => {
                if (!color) {
                  color = `undefined-${i}`;
                }
                uniqueColors.add(color.toLowerCase());
              });
            }
          });
          chartData.datasets.forEach((dataset) => {
            if (Array.isArray(dataset[array])) {
              dataset[array] = dataset[array].map((color, i) =>
                this.replaceColor(color || `undefined-${i}`, uniqueColors)
              );
            }
          });
        }
      });
    }
    return chartData;
  }

  static printDiff = (
    obj1: Record<string, any>,
    obj2: Record<string, any>,
    prefix: string
  ) => {
    const allKeys = Object.keys(obj1).concat(Object.keys(obj2));
    allKeys.forEach((key) => {
      if (obj1[key] !== obj2[key]) {
        console.log(
          `change detected in ${prefix}: ${key}`,
          obj1[key],
          "->",
          obj2[key]
        );
      }
    });
  };
}
