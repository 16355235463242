import React, { Component } from "react";

import i18next from "i18next";
import { isEqual, isNil } from "lodash";
import { ActionMeta, ValueType, OptionTypeBase } from "react-select";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import { Button, Modal, ModalHeader } from "reactstrap";
import Input from "reactstrap/lib/Input";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalFooter from "reactstrap/lib/ModalFooter";

import * as Models from "../../data/AppModels";
import { getSelectTheme } from "../../data/AppUtils";
import RestApi from "../../services/RestApi";

export interface IProps {
  isOpen: boolean;
  showInternal: boolean;
  showExternal: boolean;
  showMessage: boolean;
  share: (data: IDShareData) => void;
  cancel: () => void;
  generateShareLink?: () => Promise<string | void>;
  loadDefaultUsers?: boolean;
}

export interface IState {
  selectedIds: string[];
  sharedLink: string | null;
  sendTo: string | null;
  customText: string | null;
  defaultUsers: { value: string; label: string }[];
}

export interface IDShareData {
  selectedUserIds: string[];
  customText: string | null;
  sendTo: string | null;
}

interface UserOption extends OptionTypeBase {
  value: string;
  label: string;
}

export default class DShareComponent extends Component<IProps, IState> {
  state: IState;

  textarea: any;

  constructor(properties) {
    super(properties);

    this.state = {
      selectedIds: [],
      sharedLink: null,
      sendTo: null,
      customText: null,
      defaultUsers: properties.defaultUsers || [],
    };

    this.handleChange = this.handleChange.bind(this);
    this.createLink = this.createLink.bind(this);
    this.doShare = this.doShare.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    return {
      defaultUsers: props.defaultUsers || state.defaultUsers,
    };
  }

  shouldComponentUpdate(nextProperties, nextState: IState): boolean {
    const { state } = this;
    const { props } = this;

    return (
      !isEqual(state.selectedIds, nextState.selectedIds) ||
      state.sharedLink !== nextState.sharedLink ||
      state.sendTo !== nextState.sendTo ||
      state.defaultUsers !== nextState.defaultUsers ||
      state.customText !== nextState.customText ||
      props.isOpen !== nextProperties.isOpen ||
      props.showExternal !== nextProperties.showExternal
    );
  }

  componentDidMount(): void {
    if (this.props.loadDefaultUsers) {
      DShareComponent.loadUsers("")
        .then((users) => this.setState({ defaultUsers: users }))
        .catch((error) => console.log("could not load users", error));
    }
  }

  clearComponent() {
    this.setState({
      selectedIds: [],
      sharedLink: null,
      sendTo: null,
      customText: null,
    });
  }

  handleChange(value: ValueType<UserOption>, action: ActionMeta<UserOption>) {
    // console.info("value: " + JSON.stringify(value) + "action: " + JSON.stringify(action));
    let selectedIds: string[] = [];

    if (!value) {
      selectedIds = [];
    } else {
      value.forEach((a: any) => selectedIds.push(a.value));
    }

    this.setState({
      selectedIds,
    });
  }

  static async loadUsers(input: string): Promise<UserOption[]> {
    const users = await RestApi.searchUsersV1(input);
    if (users && users.content) {
      const data: { value: string; label: string }[] = users.content.map(
        (value: Models.IUsersData) => ({
          value: value.id,
          label: value.name,
        })
      );
      return data;
    }
    return [];
  }

  async createLink() {
    if (this.props.showExternal && this.props.generateShareLink) {
      const link = await this.props.generateShareLink();
      this.setState(
        {
          sharedLink: link || null,
        },
        () => {
          if (link) {
            const { textarea } = this;
            textarea.value = link;
            textarea.select();
            document.execCommand("copy");
            toast.success(i18next.t("dsShareLinkCopiedToClipboard"));
          }
        }
      );
    }
  }

  static isEmailValid(value: string | null): boolean {
    if (isNil(value)) {
      return false;
    }

    return value.match(/^([\w%+.-]+)@([\w-]+\.)+(\w{2,})$/i) !== null;
  }

  doShare() {
    this.props.share({
      customText: this.state.customText,
      selectedUserIds: this.state.selectedIds,
      sendTo: this.state.sendTo,
    });
    this.clearComponent();
  }

  render() {
    const internalEmpty = this.state.selectedIds.length === 0;
    const internalValid = this.state.selectedIds.length > 0;
    const externalEmpty =
      this.state.sendTo === null || this.state.sendTo.length === 0;
    const externalValid = DShareComponent.isEmailValid(this.state.sendTo);
    let shareValid = false;
    if (this.props.showInternal && !this.props.showExternal) {
      // only internal active -> internal needs to be valid
      shareValid = internalValid;
    }
    if (!this.props.showInternal && this.props.showExternal) {
      // only external active -> external needs to be valid
      shareValid = externalValid;
    }
    if (this.props.showInternal && this.props.showExternal) {
      // both internal and external are active
      if (internalEmpty) {
        // internal value is empty -> external needs to be valid
        shareValid = externalValid;
      } else if (externalEmpty) {
        // external value is empty -> internal needs to be valid
        shareValid = internalValid;
      } else {
        // both internal and external are non-emptu -> both need to be valid
        shareValid = internalValid && externalValid;
      }
    }
    return (
      <Modal
        isOpen={this.props.isOpen}
        className="modal-dialog-centered modal-dialog-upload-data modal-lg"
      >
        <ModalHeader>{i18next.t("dsShareShare")}</ModalHeader>
        <ModalBody>
          {this.props.showInternal && (
            <AsyncSelect
              isMulti
              valueKey="id"
              labelKey="name"
              theme={getSelectTheme()}
              loadOptions={DShareComponent.loadUsers}
              onChange={this.handleChange}
              defaultOptions={this.state.defaultUsers}
              placeholder={i18next.t("dsShareSelectYourColleague")}
            />
          )}
          {this.props.showExternal && (
            <Input
              className="mt-2"
              type="email"
              placeholder={i18next.t("dsShareTypeEmail")}
              value={this.state.sendTo || ""}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                this.setState({
                  sendTo: event.target.value,
                });
              }}
            />
          )}
          {this.props.showMessage && (
            <Input
              className="mt-2"
              type="textarea"
              placeholder={i18next.t("dsShareMessageToYourColleague")}
              value={this.state.customText || ""}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                this.setState({
                  customText: event.target.value,
                });
              }}
            />
          )}
          {this.props.showExternal && (
            <textarea
              ref={(textarea) => {
                this.textarea = textarea;
              }}
              style={{ position: "absolute", top: "-1000px" }}
            />
          )}
          {this.state.sharedLink && (
            <Input className="mt-2" value={this.state.sharedLink} readOnly />
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => this.doShare()}
            disabled={!shareValid}
          >
            {i18next.t("dsShareShare")}
          </Button>
          {this.props.showExternal && (
            <Button color="secondary" onClick={() => this.createLink()}>
              {i18next.t("btnCreateLink")}
            </Button>
          )}
          <Button
            color="dark"
            outline
            onClick={() => {
              this.clearComponent();
              this.props.cancel();
            }}
          >
            {i18next.t("btnCancel")}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
