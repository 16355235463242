import { setInterval } from "timers";

import i18next from "i18next";
import * as _ from "lodash";
import { action, computed, configure, observable, runInAction } from "mobx";

import AppConstants from "../data/AppConstants";
import {
  Language,
  languageFromValue,
  UserProperty,
  ICurrentUser,
} from "../data/AppModels";
import AppUtils from "../data/AppUtils";
import Operations from "../data/Operations";
import RestApi from "../services/RestApi";
import AllStores from "./AllStores";
import {
  validateTokenInit,
  AccessTokenValueReason,
  AuthenticationState,
  AccessTokenInitOptions,
} from "./authentication";
import {
  EntityRelationType,
  IWorkspaceMemberEntityRelationStore,
} from "./EntityRelationStores";

// strict mode
configure({ enforceActions: "observed" });

export type InitOptions = {
  accessToken?: AccessTokenInitOptions;
  language?: string;
};

export enum SidebarView {
  open = "open",
  close = "close",
}

export interface IApplicationStore {
  language: Language | undefined;
  accessToken: string | null;
  authenticationState: AuthenticationState;
  isExternalMode: boolean;
  isAuthenticated: boolean;
  getSidebarView: SidebarView;
  currentUser?: ICurrentUser;
  selectedInstallation?: string;

  initialize(options: InitOptions): Promise<void>;

  doSignOut(): void;

  changeLanguage(lng: Language): Promise<void>;

  toggleSidebar(): void;

  isAllowed(operation: Operations): boolean;

  setInstallationId(instId: string): void;
}

const languageInStorage = () =>
  languageFromValue(localStorage.getItem(AppConstants.LS_USER_LANGUAGE) || "");

const detectLanguage = () =>
  i18next.isInitialized
    ? languageFromValue(i18next.language)
    : languageInStorage();

const accessTokenInStorage = () =>
  localStorage.getItem(AppConstants.LS_ACCESS_TOKEN);

const detectAccessToken = () => {
  const token = accessTokenInStorage();
  return validateTokenInit({
    value: token,
    reason: token
      ? AccessTokenValueReason.LOGIN_USER
      : AccessTokenValueReason.INIT,
  });
};

const {
  token: initialDetectionToken,
  tokenReason: initialDetectionTokenReason,
} = detectAccessToken();

export default class ApplicationStore implements IApplicationStore {
  @observable jwtAccessToken: string | null = initialDetectionToken;

  @observable
  jwtAccessTokenReason: AccessTokenValueReason = initialDetectionTokenReason;

  @observable userLanguage: Language | undefined = detectLanguage();

  @observable sidebarView: SidebarView = SidebarView.open;

  @observable currentUser?: ICurrentUser = undefined;

  @observable selectedInstallation?: string = undefined;

  stores: AllStores | undefined = undefined;

  sniffer?: NodeJS.Timeout = undefined;

  constructor(stores: AllStores) {
    this.stores = stores;
  }

  getLocalStorageSniffer = () =>
    setInterval(() => {
      // sniff local storage for changes - these can occur when working with multiple tabs
      const token = accessTokenInStorage();
      // detect token changes only in regular mode
      const tokenChanged =
        !this.isExternalMode && token !== this.jwtAccessToken;
      if (tokenChanged) {
        console.warn(
          "ApplicationStore :: token was changed in local storage -> reinitialize"
        );
      }

      // detect language changes only in external mode
      const language = languageInStorage();
      const languageChanged =
        !this.isExternalMode && language !== this.userLanguage;
      if (languageChanged) {
        console.warn(
          `ApplicationStore :: language was changed in local storage -> ${
            tokenChanged ? "reinitialize" : "changeLanguage"
          }`
        );
      }
      if (tokenChanged) {
        this.initialize({
          accessToken: {
            value: token,
            reason: token
              ? AccessTokenValueReason.LOGIN_USER
              : AccessTokenValueReason.LOGOUT,
          },
          language: languageChanged ? language : undefined,
        });
      } else if (languageChanged) {
        this.changeLanguage(language);
      }
    }, 1000);

  @action
  async initialize({ accessToken, language }: InitOptions) {
    console.log(
      `ApplicationStore :: initialize :: language: ${language} :: accessToken: ${
        accessToken ? !!accessToken?.value : undefined
      } :: reason: ${accessToken?.reason}`
    );
    this.clearAll();
    this.initAccessToken(accessToken);
    if (language !== undefined) {
      // if the language was provided then use it
      await this.changeLanguage(languageFromValue(language));
    }
    if (this.isAuthenticated && !this.isExternalMode) {
      // if authenticated, then load the meta-data of the current user and his/her preferred language (if it is not overriden init options)
      if (language) {
        await this.initUser();
      } else {
        await Promise.all([this.initUser(), this.initUsersLanguage()]);
      }
    }
    this.sniffer = this.getLocalStorageSniffer();
  }

  @action
  initAccessToken(accessToken?: AccessTokenInitOptions) {
    if (accessToken !== undefined) {
      const { token, tokenReason } = validateTokenInit(accessToken);
      this.jwtAccessToken = token;
      this.jwtAccessTokenReason = tokenReason;
      RestApi._setAccessToken(token);
      if (!this.isExternalMode) {
        // touch localStore only for regular mode
        if (this.jwtAccessToken !== null && this.jwtAccessToken.length > 0) {
          localStorage.setItem(
            AppConstants.LS_ACCESS_TOKEN,
            this.jwtAccessToken
          );
        } else {
          localStorage.removeItem(AppConstants.LS_ACCESS_TOKEN);
        }
      }
    } else {
      // keep the current token, because it was not explicitely defined
    }
  }

  // init language after login, get language for logged user
  @action
  async initUsersLanguage() {
    // load language from user properties
    try {
      const lngProperty = await RestApi.loadUserPropertyV1(
        AppConstants.LS_USER_LANGUAGE
      );
      const language = languageFromValue(lngProperty?.value || "");
      if (language) {
        console.log(
          "ApplicationStore :: initializeUsersLanguage :: loaded lang=",
          language
        );
        await this.changeLanguage(language); // update store
      } else {
        console.log(
          `ApplicationStore :: initializeUsersLanguage :: no language recognized, falling back to local language :: in response: ${lngProperty?.value}`
        );
      }
    } catch (error) {
      console.error(
        "ApplicationStore :: initializeUsersLanguage ::could not load user language",
        error
      );
      // TODO notify user
    }
  }

  @action
  async changeLanguage(language: Language) {
    console.debug(
      `ApplicationStore :: changeLanguage :: changing to: ${language}`
    );
    // change the language or the libs (i18next, moment and others)
    const finalLanguage = await AppUtils.changeLanguage(language);
    if (finalLanguage !== language) {
      console.warn(
        `ApplicationStore :: changeLanguage :: language was not set to ${language}, but to ${finalLanguage}`
      );
    }
    // update the store wth the value from libs
    if (finalLanguage !== this.userLanguage) {
      runInAction(() => {
        this.userLanguage = finalLanguage;
        RestApi._setLanguage(finalLanguage);
      });
    }
    const saveForUser =
      this.authenticationState === AuthenticationState.AUTHENTICATED_USER;
    if (saveForUser && finalLanguage === language) {
      try {
        // update the user property on the server
        await RestApi.saveUserPropertyV1({
          key: AppConstants.LS_USER_LANGUAGE,
          value: finalLanguage,
        });
      } catch (error) {
        console.error(
          "ApplicationStore :: changeLanguage :: could not save the language of the current user",
          error
        );
        // TODO notify user
      }
    }
  }

  @action
  clearAll() {
    console.log("ApplicationStore :: cleaning all data, stores and timeouts");
    if (this.sniffer) {
      clearInterval(this.sniffer);
    }
    const { token, tokenReason } = detectAccessToken();
    const language = detectLanguage();
    this.jwtAccessToken = token;
    this.jwtAccessTokenReason = tokenReason;
    this.userLanguage = language;
    this.sidebarView = SidebarView.open;
    this.currentUser = undefined;
    this.selectedInstallation = undefined;
    RestApi._setAccessToken(token);
    RestApi._setLanguage(language);
    RestApi._setInstallationId(undefined);
    if (this.stores) {
      this.stores.clearAll();
    }
  }

  @action
  async initUser() {
    try {
      await Promise.all([
        this.initUserMetaData(),
        this.initUsersInstallations(),
      ]);
      // wait for previous call because we need selected installation
      this.initOtherStores();
    } catch (error) {
      console.log("could not load current user -> signout", error);
      this.initialize({
        accessToken: {
          value: null,
          reason: AccessTokenValueReason.INIT_FAILED,
        },
      });
    }
  }

  @action
  async initOtherStores() {
    const promises: Promise<any>[] = [];
    if (this.stores) {
      if (this.stores.basicStores.dashboardStore) {
        this.stores.basicStores.dashboardStore.setMyUser(
          this.currentUser?.id,
          this.currentUser?.userPermissions?.superUser || false
        );
        promises.push(this.stores.basicStores.dashboardStore.fetchDashboards());
      }
      if (this.stores.basicStores.userStore) {
        promises.push(this.stores.basicStores.userStore.fetchAll());
      }
      if (this.stores.basicStores.workspaceStore) {
        promises.push(this.stores.basicStores.workspaceStore.init());
      }
      const workspaceMemberConnectionsStore:
        | IWorkspaceMemberEntityRelationStore
        | undefined = this.stores.getEntityRelationStore(
        EntityRelationType.WORKSPACE_MEMBER
      );
      if (workspaceMemberConnectionsStore) {
        promises.push(workspaceMemberConnectionsStore.fetchAll());
      }
    }
    await Promise.all(promises);
  }

  @action
  async initUsersInstallations() {
    if (this.stores && this.stores.basicStores.installationStore) {
      let installations =
        await this.stores.basicStores.installationStore.fetchInstallations();
      console.log("installations of the user", installations);
      if (installations === undefined) {
        throw new Error(
          "could not load installations of current user (no data in response)"
        );
      }
      installations = installations.filter((inst) => !!inst.id);
      if (installations.length === 0) {
        throw new Error(
          "installations of current user were loaded successfully, but user belongs to none with a proper ID"
        );
      }
      const lastSelected = await RestApi.loadUserPropertyV1(
        AppConstants.SELECTED_INSTALLATION
      );
      if (installations) {
        let selected = installations[0].id;
        if (
          lastSelected &&
          installations.some((inst) => inst.id === lastSelected.value)
        ) {
          console.log("reusing last selected installation");
          selected = lastSelected.value;
        }
        runInAction(() => {
          this.selectedInstallation = selected;
          RestApi._setInstallationId(selected);
        });
      }
    }
  }

  @action
  async initUserMetaData() {
    const data = await RestApi.currentUser();
    console.log("current user data", data);
    if (data === undefined) {
      throw new Error(
        "could not load data of current user (no data in response)"
      );
    }
    if (!data.id === undefined) {
      throw new Error(
        "current user data was loaded, but the user id is undefined"
      );
    }
    runInAction(() => {
      this.currentUser = data;
    });
    await this.loadCurrentUserPhoto(data.id);
    return data;
  }

  @action
  async loadCurrentUserPhoto(userId: string) {
    try {
      if (this.stores?.basicStores.userStore) {
        await this.stores.basicStores.userStore.fetchPhoto(userId);
      }
    } catch (error) {
      console.error("Could not load user photo:", error);
    }
  }

  @action
  doSignOut(): void {
    console.debug("ApplicationStore :: doSignOut");
    this.initialize({
      accessToken: {
        value: null,
        reason: AccessTokenValueReason.LOGOUT,
      },
    });
  }

  @action
  toggleSidebar() {
    if (this.sidebarView === SidebarView.open) {
      this.sidebarView = SidebarView.close;
    } else {
      this.sidebarView = SidebarView.open;
    }
  }

  @action
  setInstallationId(instId: string) {
    if (this.stores) this.stores.onInstallationChange();
    this.selectedInstallation = instId;
    RestApi._setInstallationId(instId);
    const up: UserProperty = {
      key: AppConstants.SELECTED_INSTALLATION,
      value: instId,
    };
    RestApi.saveUserPropertyV1(up); // update user property
    this.initOtherStores();
  }

  @computed
  get isAllowed(): (operation: Operations) => boolean {
    const user = this.currentUser;
    return (operation) => {
      if (user) {
        if (user.userPermissions?.superUser === true) {
          return true;
        }
        if (user.userPermissions?.operations) {
          return user.userPermissions.operations.includes(operation.valueOf());
        }
      }
      return false;
    };
  }

  @computed
  get getSidebarView(): SidebarView {
    return this.sidebarView;
  }

  @computed
  get isAuthenticated() {
    return [
      AuthenticationState.AUTHENTICATED_USER,
      AuthenticationState.AUTHENTICATED_EXTERNAL,
    ].includes(this.authenticationState);
  }

  @computed
  get isExternalMode() {
    return (
      this.jwtAccessToken !== null &&
      this.jwtAccessTokenReason === AccessTokenValueReason.LOGIN_EXTERNAL
    );
  }

  @computed
  get authenticationState(): AuthenticationState {
    if (!_.isNil(this.jwtAccessToken)) {
      if (this.jwtAccessTokenReason === AccessTokenValueReason.LOGIN_EXTERNAL) {
        return AuthenticationState.AUTHENTICATED_EXTERNAL;
      }
      return AuthenticationState.AUTHENTICATED_USER;
    }
    if (this.jwtAccessTokenReason === AccessTokenValueReason.INIT_FAILED) {
      return AuthenticationState.NOT_AUTHENTICATED_INIT_FAILED;
    }
    if (this.jwtAccessTokenReason === AccessTokenValueReason.LOGOUT) {
      return AuthenticationState.NOT_AUTHENTICATED_LOGOUT;
    }
    if (this.jwtAccessTokenReason === AccessTokenValueReason.TOKEN_EXPIRED) {
      return AuthenticationState.NOT_AUTHENTICATED_TOKEN_EXPIRED;
    }
    if (this.jwtAccessTokenReason === AccessTokenValueReason.TOKEN_INVALID) {
      return AuthenticationState.NOT_AUTHENTICATED_TOKEN_INVALID;
    }
    return AuthenticationState.NOT_AUTHENTICATED;
  }

  @computed
  get language(): Language | undefined {
    return this.userLanguage;
  }

  @computed
  get accessToken(): string | null {
    return this.jwtAccessToken;
  }
}
