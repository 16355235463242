import { ConnectorTypeQueryAttributeDto } from "../../data/dto/type/types";
import { AbstractStore, EntityFilter, IAbstractStore } from "../AbstractStore";
import StoreNames from "../storeNames";
import { ConnectorTypeQueryAttributesTransport } from "./connectorTypeQueryAttributesTransport";

export type IConnectorTypeQueryAttributesStore = IAbstractStore<
  ConnectorTypeQueryAttributeDto,
  EntityFilter
>;

export default class ConnectorTypeQueryAttributesStore
  extends AbstractStore<ConnectorTypeQueryAttributeDto, EntityFilter>
  implements IConnectorTypeQueryAttributesStore
{
  transport: ConnectorTypeQueryAttributesTransport;

  constructor(transport: ConnectorTypeQueryAttributesTransport) {
    super(StoreNames.CONNECTOR_TYPE_QUERY_ATTRIBUTES);

    this.transport = transport;
  }

  apiFetchAll = async () => this.transport.getConnectorTypeQueryAttributes();

  apiFetchOne = async (id: string) =>
    this.transport.getConnectorTypeQueryAttribute(id);
}
