import React, { useEffect, useState, Suspense } from "react";

import ApplicationRouter from "./components/application-router/ApplicationRouter";

import "./App.scss";
import "react-toastify/dist/ReactToastify.min.css";

import { useObserver, Provider } from "mobx-react";

import "mobx-react/batchingForReactDom";

// unregister global plugin
import Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import i18next from "i18next";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";

import PageLoader from "./components/loader/PageLoader";
import setupI18n from "./i18n";
import { AuthenticationState } from "./store/authentication";
import { stores } from "./store/stores";
import { useApplicationStore } from "./store/useStores";

Chart.plugins.unregister(ChartDataLabels);

toast.configure({
  hideProgressBar: true,
  position: "top-center",
  draggable: false,
  className: "custom-toast-container",
  autoClose: 5000,
  closeButton: (
    <>
      <i className="icon-close" />
    </>
  ),
});

function AppContent() {
  const applicationStore = useApplicationStore();
  const { authenticationState } = useObserver(() => ({
    authenticationState: applicationStore?.authenticationState,
  }));
  const [initialized, setInitialized] = useState<boolean>(false);

  useEffect(() => {
    if (
      authenticationState === AuthenticationState.NOT_AUTHENTICATED_INIT_FAILED
    ) {
      console.error("Application could not be properly initialized");
      toast.error(i18next.t("app.initialization.error"));
    }
  }, [authenticationState]);

  useEffect(() => {
    console.log("Application initialization started");
    setupI18n((language) => {
      applicationStore?.initialize({ language }).then(() => {
        console.log("Application initialization finished");
        setInitialized(true);
      });
    });
  }, [applicationStore]);
  return (
    <>
      {initialized && <ApplicationRouter />}
      <ReactTooltip place="top" type="dark" effect="solid" bodyMode isCapture />
    </>
  );
}

export default function App() {
  return (
    <Provider {...stores}>
      <Suspense fallback={<PageLoader />}>
        <AppContent />
      </Suspense>
    </Provider>
  );
}
