import RestApi from "../services/RestApi";
import { createCRUDApi, CRUDApi } from "./apiCalls";
import DashboardDto from "./dto/dashboard/DashboardDto";
import DashboardDtoUpdate from "./dto/dashboard/DashboardDtoUpdate";

export interface DashboardApiFilter {
  installations?: string | string[];
}

export interface DashboardApi
  extends CRUDApi<DashboardDto, DashboardDtoUpdate, DashboardApiFilter> {
  removeWidgetFromDashboard: (
    dashboardId: string,
    widgetId: string
  ) => Promise<void>;
  addWidgetToDashboard: (
    dashboardId: string,
    widgetId: string
  ) => Promise<void>;
}

export default function createDashboardApi(): DashboardApi {
  return {
    ...createCRUDApi<DashboardDto, DashboardDtoUpdate, DashboardApiFilter>(
      "v1/dashboards"
    ),
    removeWidgetFromDashboard: (dashboardId, widgetId) =>
      RestApi.doDelete(`v1/dashboards/${dashboardId}/widgets/${widgetId}`),
    addWidgetToDashboard: (dashboardId, widgetId) =>
      RestApi.doPut<void>(
        `/v1/dashboards/${dashboardId}/widgets/${widgetId}`,
        {}
      ),
  };
}
