import { useEffect } from "react";

export default function usePageHeaderPadding() {
  useEffect(() => {
    const userHudWidth = document.querySelectorAll(".user-hud")[0].clientWidth;
    const pageHeaderElement = document.querySelectorAll(
      ".page-header"
    )[0] as HTMLDivElement;
    if (pageHeaderElement) {
      pageHeaderElement.style.paddingRight = `${userHudWidth}px`;
    }
  });
}
