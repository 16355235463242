import React from "react";

import {
  Card,
  CardBody,
  CardDeck,
  CardText,
  CardTitle,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import "./createWidgetsModal.scss";

import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import AppConstants from "../../../data/AppConstants";
import { WidgetTypeIcon, WidgetType } from "../../widgets/dataSet/TypeIcon";
import GoogleDriveCard from "./cards/GoogleDriveCard";

export interface Props {
  isOpen: boolean;
  toggle: () => void;
}

export default function (props: Props) {
  const history = useHistory();
  const { t } = useTranslation();

  const filePreview = (fileId: string) => {
    history.push(
      AppConstants.joinUrl(AppConstants.RT_L_DS_ADD_UPLOAD_FILE, { id: fileId })
    );
  };

  return (
    <Modal
      isOpen={props.isOpen}
      toggle={() => props.toggle()}
      className="modal-dialog-centered modal-lg"
    >
      <ModalHeader toggle={() => props.toggle()}>
        {t("chooseNewWidgetSource")}
      </ModalHeader>
      <ModalBody>
        <CardDeck className="m-columns">
          <Card className="m-card" color="light">
            <Link
              to={AppConstants.RT_L_DASHBOARD_CATALOGUES}
              onClick={() => props.toggle()}
              className="m-card-link"
            >
              <CardBody className="m-card-body">
                <WidgetTypeIcon widgetType={WidgetType.CATALOGUE} />
                <CardTitle className="font-weight-bold">
                  {t("catalogue")}
                </CardTitle>
                <CardText className="text-center m-card-text">
                  {t("selectFromListOfPredefined")}
                </CardText>
              </CardBody>
            </Link>
          </Card>
          <Card className="m-card" color="light">
            <Link
              to={AppConstants.RT_L_DS_ADD_UPLOAD}
              onClick={() => props.toggle()}
              className="m-card-link"
            >
              <CardBody className="m-card-body">
                <WidgetTypeIcon widgetType={WidgetType.FILE_UPLOAD} />
                <CardTitle className="font-weight-bold">
                  {t("fileUpload")}
                </CardTitle>
                <CardText className="text-center m-card-text">
                  {t("uploadFromFiles")}
                </CardText>
              </CardBody>
            </Link>
          </Card>
          <GoogleDriveCard
            onClick={() => props.toggle()}
            filePreview={(fileId) => filePreview(fileId)}
          />
        </CardDeck>
      </ModalBody>
    </Modal>
  );
}
