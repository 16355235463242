import React from "react";

import * as Models from "../../data/AppModels";
import CommonWidget from "./CommonWidget";

import "./InfoWidget.scss";

export default class InfoWidget extends CommonWidget {
  createCardBody = () => this.createInfoWidget(this.state.infoData);

  customCardBodyClass = () => "info-widget-card-body-content";

  createInfoWidget = (infoData: Models.IInfoData | null) => {
    let content = <></>;

    if (infoData) {
      content = (
        <div className="info-widget-body">
          <div className="value">
            <p>{infoData.value}</p>
          </div>
          <div>{infoData.text}</div>
        </div>
      );
    }

    return content;
  };
}
