import React from "react";

import { useObserver } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Redirect, Route } from "react-router-dom";
import { toast } from "react-toastify";

import AppConstants from "../../data/AppConstants";
import { AuthenticationState } from "../../store/authentication";
import { useApplicationStore } from "../../store/useStores";

export default function PrivateRoute({ ...routeProps }) {
  const applicationStore = useApplicationStore();
  const { authenticated, authenticationState } = useObserver(() => ({
    authenticated: applicationStore?.isAuthenticated,
    authenticationState: applicationStore?.authenticationState,
  }));
  const { t } = useTranslation();

  if (!authenticated) {
    if (authenticationState === AuthenticationState.NOT_AUTHENTICATED) {
      toast.error(t("app.notAuthenticated.generic"));
    }
    if (
      authenticationState ===
      AuthenticationState.NOT_AUTHENTICATED_TOKEN_EXPIRED
    ) {
      toast.error(t("app.notAuthenticated.expiredToken"));
    }
    if (
      authenticationState ===
      AuthenticationState.NOT_AUTHENTICATED_TOKEN_INVALID
    ) {
      toast.error(t("app.notAuthenticated.invalidToken"));
    }
    if (authenticationState === AuthenticationState.NOT_AUTHENTICATED_LOGOUT) {
      toast.info(t("app.notAuthenticated.logout"));
    }
    return <Redirect to={AppConstants.RT_ROOT} />;
  }

  return <Route {...routeProps} />;
}
