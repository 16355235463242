import { action } from "mobx";

import {
  Entity,
  WorkspaceConnectorCreateRelation,
  WorkspaceConnectorRelation,
  WorkspaceDashboardCreateRelation,
  WorkspaceDashboardRelation,
  WorkspaceDataSetCreateRelation,
  WorkspaceDataSetRelation,
  WorkspaceDataSourceCreateRelation,
  WorkspaceDataSourceRelation,
  WorkspaceMemberCreateRelation,
  WorkspaceMemberRelation,
} from "../data/AppModels";
import RestApi from "../services/RestApi";
import AbstractEntityStore, {
  IAbstractEntityStore,
} from "./AbstractEntityStore";
import { EntityFilter } from "./AbstractStore";
import StoreNames from "./storeNames";
import { getConnectionEntityKey } from "./workspaceStoreHooks";

export enum EntityRelationType {
  WORKSPACE_MEMBER = "WORKSPACE_MEMBER",
  WORKSPACE_DASHBOARD = "WORKSPACE_DASHBOARD",
  WORKSPACE_DATASET = "WORKSPACE_DATASET",
  WORKSPACE_DATASOURCE = "WORKSPACE_DATASOURCE",
  WORKSPACE_CONNECTOR = "WORKSPACE_CONNECTOR",
}

export function getEntityRelationStoreName(type: EntityRelationType) {
  return `${StoreNames.ENTITY_RELATION}/${type}`;
}

const createFilter = (type: EntityRelationType, entityId: string) => {
  if (type) {
    switch (type) {
      case EntityRelationType.WORKSPACE_CONNECTOR:
        return { connectorIds: entityId };
      case EntityRelationType.WORKSPACE_DASHBOARD:
        return { dashboardIds: entityId };
      case EntityRelationType.WORKSPACE_DATASET:
        return { dataSetsIds: entityId };
      case EntityRelationType.WORKSPACE_DATASOURCE:
        return { dataSourcesIds: entityId };
      case EntityRelationType.WORKSPACE_MEMBER:
        return { workspaceMemberTypeIds: entityId };
      default:
        break;
    }
  }
  return {};
};

export interface IAbstractEntityRelationStore<
  T extends Entity = Entity,
  C = any,
  F extends EntityFilter = EntityFilter
> extends IAbstractEntityStore<T, C, F> {
  entityRelationType: EntityRelationType;

  findRelationByEntityId(entityId: string): void;
}

abstract class AbstractEntityRelationStore<
    T extends Entity,
    C,
    F extends EntityFilter
  >
  extends AbstractEntityStore<T, C, F>
  implements IAbstractEntityRelationStore<T, C>
{
  entityRelationType: EntityRelationType;

  constructor(type: EntityRelationType) {
    super(getEntityRelationStoreName(type));
    this.entityRelationType = type;
  }

  @action
  findRelationByEntityId = async (entityId) => {
    // key by type
    const connectionEntityKey = getConnectionEntityKey(this.entityRelationType);
    if (connectionEntityKey && entityId.length > 0) {
      // find relation in loaded entities
      const entities: T[] = await this.entities;
      const filtered = entities.filter(
        (connection) => connection[connectionEntityKey] === entityId
      );
      if (filtered.length === 0) {
        const filter = createFilter(this.entityRelationType, entityId);
        // load from server
        if (filter && this.allFetched && !this.fetchingAll) {
          try {
            // @ts-ignore
            this.fetchingAll = this._apiFetchAll(filter);
            const fetched: T[] = await this.fetchingAll;
            const _entities: T[] = [...entities];
            const new_et = fetched.filter(
              (f) => !_entities.find((e) => e.id === f.id)
            );
            _entities.push(...new_et);
            this.setEntities(_entities);
            this.fetchingAll = undefined;
          } catch (error) {
            this.fetchingAll = undefined;
            console.error(`error occured${error}`);
            throw error;
          }
        }
      }
    }
  };
}

// WORKSPACE - USER (MEMBER)

export type IWorkspaceMemberEntityRelationStore = IAbstractEntityRelationStore<
  WorkspaceMemberRelation,
  WorkspaceMemberCreateRelation
>;

export interface WMERSFilter extends EntityFilter {
  workspaceIds?: string | string[];
  workspaceMemberTypeIds?: string | string[];
}

class WorkspaceMemberEntityRelationStore
  extends AbstractEntityRelationStore<
    WorkspaceMemberRelation,
    WorkspaceMemberCreateRelation,
    WMERSFilter
  >
  implements IWorkspaceMemberEntityRelationStore
{
  installationSensitive = true;

  constructor() {
    super(EntityRelationType.WORKSPACE_MEMBER);
  }

  apiFetchAll = (filter) =>
    RestApi.workspacesGetAllConnectionsToMembers(
      filter?.workspaceIds,
      filter?.workspaceMemberTypeIds
    );

  apiFetchOne = (id: string) => {
    throw new Error("unsupported operation");
  };

  apiCreate = (createData: WorkspaceMemberCreateRelation) =>
    RestApi.workspacesPostConnectionToMembers(createData);

  newEntity = (id: string, createData: WorkspaceMemberCreateRelation) => ({
    ...createData,
    id,
  });

  apiUpdate = (id: string, updateData: WorkspaceMemberCreateRelation) =>
    RestApi.workspacesPut1ConnectionToMember(id, updateData);

  updateEntity = (
    entity: WorkspaceMemberRelation | undefined,
    id: string,
    updateData: WorkspaceMemberCreateRelation
  ) => ({
    ...(entity || {}),
    id,
    ...updateData,
  });

  apiDelete = (id: string) => RestApi.workspacesDelete1ConnectionToMember(id);
}

// WORKSPACE - DASHBOARD

export interface WDERSFilter extends EntityFilter {
  workspaceIds?: string | string[];
  dashboardIds?: string | string[];
}

export type IWorkspaceDashboardEntityRelationStore =
  IAbstractEntityRelationStore<
    WorkspaceDashboardRelation,
    WorkspaceDashboardCreateRelation,
    WDERSFilter
  >;

class WorkspaceDashboardEntityRelationStore
  extends AbstractEntityRelationStore<
    WorkspaceDashboardRelation,
    WorkspaceDashboardCreateRelation,
    WDERSFilter
  >
  implements IWorkspaceDashboardEntityRelationStore
{
  installationSensitive = true;

  constructor() {
    super(EntityRelationType.WORKSPACE_DASHBOARD);
  }

  apiFetchAll = (filter?: WDERSFilter) =>
    RestApi.workspacesGetAllConnectionsToDashboards(
      filter?.workspaceIds,
      filter?.dashboardIds
    );

  apiFetchOne = (id: string) => {
    throw new Error("unsupported operation");
  };

  apiCreate = (createData: WorkspaceDashboardCreateRelation) =>
    RestApi.workspacesPostConnectionToDashboards(createData);

  newEntity = (id: string, createData: WorkspaceDashboardCreateRelation) => ({
    ...createData,
    id,
  });

  apiUpdate = (id: string, updateData: WorkspaceDashboardCreateRelation) => {
    throw new Error("unsupported operation");
  };

  updateEntity = (
    entity: WorkspaceDashboardRelation | undefined,
    id: string,
    updateData: WorkspaceDashboardCreateRelation
  ) => ({
    ...(entity || {}),
    id,
    ...updateData,
  });

  apiDelete = (id: string) =>
    RestApi.workspacesDelete1ConnectionToDashboard(id);
}

// WORKSPACE - DATASET

export interface WDSERSFilter extends EntityFilter {
  workspaceIds?: string | string[];
  dataSetsIds?: string | string[];
}

export type IWorkspaceDataSetEntityRelationStore = IAbstractEntityRelationStore<
  WorkspaceDataSetRelation,
  WorkspaceDataSetCreateRelation,
  WDSERSFilter
>;

class WorkspaceDataSetEntityRelationStore
  extends AbstractEntityRelationStore<
    WorkspaceDataSetRelation,
    WorkspaceDataSetCreateRelation,
    WDSERSFilter
  >
  implements IWorkspaceDataSetEntityRelationStore
{
  installationSensitive = true;

  constructor() {
    super(EntityRelationType.WORKSPACE_DATASET);
  }

  apiFetchAll = (filter?: WDSERSFilter) =>
    RestApi.workspacesGetAllConnectionsToDataSets(
      filter?.workspaceIds,
      filter?.dataSetsIds
    );

  apiFetchOne = (id: string) => {
    throw new Error("unsupported operation");
  };

  apiCreate = (createData: WorkspaceDataSetCreateRelation) =>
    RestApi.workspacesPostConnectionToDataSets(createData);

  newEntity = (id: string, createData: WorkspaceDataSetCreateRelation) => ({
    ...createData,
    id,
  });

  apiUpdate = (id: string, updateData: WorkspaceDataSetCreateRelation) => {
    throw new Error("unsupported operation");
  };

  updateEntity = (
    entity: WorkspaceDataSetRelation | undefined,
    id: string,
    updateData: WorkspaceDataSetCreateRelation
  ) => ({
    ...(entity || {}),
    id,
    ...updateData,
  });

  apiDelete = (id: string) => RestApi.workspacesDelete1ConnectionToDataSet(id);
}

// WORKSPACE - DATASOURCE

export interface WDSourceERSFilter extends EntityFilter {
  workspaceIds?: string | string[];
  dataSourcesIds?: string | string[];
}

export type IWorkspaceDataSourceEntityRelationStore =
  IAbstractEntityRelationStore<
    WorkspaceDataSourceRelation,
    WorkspaceDataSourceCreateRelation,
    WDSourceERSFilter
  >;

class WorkspaceDataSourceEntityRelationStore
  extends AbstractEntityRelationStore<
    WorkspaceDataSourceRelation,
    WorkspaceDataSourceCreateRelation,
    WDSourceERSFilter
  >
  implements IWorkspaceDataSourceEntityRelationStore
{
  installationSensitive = true;

  constructor() {
    super(EntityRelationType.WORKSPACE_DATASOURCE);
  }

  apiFetchAll = (filter?: WDSourceERSFilter) =>
    RestApi.workspacesGetAllConnectionsToDataSources(
      filter?.workspaceIds,
      filter?.dataSourcesIds
    );

  apiFetchOne = (id: string) => {
    throw new Error("unsupported operation");
  };

  apiCreate = (createData: WorkspaceDataSourceCreateRelation) =>
    RestApi.workspacesPostConnectionToDataSources(createData);

  newEntity = (id: string, createData: WorkspaceDataSourceCreateRelation) => ({
    ...createData,
    id,
  });

  apiUpdate = (id: string, updateData: WorkspaceDataSourceCreateRelation) => {
    throw new Error("unsupported operation");
  };

  updateEntity = (
    entity: WorkspaceDataSourceRelation | undefined,
    id: string,
    updateData: WorkspaceDataSourceCreateRelation
  ) => ({
    ...(entity || {}),
    id,
    ...updateData,
  });

  apiDelete = (id: string) =>
    RestApi.workspacesDelete1ConnectionToDataSource(id);
}

// WORKSPACE - CONNECTOR
export interface WConnectorERSFilter extends EntityFilter {
  workspaceIds?: string | string[];
  connectorIds?: string | string[];
}

export type IWorkspaceConnectorEntityRelationStore =
  IAbstractEntityRelationStore<
    WorkspaceConnectorRelation,
    WorkspaceConnectorCreateRelation,
    WConnectorERSFilter
  >;

class WorkspaceConnectorEntityRelationStore
  extends AbstractEntityRelationStore<
    WorkspaceConnectorRelation,
    WorkspaceConnectorCreateRelation,
    WConnectorERSFilter
  >
  implements IWorkspaceConnectorEntityRelationStore
{
  installationSensitive = true;

  constructor() {
    super(EntityRelationType.WORKSPACE_CONNECTOR);
  }

  apiFetchAll = (filter?: WConnectorERSFilter) =>
    RestApi.workspacesGetAllConnectionsToConnectors(
      filter?.workspaceIds,
      filter?.connectorIds
    );

  apiFetchOne = (id: string) => {
    throw new Error("unsupported operation");
  };

  apiCreate = (createData: WorkspaceConnectorCreateRelation) =>
    RestApi.workspacesPostConnectionToConnectors(createData);

  newEntity = (id: string, createData: WorkspaceConnectorCreateRelation) => ({
    ...createData,
    id,
  });

  apiUpdate = (id: string, updateData: WorkspaceConnectorCreateRelation) => {
    throw new Error("unsupported operation");
  };

  updateEntity = (
    entity: WorkspaceConnectorRelation | undefined,
    id: string,
    updateData: WorkspaceConnectorCreateRelation
  ) => ({
    ...(entity || {}),
    id,
    ...updateData,
  });

  apiDelete = (id: string) =>
    RestApi.workspacesDelete1ConnectionToConnector(id);
}

const allStores = [
  new WorkspaceConnectorEntityRelationStore(),
  new WorkspaceDashboardEntityRelationStore(),
  new WorkspaceDataSetEntityRelationStore(),
  new WorkspaceDataSourceEntityRelationStore(),
  new WorkspaceMemberEntityRelationStore(),
];

export default allStores;
