import { useCallback } from "react";

import { useObserver } from "mobx-react";

import { NamedEntity } from "../data/AppModels";
import { IAbstractEntityStore } from "./AbstractEntityStore";

export function useStoredEntityName<
  T extends NamedEntity = NamedEntity,
  C = any
>(store?: IAbstractEntityStore<T, C>) {
  const { getEntity } = useObserver(() => ({
    getEntity: store?.getOne,
  }));
  const retVal = useCallback(
    (entityId: string) => {
      if (entityId && getEntity) {
        const entity = getEntity(entityId);
        return entity?.name || "";
      }
      return "";
    },
    [getEntity]
  );
  return retVal;
}
