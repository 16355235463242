import {
  DataSourceDto,
  DataSourceQueryAttributeDto,
  DataSourceQueryAttributeUpdateDto,
  DataSourceUpdateDto,
  IPageableResultBase,
} from "../../data/AppModels";
import RestApi from "../../services/RestApi";

export interface DataSourceTransport {
  createDataSource: (dtoU: DataSourceUpdateDto) => Promise<string>;
  updateDataSource: (dtoU: DataSourceUpdateDto, id: string) => Promise<void>;
  deleteDataSource: (id: string) => Promise<void>;
  getDataSource: (id: string) => Promise<DataSourceDto>;
  getDataSources: () => Promise<IPageableResultBase<DataSourceDto>>;

  createDataSourceQueryAttribute: (
    dtoU: DataSourceQueryAttributeUpdateDto
  ) => Promise<string>;
  updateDataSourceQueryAttribute: (
    dtoU: DataSourceQueryAttributeUpdateDto,
    id: string
  ) => Promise<void>;
  getDataSourceQueryAttributes: (
    dataSources: string | string[] | undefined
  ) => Promise<IPageableResultBase<DataSourceQueryAttributeDto>>;
  getDataSourceQueryAttribute: (id) => Promise<DataSourceQueryAttributeDto>;
}

export function createDataSourceTransport(): DataSourceTransport {
  return {
    createDataSource,
    updateDataSource,
    deleteDataSource,
    getDataSource,
    getDataSources,
    createDataSourceQueryAttribute,
    updateDataSourceQueryAttribute,
    getDataSourceQueryAttributes,
    getDataSourceQueryAttribute,
  };
}

/** data source * */

function createDataSource(dtoU: DataSourceUpdateDto) {
  return RestApi.doPost<string>("/v1/data-sources", dtoU);
}

function updateDataSource(dtoU: DataSourceUpdateDto, id: string) {
  return RestApi.doPut<void>(`/v1/data-sources/${id}`, dtoU, {
    returnRawData: true,
  });
}

function deleteDataSource(id: string) {
  return RestApi.doDelete(`/v1/data-sources/${id}`);
}

function getDataSource(id: string) {
  return RestApi.doGet<DataSourceDto>(`/v1/data-sources/${id}`);
}

function getDataSources() {
  return RestApi.doGet<IPageableResultBase<DataSourceDto>>("/v1/data-sources", {
    pageable: true,
    params: {
      sort: "name,asc",
    },
  });
}

/** * Query attributes * */

function createDataSourceQueryAttribute(
  dtoU: DataSourceQueryAttributeUpdateDto
) {
  return RestApi.doPost<string>("/v1/data-source-query-attributes", dtoU);
}

function updateDataSourceQueryAttribute(
  dtoU: DataSourceQueryAttributeUpdateDto,
  datasourceQueryAttributeId: string
) {
  return RestApi.doPut<void>(
    `/v1/data-source-query-attributes/${datasourceQueryAttributeId}`,
    dtoU,
    { returnRawData: true }
  );
}

function getDataSourceQueryAttributes(
  dataSources: string | string[] | undefined = undefined
) {
  return RestApi.doGet<IPageableResultBase<DataSourceQueryAttributeDto>>(
    "/v1/data-source-query-attributes",
    {
      pageable: true,
      params: {
        "data-sources": Array.isArray(dataSources)
          ? dataSources.join()
          : dataSources,
      },
    }
  );
}

function getDataSourceQueryAttribute(id: string) {
  return RestApi.doGet<DataSourceQueryAttributeDto>(
    `/v1/data-source-query-attributes/${id}`
  );
}
