import React from "react";

import classNames from "classnames";

import Loader from "./Loader";

const PageLoader = ({ size = 32, dark = false }) => (
  <div className={classNames("page-loader", dark && "dark")}>
    <Loader size={size} />
  </div>
);

export default PageLoader;
