/* eslint-disable react/sort-comp */

import React, { Component } from "react";

import i18next from "i18next";
import { debounce, throttle, find, get, isNil } from "lodash";
import cloneDeep from "lodash/cloneDeep";
import { inject, observer } from "mobx-react";
import pWaterfall from "p-waterfall";
import { Responsive, Layout, Layouts } from "react-grid-layout";
import { RouteComponentProps } from "react-router-dom";
import { AutoSizer } from "react-virtualized";
import { DropdownItem, InputGroup } from "reactstrap";

import AppConstants from "../../data/AppConstants";
import * as Models from "../../data/AppModels";
import {
  IWidget,
  IShareWithExternalData,
  IWidgetShareWithData,
  IWidgetVisualisationData,
  WidgetType,
} from "../../data/AppModels";
import AppUtils from "../../data/AppUtils";
import Operations from "../../data/Operations";
import RestApi from "../../services/RestApi";
import withLoudAsync, {
  LoudAsyncComponentProps,
} from "../../services/withLoudAsync";
import { IApplicationStore } from "../../store/ApplicationStore";
import { IDashboardStore } from "../../store/DashboardStore";
import { EntityRelationType } from "../../store/EntityRelationStores";
import { IWidgetStore } from "../../store/WidgetStore";
import DashboardKeysDropDown from "../dashboard/DashboardKeysDropDown";
import DashboardPageHeaderDropdown from "../dashboard/DashboardPageHeaderDropdown";
import { EntityWorkspaceRelationInPopover } from "../entities/EntityWorkspaceRelation";
import { confirm } from "../modal/Confirm";
import CreateWidgetsModal from "../modal/create/CreateWidgetsModal";
import WidgetSwitchBetweenDashboardsModal from "../modal/WidgetSwitchBetweenDashboardsModal";
import DShareComponent, { IDShareData } from "../share/DShareComponent";
import Page from "../templates/Page";
import PageContent from "../templates/PageContent";
import PageHeader from "../templates/PageHeader";
import { WidgetBody, widgetWrapper } from "../widgets/Widget";
import WidgetPresentationType from "../widgets/WidgetPresentationType";

import "./DashboardView.scss";

export interface IProps extends LoudAsyncComponentProps, RouteComponentProps {
  widgetStore?: IWidgetStore;
  applicationStore?: IApplicationStore;
  dashboardStore?: IDashboardStore;
}

export interface IState {
  dashboardName: string;
  removedWidgets: string[];
  fullscreenWidgetId: string | null;
  savedLayout: string | null;
  shareWidgetId: string | null;
  switchWidgetBetweenDashboardsId: string | undefined;
  loadedLayout: boolean;
  widgets: IWidget[];
  createWidgetModal: boolean;
  dragging: boolean;
  selectedFilterKey: string | undefined; // in case of used data set as filter
}

export function compareJsonWithoutOrder(
  first: any[] | null | undefined,
  second: any[] | null | undefined
): boolean {
  let returnValue = true;
  if (!first || !second) {
    return false;
  }

  if (first.length !== second.length) {
    return false;
  }

  Object.keys(first).forEach((key) => {
    const item: any = get(first, key);
    const itemIndex: any = get(item, "i");
    const secondItem = find(second, { i: itemIndex });

    // @ts-ignore
    if (
      !secondItem ||
      item.x !== secondItem.x ||
      item.y !== secondItem.y ||
      item.w !== secondItem.w ||
      item.h !== secondItem.h
    ) {
      returnValue = false;
    }
  });

  return returnValue;
}

const INITIAL_STATE: IState = {
  dashboardName: "",
  removedWidgets: [],
  fullscreenWidgetId: null,
  savedLayout: null,
  shareWidgetId: null,
  switchWidgetBetweenDashboardsId: undefined,
  loadedLayout: false,
  widgets: [],
  createWidgetModal: false,
  dragging: false,
  selectedFilterKey: undefined,
};

@inject("widgetStore")
@inject("applicationStore")
@inject("dashboardStore")
@observer
class DashboardView extends Component<IProps, IState> {
  state: IState;

  newWidgetId: string | null;

  widgetVisualisations: IWidgetVisualisationData[] = [];

  constructor(properties) {
    super(properties);

    this.state = cloneDeep(INITIAL_STATE);
    this.newWidgetId = null;

    this.createWidget = this.createWidget.bind(this);
    this.removeWidget = this.removeWidget.bind(this);
    this.editWidget = this.editWidget.bind(this);
    this.fullscreenWidget = this.fullscreenWidget.bind(this);
    this.onLayoutChange = debounce(this.onLayoutChange.bind(this), 500);
    this.handleShareWidget = this.handleShareWidget.bind(this);
    this.handleSwitchBetweenDashboards =
      this.handleSwitchBetweenDashboards.bind(this);
    this.shareSubmit = this.shareSubmit.bind(this);
    this.shareCancel = this.shareCancel.bind(this);
    this.generateShareLink = this.generateShareLink.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  // get dashboard id from url
  dashboardId = (props = this.props) => get(props.match.params, ["id"]);

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(previousProps: IProps) {
    const prevDashboardId = this.dashboardId(previousProps);
    const thisDashboardId = this.dashboardId();
    if (prevDashboardId !== thisDashboardId) {
      // update last selected
      this.props.dashboardStore?.selectDashboard(thisDashboardId);
      this.reloadData();
    }
  }

  reloadData() {
    this.setState(cloneDeep(INITIAL_STATE), () => {
      this.loadData();
    });
  }

  loadData() {
    if (this.props.widgetStore && this.props.dashboardStore) {
      const { widgetStore, dashboardStore } = this.props;
      const dashboardId = this.dashboardId();

      pWaterfall([
        () =>
          this.props.doLoudAsync(
            () =>
              Promise.all([
                widgetStore.fetchDashboardWidgetItems(dashboardId),
                dashboardStore.getDashboard(dashboardId),
              ]),
            {
              errorMessage: i18next.t("dash.error.couldnotload.widgets"),
              errorResolution: "page-overlay", // this is a dead-end - screen has nothing to show if this promise fails
              loadingIndicator: "page-overlay",
              onSuccess: ([_, dashboard]) => {
                this.setState({
                  dashboardName: dashboard?.name || "",
                });
              },
            }
          ),
        () =>
          this.props.doLoudAsync(
            () =>
              RestApi.loadUserPropertyV1(`layout${dashboardId}`).then(
                (property) => {
                  if (!isNil(property)) {
                    this.setState({
                      savedLayout: property.value,
                      loadedLayout: true,
                    });
                  }
                }
              ),
            {
              errorResolution: "toast",
              errorMessage: i18next.t("dash.error.couldnotload.layout"),
              loadingIndicator: "page-overlay",
            }
          ),
      ]);
    }
  }

  onLayoutChange(layout: Layout[], layouts: Layouts) {
    if (!this.state.fullscreenWidgetId && this.state.loadedLayout) {
      const layoutString = JSON.stringify(layouts.lg);
      const areSame: boolean = compareJsonWithoutOrder(
        layouts.lg,
        JSON.parse(this.state.savedLayout || "[]") as []
      );
      if (!areSame) {
        this.setState(
          {
            savedLayout: layoutString,
          },
          () => {
            // save to DB
            RestApi.saveUserPropertyV1({
              key: `layout${this.dashboardId()}`,
              value: layoutString,
            });
          }
        );
      }
    }
  }

  fullscreenWidget(id: string) {
    this.setState(
      (previousState) => ({
        fullscreenWidgetId: !previousState.fullscreenWidgetId ? id : null,
      }),
      () => {
        AppUtils.toggleFullscreen();
      }
    );
  }

  createWidget(widget: Models.IWidget) {
    const isFullscreen = !!this.state.fullscreenWidgetId;
    const widgetType: WidgetType = AppUtils.getWidgetType(
      widget,
      this.widgetVisualisations
    );

    const doRemove = this.isAllowed(Operations.DELETE_WIDGET)
      ? this.removeWidget
      : undefined;

    const doEdit = this.isAllowed(Operations.CREATE_WIDGET)
      ? this.editWidget
      : undefined;

    const doShare = this.isAllowed([
      Operations.SHARE_WIDGET_EXTERNAL,
      Operations.CREATE_WIDGET_SHARE,
    ])
      ? this.handleShareWidget
      : undefined;

    const doSwitchWidgetBetweenDashboards = this.isAllowed(
      Operations.CREATE_WIDGET
    )
      ? this.handleSwitchBetweenDashboards
      : undefined;

    const filterKeys: string[] | undefined = this.state.selectedFilterKey
      ? [this.state.selectedFilterKey]
      : undefined;

    return widgetWrapper(
      <WidgetBody
        id={widget.id}
        widgetType={widgetType}
        widget={widget}
        isFullscreen={isFullscreen}
        doRemove={doRemove}
        doEdit={doEdit}
        doFullscreen={() => this.fullscreenWidget(widget.id)}
        doShare={doShare}
        doSwitchDashboard={doSwitchWidgetBetweenDashboards}
        isPreview={false}
        isNew={widget.id === this.newWidgetId}
        presentationType={WidgetPresentationType.APP}
        dragging={this.state.dragging}
        filterKeys={filterKeys}
      />,
      widget.id,
      isFullscreen
    );
  }

  removeWidget(id: string): void {
    if (this.props.widgetStore) {
      this.props.widgetStore
        .removeWidgetFromDashboard(this.dashboardId(), id)
        .then(() => {
          // remove fullscreen mode
          if (this.state.fullscreenWidgetId) {
            this.setState(
              {
                fullscreenWidgetId: null,
              },
              () => {
                AppUtils.toggleFullscreen();
              }
            );
          }
          return null;
        })
        .catch((error) => {
          console.warn("could not remove widget", error);
        });
    }
  }

  isAllowedOperation = (operation: Operations) =>
    this.props.applicationStore?.isAllowed(operation) || false;

  isAllowed = (operations: Operations | Operations[]) => {
    if (Array.isArray(operations)) {
      const ops = operations as Operations[];
      // allowed if ANY allowed
      return ops.some((op) => this.isAllowedOperation(op));
    }
    return this.isAllowedOperation(operations as Operations);
  };

  handleShareWidget(id: string): void {
    this.setState({
      shareWidgetId: id,
    });
  }

  handleSwitchBetweenDashboards(id: string): void {
    this.setState({
      switchWidgetBetweenDashboardsId: id,
    });
  }

  generateShareLink = (id: string) => {
    const data: IShareWithExternalData = {
      widgetIds: [],
      notifyEmailAdress: null,
      text: null,
    };
    data.widgetIds.push(id);
    return RestApi.shareWithExternalV1(data);
  };

  buildShareElement(): JSX.Element {
    // console.info("buildShareElement this.state.shareWidgetId: " + this.state.shareWidgetId);
    const allowedExternal = this.isAllowed(Operations.SHARE_WIDGET_EXTERNAL);
    const allowedInternal = this.isAllowed(Operations.CREATE_WIDGET_SHARE);
    return (
      <DShareComponent
        isOpen={!isNil(this.state.shareWidgetId)}
        share={this.shareSubmit}
        cancel={this.shareCancel}
        loadDefaultUsers
        showInternal={allowedInternal}
        showExternal={allowedExternal}
        showMessage
        generateShareLink={
          allowedExternal
            ? () => {
                if (!this.state.shareWidgetId) {
                  return Promise.resolve();
                }
                return this.generateShareLink(this.state.shareWidgetId);
              }
            : undefined
        }
      />
    );
  }

  shareSubmit(data: IDShareData) {
    if (data.selectedUserIds.length === 0 && !data.sendTo) {
      return;
    }

    // share with internal users
    if (this.state.shareWidgetId && data.selectedUserIds.length !== 0) {
      const shareWithData: IWidgetShareWithData = {
        userGroupsAdd: [],
        userIdsAdd: data.selectedUserIds,
        text: data.customText,
      };

      RestApi.shareWidgetV1(this.state.shareWidgetId, shareWithData);
    }

    // share with external users, using email address
    if (this.state.shareWidgetId && data.sendTo) {
      const shareWithExternalData: IShareWithExternalData = {
        widgetIds: [],
        notifyEmailAdress: data.sendTo,
        text: data.customText,
      };
      shareWithExternalData.widgetIds.push(this.state.shareWidgetId);

      RestApi.shareWithExternalV1(shareWithExternalData);
    }

    this.shareCancel();
  }

  shareCancel() {
    this.setState({
      shareWidgetId: null,
    });
  }

  editWidget(id: string): void {
    this.props.history.push(
      AppConstants.joinUrl(AppConstants.RT_L_DASHBOARD_WIDGET_CONFIG, {
        widgetId: id,
      })
    );
  }

  /**
   * loads layout from localstorage if exists
   * if does not exist, generates default layout
   * @param widgets
   */
  getLayout = (widgets: Models.IWidget[]) => {
    if (this.state.fullscreenWidgetId) {
      return [
        {
          i: this.state.fullscreenWidgetId,
          x: 0,
          y: 0,
          w: 12,
          h: Math.floor((window.innerHeight - 100) / 100),
        },
      ];
    }

    const existingWidgets: string[] = [];
    let layout: Layout[] = [];

    // saved layout exists, reusing
    if (!isNil(this.state.savedLayout)) {
      layout = JSON.parse(this.state.savedLayout);
      // force insert newly added widgets to the end of dashboard
      layout.forEach((widgetConfig: any) => {
        existingWidgets.push(widgetConfig.i);
      });

      // we have new item, so re-shake others
      if (widgets.length > Object.keys(layout).length && widgets.length > 1) {
        layout = layout.map((widgetConfig: any) => {
          widgetConfig.y += 3;
          return widgetConfig;
        });
      }

      widgets.forEach((widget: Models.IWidget) => {
        if (!existingWidgets.includes(widget.id)) {
          layout.push({ i: widget.id, x: 0, y: 0, w: 4, h: 3 });

          this.newWidgetId = widget.id;
        }
      });

      return layout;
    }
    // generate new layout
    let X = 0;
    let Y = 0;
    widgets.forEach((widget: Models.IWidget) => {
      layout.push({ i: widget.id, x: X * 4, y: Y * 3, w: 4, h: 3 });
      X += 1;
      if (X > 2) {
        X = 0;
        Y += 1;
      }
    });

    return layout;
  };

  getLayoutSm = (widgets: Models.IWidget[]) => {
    const layout: Layout[] = [];

    let Y = 0;
    widgets.forEach((widget: Models.IWidget) => {
      layout.push({ i: widget.id, x: 0, y: Y * 3, w: 12, h: 3 });
      Y += 1;
    });
    return layout;
  };

  toggleCreateWidgetModal = () => {
    this.setState({
      createWidgetModal: !this.state.createWidgetModal,
    });
  };

  setDragging = throttle((dragging: boolean) => {
    if (dragging) {
      if (!this.state.dragging) {
        this.setState({ dragging });
      }
    } else {
      this.setState({ dragging });
    }
  }, 150);

  doDelete = () => {
    const _dashboardId = this.dashboardId();

    confirm({
      message: i18next.t("dashboards.confirm.delete"),
      buttons: [
        {
          label: i18next.t("yes"),
          type: "confirm",
          onClick: () => {
            this.props.doLoudAsync(
              async () => {
                await this.props.dashboardStore?.removeDashboard(_dashboardId);
                // TODO multiple-dashboards :
                //  - show this button only for non-default dashboards
                //  - show confirmation dialogue with options
                //     - REMOVE - navigate to default dashboard afterwards AppConstants.RT_L_DASHBOARD
                //     - REMOVE AND MOVE WIDGETS (a popup with list of other dashboards) - navigate to selected dashboard afterwards

                const defaultDashboardId =
                  this.props.dashboardStore?.defaultDashboardId;
                this.props.history.push(
                  AppConstants.joinUrl(AppConstants.RT_L_DASHBOARD_VIEW, {
                    id: defaultDashboardId,
                  })
                );
              },
              {
                errorResolution: "toast",
                errorMessage: i18next.t("dash.error.couldNotRemove"),
              }
            );
          },
        },
        {
          label: i18next.t("no"),
          type: "cancel",
          onClick: () => {},
        },
      ],
    });
  };

  headerMenuItems = () => {
    const items: JSX.Element[] = [];
    const _dashboardId = this.dashboardId();

    const canEdit = this.props.dashboardStore?.canEdit(_dashboardId);
    const canRemove = this.props.dashboardStore?.canRemove(_dashboardId);

    if (canEdit) {
      const addItem = (
        <DropdownItem key="add" onClick={this.toggleCreateWidgetModal}>
          <i className="icon-plus" />
          {i18next.t("header.action.widget")}
        </DropdownItem>
      );

      items.push(addItem);

      const editPropertiesItem = (
        <DropdownItem
          key="properties"
          onClick={() =>
            this.props.history.push(
              AppConstants.joinUrl(
                AppConstants.RT_L_DASHBOARD_PROPERTIES_VIEW,
                { id: _dashboardId }
              )
            )
          }
        >
          <i className="icon-equalizer" />
          {i18next.t("header.action.dashboardProperties")}
        </DropdownItem>
      );

      items.push(editPropertiesItem);
    }

    if (canRemove) {
      const deleteItem = (
        <DropdownItem
          key="delete"
          style={{ color: "danger" }}
          onClick={() => this.doDelete()}
        >
          <i className="icon-trash" />
          {i18next.t("header.action.deleteDashboard")}
        </DropdownItem>
      );

      items.push(deleteItem);
    }

    return items;
  };

  showWidgets = (): boolean => {
    const selectedDashboard = this.props.dashboardStore?.dashboards.find(
      (dashboard) => dashboard.id === this.dashboardId()
    );

    const hasFilter = selectedDashboard && selectedDashboard.dataSetFilter;

    return hasFilter ? this.state.selectedFilterKey !== undefined : true;
  };

  selectFilterKey = (filterKey: string | undefined) => {
    this.setState({ ...this.state, selectedFilterKey: filterKey });
  };

  render() {
    const _dashboardId = this.dashboardId();

    let widgets: IWidget[] = [];
    if (this.props.widgetStore) {
      widgets = this.props.widgetStore.widgetItems(_dashboardId);

      this.widgetVisualisations =
        this.props.widgetStore.widgetVisualisationsItems;
    }

    const { removedWidgets } = this.state;
    let displayWidgets: Models.IWidget[] = [];
    let layout: Layout[] = [];
    let layoutSm: Layout[] = [];
    const fullscreen = this.state.fullscreenWidgetId;
    const shareWidgetElement: JSX.Element | null = this.buildShareElement();

    if (widgets.length > 0 && this.showWidgets()) {
      displayWidgets = widgets.filter((widget: Models.IWidget) => {
        if (!fullscreen) {
          return !removedWidgets.includes(widget.id);
        }
        return fullscreen === widget.id;
      });
      layout = this.getLayout(displayWidgets);
      layoutSm = this.getLayoutSm(displayWidgets);
    }

    const canEdit = this.props.dashboardStore?.canEdit(_dashboardId);

    return (
      <>
        <Page>
          <PageHeader
            headline={this.state.dashboardName}
            headlineEditable={
              canEdit
                ? (newValue) => {
                    this.props.dashboardStore?.renameDashboard(
                      this.dashboardId(),
                      newValue
                    );
                    this.setState({
                      dashboardName: newValue,
                    });
                  }
                : undefined
            }
            headlineEditPlaceholder={i18next.t("namePlaceholder.dashboard")}
            customControl={
              <InputGroup>
                <DashboardKeysDropDown
                  dashboardId={_dashboardId}
                  selectedFilterKey={this.state.selectedFilterKey}
                  selectFilterKey={this.selectFilterKey}
                />
                <div className="ml-3">
                  <EntityWorkspaceRelationInPopover
                    entityId={_dashboardId}
                    type={EntityRelationType.WORKSPACE_DASHBOARD}
                  />
                </div>
                {this.headerMenuItems().length !== 0 && (
                  <DashboardPageHeaderDropdown items={this.headerMenuItems()} />
                )}
              </InputGroup>
            }
          />
          <PageContent fullscreenContent padding={false}>
            <AutoSizer>
              {({ height, width }) => (
                <div className="grid-wrapper" style={{ height, width }}>
                  <Responsive
                    className="layout"
                    layouts={{ lg: layout, sm: layoutSm }}
                    breakpoints={{ lg: 400, sm: 0 }}
                    cols={{ lg: 12, sm: 12 }}
                    rowHeight={100}
                    useCSSTransforms={false}
                    autoSize
                    width={width - 20} // 20 to prevent horizontal scrollbar
                    onLayoutChange={this.onLayoutChange}
                    margin={fullscreen ? [0, 0] : [16, 16]}
                    onDrag={() => this.setDragging(true)}
                    onDragStop={() => this.setDragging(false)}
                    isDraggable={!fullscreen}
                    draggableCancel=".widget-actions, .widget-controls"
                    isResizable={!fullscreen}
                  >
                    {displayWidgets.map((widget) => this.createWidget(widget))}
                  </Responsive>
                </div>
              )}
            </AutoSizer>
          </PageContent>
        </Page>
        {shareWidgetElement}
        {this.state.switchWidgetBetweenDashboardsId && (
          <WidgetSwitchBetweenDashboardsModal
            cancel={() => {
              this.setState({ switchWidgetBetweenDashboardsId: undefined });
            }}
            submit={() => {
              this.setState({ switchWidgetBetweenDashboardsId: undefined });
            }}
            widgetId={this.state.switchWidgetBetweenDashboardsId!}
            oldDashboardId={this.dashboardId()}
          />
        )}
        <CreateWidgetsModal
          isOpen={this.state.createWidgetModal}
          toggle={() => this.toggleCreateWidgetModal()}
        />
      </>
    );
  }
}

export default withLoudAsync(DashboardView);
/* eslint-enable react/sort-comp */
