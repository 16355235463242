import React from "react";

import "./UserListItem.scss";
import { IUser } from "../../data/AppModels";
import UserPhoto from "./UserPhoto";

export type Props = {
  user: IUser;
};

export default function UserListItem({ user }: Props) {
  return (
    <div className="user-list-item">
      <UserPhoto userId={user.id} />
      {user.name}
    </div>
  );
}
