import React, { Component } from "react";

import { Button } from "reactstrap";

import {
  IControlsWidgetContext,
  IDashboardWidgetExternal,
  IWidget,
  IWidgetParameters,
} from "../../../data/AppModels";

import "./WidgetControls.scss";
import "./WidgetActions.scss";
import DatePicker from "react-datepicker";
import moment from "moment";
import i18next from "i18next";

interface IProps extends React.HTMLAttributes<HTMLElement> {
  widget: IWidget | IDashboardWidgetExternal;
  widgetParameters?: IWidgetParameters | null;
  onApply?: (widgetControlsContext: IControlsWidgetContext) => void;
}

interface IState {
  changed: boolean;
  date: Date | null;
  dateFrom: Date | null;
  dateTo: Date | null;
  isDate: boolean;
  isInterval: boolean;
}

export default class WidgetControls extends Component<IProps, IState> {
  props: IProps;

  state: IState;

  constructor(properties) {
    super(properties);
    this.props = properties;
    this.state = {
      changed: false,

      date: null,
      dateFrom: null,
      dateTo: null,
      isDate: false,
      isInterval: false,
    };
    this.hasDatePicker = this.hasDatePicker.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleDateFromChange = this.handleDateFromChange.bind(this);
    this.handleDateToChange = this.handleDateToChange.bind(this);
    this.handleApply = this.handleApply.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProperties, nextContext: any): void {
    const { state } = this;

    state.date = null;
    state.dateFrom = null;
    state.dateTo = null;
    state.isDate = false;
    state.isInterval = false;

    const { widgetParameters } = nextProperties;

    if (widgetParameters) {
      state.date = widgetParameters.date;
      state.dateFrom = widgetParameters.dateFrom;
      state.dateTo = widgetParameters.dateTo;
      state.isInterval = widgetParameters.isInterval;
      state.isDate = widgetParameters.isDate;
    }
    // console.log("componentWillReceiveProps state: " + JSON.stringify(state));
    // if (state.date){
    //     state.date = new Date(state.date.toString());
    //
    //     console.log("componentWillReceiveProps state.date.toString: " + JSON.stringify(state.date.toString()));
    //     console.log("componentWillReceiveProps state.toISOString: " + JSON.stringify(state.date.toISOString()));
    //     console.log("componentWillReceiveProps state.toUTCString: " + JSON.stringify(state.date.toUTCString()));
    // }

    this.setState(state);
  }

  hasDatePicker() {
    return this.state.isDate;
  }

  hasDateRangePicker() {
    return this.state.isInterval;
  }

  handleDateChange(date: Date) {
    // console.info("handleDateChange date:" + date);
    // console.info("handleDateChange date.toString:" + date.toString());
    // console.info("handleDateChange date.toUTCString:" + date.toUTCString());
    // console.info("handleDateChange date.toISOString:" + date.toISOString());
    // console.info("handleDateChange date.ddmmyyyy:" + date.getUTCDay() + " " + date.getMonth() + " " + date.getFullYear());
    const { state } = this;
    // state.date = date;
    if (date) {
      state.date = new Date(moment(date).format("YYYY-MM-DD"));
    } else {
      state.date = null;
    }
    state.changed = true;
    this.setState(state);
    // console.info("handleDateChange: state" + JSON.stringify(state));
  }

  handleDateFromChange(date: Date) {
    const { state } = this;
    if (date) {
      state.dateFrom = new Date(moment(date).format("YYYY-MM-DD"));
    } else {
      state.dateFrom = null;
    }
    state.changed = true;
    this.setState(state);
  }

  handleDateToChange(date: Date) {
    const { state } = this;
    if (date) {
      state.dateTo = new Date(moment(date).format("YYYY-MM-DD"));
    } else {
      state.dateTo = null;
    }
    state.changed = true;
    this.setState(state);
  }

  handleApply(event: React.MouseEvent) {
    // console.info("handleApply state: " + JSON.stringify(this.state));
    if (this.props.onApply) {
      const widgetControlsContext: IControlsWidgetContext = {
        date: this.state.date,
        dateFrom: this.state.dateFrom,
        dateTo: this.state.dateTo,
      };

      this.props.onApply(widgetControlsContext);

      const { state } = this;
      state.changed = false;
      this.setState(state);
    }
  }

  render() {
    let datePicker;
    if (this.hasDatePicker()) {
      datePicker = (
        <>
          <i className="icon-calendar ml-1 mr-2" />
          <DatePicker
            key={`dpId${this.props.widget.id}`}
            selected={this.state.date}
            onChange={this.handleDateChange}
            dateFormat="dd.MM.yyyy"
            className="form-control"
            todayButton={i18next.t("btnToday")}
            isClearable
            showYearDropdown
            showMonthDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={5}
          />
        </>
      );
    }
    let dateRangePicker;
    if (this.hasDateRangePicker()) {
      // text.push(
      //   <span key="dateRange">
      //     <strong>Date range: </strong>
      //     {moment(this.state.dateFrom).format("DD.MM.yyyy")} <i>to</i> {moment(this.state.dateTo).format("DD.MM.yyyy")}
      //   </span>
      // );
      dateRangePicker = (
        <>
          <i className="icon-calendar ml-1 mr-2" />
          <DatePicker
            key="fg2dp1"
            selected={this.state.dateFrom}
            selectsStart
            startDate={this.state.dateFrom}
            endDate={this.state.dateTo}
            onChange={this.handleDateFromChange}
            dateFormat="dd.MM.yyyy"
            className="form-control"
            todayButton={i18next.t("btnToday")}
            isClearable
            showYearDropdown
            showMonthDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={5}
          />
          <span className="ml-2 mr-1">-</span>
          <DatePicker
            key="fg2dp2"
            selected={this.state.dateTo}
            selectsEnd
            startDate={this.state.dateFrom}
            endDate={this.state.dateTo}
            onChange={this.handleDateToChange}
            dateFormat="dd.MM.yyyy"
            className="form-control"
            todayButton={i18next.t("btnToday")}
            isClearable
            showYearDropdown
            showMonthDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={5}
          />
        </>
      );
    }
    const hasControls = !!datePicker || !!dateRangePicker;

    let applyButton;
    if (hasControls) {
      applyButton = (
        <div className="d-flex justify-content-center align-items-center ml-1">
          <Button
            className={this.state.changed ? "float-right" : "d-none"}
            size="sm"
            color="primary"
            onClick={this.handleApply}
          >
            <i className="icon-check" />
          </Button>
        </div>
      );

      // editBtn = (
      //     <Button
      //       color="link"
      //       className="d-inline-block ml-2"
      //       onClick={this.toggleVisibility}
      //     >
      //       <i className="icon-wrench" />
      //     </Button>
      // );
    }
    return (
      <div className={`widget-controls${hasControls ? "" : "hidden"}`}>
        <div
          // className={this.state.visible ? "d-flex" : "d-none"}
          className="d-flex align-items-center"
        >
          {datePicker}
          {dateRangePicker}
          {applyButton}
        </div>
        {/* <Row */}
        {/*  className={!this.state.visible ? "d-flex" : "d-none"} */}
        {/* > */}
        {/*  <Col sm="9">{text}{editBtn}</Col> */}

        {/* </Row> */}
      </div>
    );
  }
}
