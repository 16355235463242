import React from "react";

import { ImpulseSpinner } from "react-spinners-kit";

import { COLORS } from "../../data/AppUtils";

const Loader = ({ color = COLORS.PRIMARY_75, size = 32 }) => (
  <div className="loader" style={{ minHeight: "24px" }}>
    <ImpulseSpinner
      frontColor={color}
      backColor={COLORS.GRAY_100}
      size={size}
      sizeUnit="px"
    />
  </div>
);

export default Loader;
