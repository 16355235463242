import * as _ from "lodash";
import { action, computed, configure, observable } from "mobx";

import createDataSetApi from "../../api/DataSetApi";
import { DataSetDto } from "../../api/dto/data-set/DataSetDto";
import {
  DsMetaColumnDto,
  IDSInfoLoadData,
  IDsJoinColumns,
  IDsMergeConfiguration,
  IDsMergeColumn,
  ITable,
  INotification,
  NotificationType,
} from "../../data/AppModels";
import * as Models from "../../data/AppModels";
import RestApi from "../../services/RestApi";
import BasicStore from "../BasicStore";

// strict mode
configure({ enforceActions: "observed" });

export interface IDatasourceMergeStore extends BasicStore {
  newDatasourceName: string;

  filter: string;

  filteredDatasources: DataSetDto[];

  selectedDatasources: DataSetDto[];

  filteredDsColumns: DsMetaColumnDto[];

  selectedDsColumns: DsMetaColumnDto[];

  joinColumns: IDsJoinColumns[];

  isDatasourceNameSet: boolean;

  configuration: IDsMergeConfiguration;

  isConfigurationValid: boolean;

  preview: ITable | null;

  notifications: INotification[];

  setNewDatasourceName(name: string): void;

  loadDatasources(): void;

  setFilter(filterText: string): void;

  addSelectedDatasource(ds: DataSetDto, addToBeginning?: boolean): void;

  removeSelectedDatasource(ds: DataSetDto): void;

  hasSelectedDatasource(ds: DataSetDto): boolean;

  addSelectedDsColumn(column: DsMetaColumnDto): void;

  removeSelectedDsColumn(col: DsMetaColumnDto): void;

  addJoinColumns(leftCol: DsMetaColumnDto, rightCol: DsMetaColumnDto): void;

  removeJoinColumns(ds: DataSetDto): void;

  addNotification(type: NotificationType, message: string): void;

  clearNotifications(): void;

  clear(): void;
}

export default class DatasourceMergeStore implements IDatasourceMergeStore {
  dataSetApi = createDataSetApi();

  isInstallationSensitive = () => true;

  @observable newDsName = "";

  @observable filterText = "";

  @observable dss: DataSetDto[] = [];

  @observable filteredDss: DataSetDto[] = [];

  @observable selectedDss: DataSetDto[] = [];

  @observable displayLimitedDs = true;

  @observable dsColumns: DsMetaColumnDto[] = [];

  @observable filteredDsCols: DsMetaColumnDto[] = [];

  @observable selectedDsCols: DsMetaColumnDto[] = [];

  @observable joinCols: IDsJoinColumns[] = [];

  @observable previewData: ITable | null = null;

  @observable msgs: INotification[] = [];

  @action.bound
  public setNewDatasourceName(name: string): void {
    this.newDsName = name;
  }

  @computed
  public get newDatasourceName(): string {
    return this.newDsName;
  }

  @action.bound
  private setDatasources(dss: DataSetDto[]): void {
    this.dss = dss;
    this.setFilteredDatasources(this.filter);
  }

  @action.bound
  public loadDatasources(): void {
    this.dataSetApi
      .getAll()
      .then((dss) => {
        this.setDatasources(dss ? dss.content : []);
      })
      .catch((error) => {
        console.log("Could not load available datasources", error);
      });
  }

  @action.bound
  public setFilter(filterText: string): void {
    this.filterText = filterText;

    // Filter datasets
    this.setFilteredDatasources(this.filterText);

    // Filter columns
    this.setFilteredColumns(this.filterText);
  }

  @action.bound
  private setFilteredDatasources(filterText: string): void {
    const filteredDss: DataSetDto[] = this.dss.filter((ds: DataSetDto) => {
      // Check if ds is selected
      const selectedDss: DataSetDto[] = this.selectedDss.filter(
        (selectedDs: DataSetDto) => selectedDs.id === ds.id
      );
      const isSelected: boolean = selectedDss.length > 0;

      // Check if ds.name match filter (case insensitive)
      let matchFilter = false;
      if (ds.name) {
        const regex = new RegExp(filterText, "i");
        matchFilter = ds.name.search(regex) >= 0;
      }

      return !isSelected && matchFilter;
    });
    this.filteredDss = filteredDss;
  }

  @action.bound
  private setFilteredColumns(filterText: string): void {
    const filteredCols: DsMetaColumnDto[] = this.dsColumns.filter(
      (col: DsMetaColumnDto) => {
        // Check if col is selected
        const selectedCols: DsMetaColumnDto[] = this.selectedDsCols.filter(
          (selectedCol: DsMetaColumnDto) => selectedCol.id === col.id
        );
        const isSelected: boolean = selectedCols.length > 0;

        // Check if col.name match filter (case insensitive)
        let matchFilter = false;
        if (col.name) {
          const regex = new RegExp(filterText, "i");
          matchFilter = col.name.search(regex) >= 0;
        }

        return !isSelected && matchFilter;
      }
    );
    this.filteredDsCols = filteredCols;
  }

  @computed
  public get filter(): string {
    return this.filterText;
  }

  @computed
  public get filteredDatasources(): DataSetDto[] {
    return this.filteredDss;
  }

  @computed
  public get selectedDatasources(): DataSetDto[] {
    return this.selectedDss;
  }

  @action.bound
  public addSelectedDatasource(ds: DataSetDto, addToBeginning?: boolean): void {
    if (addToBeginning) {
      this.selectedDss.unshift(ds);
    } else {
      this.selectedDss.push(ds);
    }

    this.setFilteredDatasources(this.filter);
    this.loadDatasourceColumns(this.selectedDss);
    this.loadPreview();
  }

  @action.bound
  public removeSelectedDatasource(ds: DataSetDto): void {
    _.remove(this.selectedDss, { id: ds.id });
    this.removeSelectedDsColumns(ds);
    this.removeJoinColumns(ds);
    this.setFilteredDatasources(this.filter);
    this.loadDatasourceColumns(this.selectedDss);
    this.loadPreview();
  }

  @action.bound
  public hasSelectedDatasource(ds: DataSetDto): boolean {
    const hasSelectedDs: boolean = this.selectedDss.some(
      (selectedDs: DataSetDto) => selectedDs.id === ds.id
    );

    return hasSelectedDs;
  }

  @action.bound
  private setDatasourceColumns(columns: DsMetaColumnDto[]) {
    this.dsColumns = columns;
    this.setFilteredColumns(this.filter);
  }

  @action.bound
  private loadDatasourceColumns(dss: DataSetDto[]): void {
    const promises: Promise<Models.IDSInfoLoadData>[] = [];

    // Load columns for selected datasets
    dss.forEach((ds: DataSetDto) => {
      promises.push(RestApi.loadDSMeta(ds.id));
    });

    Promise.all(promises)
      .then((results: IDSInfoLoadData[]) => {
        const columns: Models.DsMetaColumnDto[] = [];

        results.forEach((result: IDSInfoLoadData) => {
          result.columns.forEach((column: DsMetaColumnDto) => {
            const col: DsMetaColumnDto = {
              id: column.id,
              name: column.name,
              dsId: column.dsId,
              dsName: column.dsName,
            };
            columns.push(col);
          });
        });

        // Sort columns by name
        const sortedCols: DsMetaColumnDto[] = _.sortBy(columns, "name");
        this.setDatasourceColumns(sortedCols);
      })
      .catch((error) => {
        console.log("Could not load columns for selected datasets", error);
      });
  }

  @computed
  public get selectedDsColumns(): DsMetaColumnDto[] {
    return this.selectedDsCols;
  }

  @computed
  public get filteredDsColumns(): DsMetaColumnDto[] {
    return this.filteredDsCols;
  }

  @action.bound
  public addSelectedDsColumn(column: DsMetaColumnDto): void {
    this.selectedDsColumns.push(column);
    this.setFilteredColumns(this.filter);
    this.loadPreview();
  }

  @action.bound
  public removeSelectedDsColumn(col: DsMetaColumnDto): void {
    _.remove(this.selectedDsCols, { id: col.id });
    this.setFilteredColumns(this.filter);
    this.loadPreview();
  }

  @action.bound
  private removeSelectedDsColumns(ds: DataSetDto): void {
    const colsToRemove: DsMetaColumnDto[] = [];

    this.selectedDsCols.forEach((col: DsMetaColumnDto) => {
      if (col.dsId === ds.id) {
        colsToRemove.push(col);
      }
    });

    colsToRemove.forEach((colToRemove: DsMetaColumnDto) => {
      _.remove(this.selectedDsCols, { id: colToRemove.id });
    });
  }

  @computed
  public get joinColumns(): IDsJoinColumns[] {
    return this.joinCols;
  }

  @action.bound
  public addJoinColumns(
    leftCol: DsMetaColumnDto,
    rightCol: DsMetaColumnDto
  ): void {
    const join: IDsJoinColumns = {
      id: _.uniqueId(),
      leftColumn: leftCol,
      rightColumn: rightCol,
    };

    console.warn("Clear join - for now only 1 join allowed");
    this.joinCols = [];

    this.joinCols.push(join);

    this.loadPreview();
  }

  @action.bound
  public removeJoinColumns(ds: DataSetDto): void {
    const joinsToRemove: IDsJoinColumns[] = [];

    this.joinCols.forEach((join: IDsJoinColumns) => {
      if (join.leftColumn.dsId === ds.id || join.rightColumn.dsId === ds.id) {
        joinsToRemove.push(join);
      }
    });

    joinsToRemove.forEach((joinToRemove: IDsJoinColumns) => {
      _.remove(this.joinCols, { id: joinToRemove.id });
    });
  }

  @computed
  public get configuration(): IDsMergeConfiguration {
    const dss: string[] = this.selectedDss.map((ds: DataSetDto) => ds.id);

    const cols: IDsMergeColumn[] = this.selectedDsCols.map(
      (col: DsMetaColumnDto) => {
        const mergeCol: IDsMergeColumn = {
          dataSourceId: col.dsId,
          columnId: col.id,
        };

        return mergeCol;
      }
    );

    const joinCols: IDsMergeColumn[] = [];
    this.joinCols.forEach((join: IDsJoinColumns) => {
      const leftCol: IDsMergeColumn = {
        dataSourceId: join.leftColumn.dsId,
        columnId: join.leftColumn.id,
      };
      joinCols.push(leftCol);

      const rightCol: IDsMergeColumn = {
        dataSourceId: join.rightColumn.dsId,
        columnId: join.rightColumn.id,
      };
      joinCols.push(rightCol);
    });

    return {
      dataSources: dss,
      columns: cols,
      joinColumns: joinCols,
    };
  }

  @computed
  public get isDatasourceNameSet(): boolean {
    if (this.newDatasourceName && this.newDatasourceName.length > 0) {
      return true;
    }
    return false;
  }

  @computed
  public get isConfigurationValid(): boolean {
    const config: IDsMergeConfiguration = this.configuration;

    if (
      config &&
      config.dataSources.length > 1 &&
      config.columns.length > 0 &&
      config.joinColumns.length > 0 &&
      this.validDsJoins(config.dataSources, config.joinColumns)
    ) {
      return true;
    }

    return false;
  }

  @action.bound
  private loadPreview(): void {
    const config: IDsMergeConfiguration = this.configuration;

    if (this.isConfigurationValid) {
      RestApi.dataForDsMergeConfigurationV1(config).then((table: ITable) => {
        this.setPreview(table);
      });
    } else {
      console.warn("Incomplete configuration, could not load preview data.");
    }
  }

  /**
   * Check if each selected datasource is joined
   * @param dss
   * @param joins
   */
  private validDsJoins(dss: string[], joins: IDsMergeColumn[]): boolean {
    const existingJoins: boolean[] = [];

    dss.forEach((ds: string) => {
      let joinExists = false;

      for (const join of joins) {
        if (ds === join.dataSourceId) {
          joinExists = true;
          break;
        }
      }

      existingJoins.push(joinExists);
    });

    existingJoins.forEach((exists: boolean) => {
      if (!exists) {
        return false;
      }
    });

    return true;
  }

  @action.bound
  private setPreview(table: ITable) {
    this.previewData = table;
  }

  @computed
  public get preview(): ITable | null {
    return this.previewData;
  }

  @computed
  public get notifications(): INotification[] {
    return this.msgs;
  }

  @action.bound
  public addNotification(type: NotificationType, message: string): void {
    const notification: INotification = {
      type,
      message,
    };

    this.msgs.push(notification);
  }

  @action.bound
  public clearNotifications(): void {
    this.msgs = [];
  }

  @action.bound
  public clear(): void {
    this.newDsName = "";
    this.filterText = "";

    this.dss = [];
    this.filteredDss = [];
    this.selectedDss = [];

    this.displayLimitedDs = true;

    this.dsColumns = [];
    this.filteredDsCols = [];
    this.selectedDsCols = [];

    this.joinCols = [];
    this.previewData = null;
    this.msgs = [];
  }
}
