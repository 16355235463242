import { action, computed, configure, observable } from "mobx";

import createDashboardApi from "../api/DashboardApi";
import DashboardDto from "../api/dto/dashboard/DashboardDto";
import { compareJsonWithoutOrder } from "../components/views/DashboardView";
import * as Models from "../data/AppModels";
import { UserProperty } from "../data/AppModels";
import WorkspaceEntityOperations from "../data/WorkspaceEntityOperations";
import { NavItemDefinition } from "../layout/useNavigation";
import RestApi from "../services/RestApi";
import BasicStore from "./BasicStore";

export type Dashboard = DashboardDto;

const SIDEBAR_DASHBOARD_ORDER = "layout-sidebar-dashbord-order";

export interface IDashboardStore extends BasicStore {
  dashboards: Dashboard[];

  selectedDashboardId: string | undefined;

  defaultDashboardId: string | undefined;

  layout: DashboardLayout;

  fetchLayout(widgets: Models.IWidget[]): Promise<void>;

  updateLayout(layouts: any): Promise<void>;

  fetchDashboards(): Promise<Dashboard[]>;

  saveDashboardOrder(items: NavItemDefinition[]): void;

  removeDashboard(dashboardId: string): Promise<void>;

  addDashboard(): Promise<string>;

  getDashboard(dashboardId: string): Promise<Dashboard | undefined>;

  renameDashboard(dashboardId: string, newName: string): Promise<void>;

  changeDashboardDataSetFilter(
    dashboardId: string,
    dataSetFilterId: string | undefined
  ): Promise<void>;

  selectDashboard(dashboardId: string): void;

  setMyUser(userId: string | undefined, superUser: boolean): void;

  canEdit(dashboardId: string);

  canRemove(dashboardId: string);
}

export interface DashboardLayout {
  lg: any[];
  sm: any[];
}

// strict mode
configure({ enforceActions: "observed" });

type MyUser = {
  id: string | undefined;
  superUser: boolean;
};

class DashboardStore implements IDashboardStore {
  isInstallationSensitive = () => true;

  dashboardApi = createDashboardApi();

  @observable myUser: MyUser | undefined;

  @observable dashboardsArray: Dashboard[] = [];

  @observable currentSelectedDashboardId: string | undefined = undefined;

  @observable currentLayout: DashboardLayout = { lg: [], sm: [] };

  @computed
  get dashboards(): Dashboard[] {
    return this.dashboardsArray;
  }

  @computed
  get selectedDashboardId(): string | undefined {
    // console.info('selectedDashboardId: ' + this.currentSelectedDashboardId)
    return this.currentSelectedDashboardId;
  }

  @computed
  get defaultDashboardId(): string | undefined {
    const myUserId = this.myUser?.id;
    const myPreset = myUserId
      ? this.dashboardsArray.find(
          (dash) => dash.preSet === true && dash.usersId === myUserId
        )?.id
      : undefined;
    if (myUserId && !myPreset) {
      console.warn(
        "you do not have any preset dashboard - will use the first available"
      );
      return this.dashboardsArray[0]?.id;
    }
    return myPreset;
  }

  @computed
  get layout(): DashboardLayout {
    return this.currentLayout;
  }

  @action
  async removeDashboard(dashboardId: string) {
    try {
      await this.dashboardApi.delete(dashboardId);
      await this.fetchDashboards();
    } catch (error) {
      console.warn("could not remove dashboard", error);
      throw error;
    }
  }

  @action
  async addDashboard() {
    try {
      const dashboardId = await this.dashboardApi.create({});
      // const previousDashboards = new Set([...this.dashboards].map(dash => dash.id))
      await this.fetchDashboards();
      return dashboardId;
    } catch (error) {
      console.warn("could not add widget to dashboard", error);
      throw error;
    }
  }

  @action
  async fetchDashboards() {
    try {
      const data = await this.dashboardApi.getAll(undefined, undefined, [
        { key: "createdDate", sort: "desc" },
      ]);
      const dashboards = data && data.content ? data.content : [];
      const defaultDashboardId = dashboards.find((dash) => dash.preSet === true)
        ?.id!;
      if (this.selectedDashboardId) {
        const selectedDashboard = dashboards.find(
          (dash) => dash.id === this.selectedDashboardId
        );
        if (!selectedDashboard) {
          // selected dashboard is not available anymore -> fallback to default
          this.setSelectedDashboard(defaultDashboardId);
        }
      }

      // Ordering
      const saved = await RestApi.loadUserPropertyV1(SIDEBAR_DASHBOARD_ORDER);
      if (saved?.value) {
        const order: string[] = JSON.parse(saved.value);
        const newOrder: DashboardDto[] = [];
        order.forEach((item) => {
          const found = dashboards
            .filter((innerItem) => item === innerItem.id)
            .pop();
          if (found) {
            newOrder.push(found);
          }
        });

        dashboards.forEach((item) => {
          const found = newOrder
            .filter((innerItem) => innerItem.id === item.id)
            .pop();
          if (!found) {
            newOrder.push(item);
          }
        });

        this.setDashboards(newOrder);
        return newOrder;
      }

      this.setDashboards(dashboards);
      return dashboards;
    } catch (error) {
      console.warn("could not fetch dashboards", error);
      this.setDashboards([]);
      throw error;
    }
  }

  @action
  async saveDashboardOrder(items: NavItemDefinition[]): Promise<void> {
    const newOrder: Dashboard[] = [];
    items.map((item) => {
      const found = this.dashboards
        .filter((innerItem) => item.id === innerItem.id)
        .pop();
      if (found) {
        newOrder.push(found);
      }
    });
    this.setDashboards(newOrder);
    if (items.length > 0 && items) {
      await RestApi.saveUserPropertyV1({
        key: SIDEBAR_DASHBOARD_ORDER,
        value: JSON.stringify(items.map((item) => item.id)),
      });
    }
  }

  @action
  async getDashboard(dashboardId: string) {
    try {
      let dashboard = this.dashboards.find((dash) => dash.id === dashboardId);
      if (dashboard) {
        return dashboard;
      }
      await this.fetchDashboards();
      dashboard = this.dashboards.find((dash) => dash.id === dashboardId);
      return dashboard;
    } catch (error) {
      console.warn("could not get dashboard name", error);
      throw error;
    }
  }

  @action
  async changeDashboardDataSetFilter(
    dashboardId: string,
    dataSetFilterId: string | undefined
  ) {
    try {
      // update name

      const dashboard = this.dashboardsArray.find((e) => e.id === dashboardId);

      if (dashboard) {
        // await RestApi.updateDashboard(dashboardId, {name: newName});

        await this.dashboardApi.update(dashboardId, {
          name: dashboard.name,
          description: dashboard.description,
          dataSetFilterId,
        });

        await this.fetchDashboards();
      }
    } catch (error) {
      console.warn(`could not update dashboard${error}`);
      throw error;
    }
  }

  @action
  async renameDashboard(dashboardId: string, newName: string) {
    try {
      // update name

      const dashboard = this.dashboardsArray.find((e) => e.id === dashboardId);

      if (dashboard) {
        // await RestApi.updateDashboard(dashboardId, {name: newName});

        await this.dashboardApi.update(dashboardId, {
          name: newName,
          description: dashboard.description,
          dataSetFilterId: dashboard.dataSetFilter?.id,
        });

        await this.fetchDashboards();
      }
      // TODO hwo to replace it correctly

      // // get and update content
      // const dashboard = await RestApi.loadDashboard(dashboardId)

      // this.setDashboards(this.dashboardsArray.map(ds => {
      //   if (ds.id === dashboardId) {
      //     return dashboard
      //   } else {
      //     return ds
      //   }
      // }))
    } catch (error) {
      console.warn(`could not rename dashboard${error}`);
      throw error;
    }
  }

  @action
  async fetchLayout(widgets: Models.IWidget[]): Promise<void> {
    // let layout: any = []
    // let X = 0
    //   let Y = 0
    //   widgets.forEach((widget: Models.IDashboardWidgetItemData) => {
    //     layout.push({i: widget.id, x: X * 4, y: Y * 3, w: 4, h: 3})
    //     X += 1
    //     if (X > 2) {
    //       X = 0
    //       Y += 1
    //     }
    //   })
    //
    //
    // this.setCurrentLayout({lg: layout, sm: layout})

    // first get stored layout

    console.info(`fetchLayout for widgets: ${JSON.stringify(widgets)}`);
    const key = `layout${this.selectedDashboardId}`; // TODO + dashboard id

    const storedLayout: UserProperty | undefined =
      await RestApi.loadUserPropertyV1(key);
    // if exists reuse

    let layout: any = [];
    const existingWidgets: string[] = [];

    // saved layout exists, reusing
    if (storedLayout && storedLayout.value) {
      layout = JSON.parse(storedLayout.value);

      console.info(`using stored layout: ${layout}`);

      // force insert newly added widgets to the end of dashboard
      layout.forEach((widgetConfig: any) => {
        existingWidgets.push(widgetConfig.i);
      });

      // we have new item, so re-shake others
      if (widgets.length > Object.keys(layout).length && widgets.length > 1) {
        layout = layout.map((widgetConfig: any) => {
          widgetConfig.y += 3;
          return widgetConfig;
        });
      }

      widgets.forEach((widget: Models.IWidget) => {
        if (!existingWidgets.includes(widget.id)) {
          layout.push({ i: widget.id, x: 0, y: 0, w: 4, h: 3 });

          // this.newWidgetId = widget.id
        }
      });
    } else {
      // create new one

      let X = 0;
      let Y = 0;
      widgets.forEach((widget: Models.IWidget) => {
        layout.push({ i: widget.id, x: X * 4, y: Y * 3, w: 4, h: 3 });
        X += 1;
        if (X > 2) {
          X = 0;
          Y += 1;
        }
      });
    }

    // what for is this ?
    const layoutSm: any[] = [];

    let Y = 0;
    widgets.forEach((widget: Models.IWidget) => {
      layoutSm.push({ i: widget.id, x: 0, y: Y * 3, w: 12, h: 3 });
      Y += 1;
    });

    console.info(`lgLayout: ${JSON.stringify(layout)}`);

    // this.setCurrentLayout({lg: layout, sm: layoutSm})
    this.setCurrentLayout({ lg: layout, sm: [] });
  }

  @action
  async updateLayout(layouts: any): Promise<void> {
    const layoutLg = JSON.stringify(layouts.lg);
    console.info(`updateLayout: ${layoutLg}`);

    const key = `layout${this.selectedDashboardId}`; // TODO + dashboard id

    const compareCurrent: any = this.currentLayout.lg;

    // TODO este tatok podmientka
    // if (!this.state.fullscreenWidgetId && this.state.loadedLayout) {

    const areSame: boolean = compareJsonWithoutOrder(
      layouts.lg,
      compareCurrent
    );
    if (!areSame) {
      console.info("layout not same updating");
      // save to DB
      await RestApi.saveUserPropertyV1({ key, value: layoutLg });

      this.setCurrentLayout({ lg: compareCurrent, sm: [] });
    }
  }

  @action
  selectDashboard(dashboardId: string): void {
    this.setSelectedDashboard(dashboardId);
  }

  @action.bound
  setDashboards(dashboards: Dashboard[]): void {
    this.dashboardsArray = dashboards;
  }

  @action.bound
  setSelectedDashboard(dashboardId: string) {
    // console.info('setSelectedDashboard: ' + dashboardId)
    this.currentSelectedDashboardId = dashboardId;
  }

  @action.bound
  setCurrentLayout(layout: DashboardLayout) {
    this.currentLayout = layout;
  }

  @action
  setMyUser(userId: string | undefined, superUser: boolean) {
    this.myUser = { id: userId, superUser };
  }

  @action
  canEdit(dashboardId: string) {
    const dashboard = this.dashboards.find((dash) => dash.id === dashboardId);
    return (
      this.myUser?.superUser === true ||
      dashboard?.operations.includes(WorkspaceEntityOperations.EDIT) === true ||
      false
    );
  }

  @action
  canRemove(dashboardId: string) {
    const dashboard = this.dashboards.find((dash) => dash.id === dashboardId);
    return (
      this.myUser?.superUser === true ||
      (dashboardId !== this.defaultDashboardId &&
        dashboard?.operations.includes(WorkspaceEntityOperations.DELETE) ===
          true) ||
      false
    );
  }

  clear(): void {
    this.dashboardsArray = [];
    this.currentSelectedDashboardId = undefined;
    this.myUser = undefined;
    this.currentLayout = { lg: [], sm: [] };
  }
}

export default DashboardStore;
