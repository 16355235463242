import { IAbstractStore } from "./AbstractStore";
import AllStores from "./AllStores";
import ApplicationStore from "./ApplicationStore";
import ConnectorsStore from "./connector/ConnectorsStore";
import { createConnectorStoreTransport } from "./connector/connectorTransports";
import ConnectorTypeAttributeStore from "./connector/ConnectorTypeAttributeStore";
import { createConnectorTypeAttributeStoreTransport } from "./connector/connectorTypeAttributesTransports";
import ConnectorTypeQueriesStore from "./connector/ConnectorTypeQueriesStore";
import { createConnectorTypeQueriessTransport } from "./connector/connectorTypeQueriesTransport";
import ConnectorTypeQueryAttributesStore from "./connector/ConnectorTypeQueryAttributesStore";
import { createConnectorTypeQueryAttributesTransport } from "./connector/connectorTypeQueryAttributesTransport";
import ConnectorTypesStore from "./connector/ConnectorTypesStore";
import { createConnectorTypesTransport } from "./connector/connectorTypesTransport";
import UserConnectorStore from "./connector/UserConnectorStore";
import DashboardStore from "./DashboardStore";
import DataSetTemplateStore from "./dataSet/DataSetTemplateStore";
import DatasourceMergeStore from "./dataSet/DatasourceMergeStore";
import DataSourcesStore from "./dataSource/DataSourcesStore";
import { createDataSourceTransport } from "./dataSource/dataSourceTransport";
import entityRelationStores from "./EntityRelationStores";
import InstallationStore from "./installations/InstallationStore";
import NotificationsStore from "./notifications/NotificationsStore";
import TasksStore from "./TasksStore";
import UserStore from "./UserStore";
import WidgetStore from "./WidgetStore";
import WorkspaceStore from "./WorkspaceStore";

const notificationsStore = new NotificationsStore();
const widgetStore = new WidgetStore();
const tasksStore = new TasksStore();
const mergeStore = new DatasourceMergeStore();
const dataSetTemplateStore = new DataSetTemplateStore();
const installationStore = new InstallationStore();
const dashboardStore = new DashboardStore();
const userStore = new UserStore();
const workspaceStore = new WorkspaceStore();
const connectorTypesStore = new ConnectorTypesStore(
  createConnectorTypesTransport()
);
const connectorTypeAttributeStore = new ConnectorTypeAttributeStore(
  createConnectorTypeAttributeStoreTransport()
);
const connectorTypeQueriesStore = new ConnectorTypeQueriesStore(
  createConnectorTypeQueriessTransport()
);
const connectorTypeQueryAttributesStore = new ConnectorTypeQueryAttributesStore(
  createConnectorTypeQueryAttributesTransport()
);
const dataSourcesStore = new DataSourcesStore(
  connectorTypeQueryAttributesStore,
  createDataSourceTransport()
);
const userConnectorsStore = new UserConnectorStore(
  connectorTypeAttributeStore,
  createConnectorStoreTransport()
);
const connectorsStore = new ConnectorsStore(
  connectorTypeAttributeStore,
  createConnectorStoreTransport()
);

function storeListAsObject(list: IAbstractStore<any, any>[]) {
  return list.reduce((acc, store) => {
    acc[store.name] = store;
    return acc;
  }, {});
}

const entityStores = [
  userStore,
  workspaceStore,
  connectorsStore,
  userConnectorsStore,
  dataSourcesStore,
];

const typeStores = [
  connectorTypesStore,
  connectorTypeAttributeStore,
  connectorTypeQueriesStore,
  connectorTypeQueryAttributesStore,
];

const childStores = {
  notificationsStore,
  widgetStore,
  tasksStore,
  mergeStore,
  dataSetTemplateStore,
  installationStore,
  dashboardStore,
  ...storeListAsObject(entityStores),
  ...storeListAsObject(entityRelationStores),
  ...storeListAsObject(typeStores),
};

const applicationStore = new ApplicationStore(new AllStores(childStores));

// initialize mobx stores
export const stores = {
  ...childStores,
  applicationStore,
};
