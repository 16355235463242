import axios, { AxiosResponse, ResponseType } from "axios";
import { set } from "lodash";
import moment from "moment";

import Response from "../api/dto/common/Response";
import { DataSetLabelTypeDto } from "../api/dto/data-set-label-type/DataSetLabelTypeDto";
import * as Models from "../data/AppModels";
import {
  DataSetLabelTypeResult,
  DataSourceTestResultDto,
  FieldTypeDto,
  FieldTypeResult,
  FileType,
  GoogleConfiguration,
  GoogleDriveFile,
  IAuthData,
  ICatalogueCategory,
  ICatalogueCategoryResult,
  ICatalogueWidgetTemplateResult,
  ICreatePermissionGroup,
  ICreateUser,
  ICurrentUser,
  IDashboardWidgetExternal,
  IDataResponse,
  IDataSourceResult,
  IDataSourceShareWithData,
  IDSBuildData,
  IDSDataSetDataPageable,
  IDSDataSetUpdate,
  IDSInfoLoadData,
  IDsMergeConfiguration,
  IDsMergeData,
  IDSSCreateCustomData,
  IDSSLoadData,
  IDsTemplate,
  IDsWidgetConfigPredefinedData,
  IGenerateData,
  IGenerateOutputData,
  IGoogleTokenFromCodeDtoR,
  IInstallationCreate,
  IInstallationResult,
  INotificationDataResult,
  INotificationsInfoData,
  IPermissionGroupInstallationResult,
  IPermissionGroupResult,
  IPermissionGroupUsersResult,
  IPermissionType,
  IShareWithExternalData,
  ITable,
  ITaskData,
  ITaskItemDataResult,
  IUser,
  IUserResult,
  IUsersGroupsFindResultData,
  IWidget,
  IWidgetConfig,
  IWidgetShareWithData,
  IWidgetsResult,
  IWidgetsVisualisationsResult,
  ScriptDefinitionLabelDto,
  ScriptDefinitionLabelDtoU,
  ScriptDefinitionLabelResult,
  ServerFileDto,
  UserProperty,
} from "../data/AppModels";

const RestApiErrorName = "RestApiError";
const restApiError = (msg: string) => {
  const e = new Error(msg);
  e.name = RestApiErrorName;
  return e;
};

interface AxiosOptions {
  contentType: string;
  includeToken: boolean;
  token?: string;
  includeInstallation: boolean;
  includeLang: boolean;
  params: {};
  onUploadProgress?: (event: any) => void;
}

const defaultAxiosOptions: AxiosOptions = {
  contentType: "application/json",
  includeInstallation: true,
  includeLang: true,
  includeToken: true,
  params: {},
};

interface PutPostOptions extends AxiosOptions {
  responseType: ResponseType;
  returnRawData: boolean;
}

const defaultPutPostOptions: PutPostOptions = {
  ...defaultAxiosOptions,
  responseType: "json",
  returnRawData: false,
};

interface GetOptions extends PutPostOptions {
  pageable: boolean;
  pageSize: number;
  page: number;
}

const defaultGetOptions: GetOptions = {
  ...defaultPutPostOptions,
  pageable: false,
  pageSize: 999,
  page: 0,
};

type DeleteOptions = AxiosOptions;

const defaultDeleteOptions = defaultAxiosOptions;

let _installationId: string | undefined;
let _accessToken: string | null = null;
let _language: Models.Language | undefined;

const prepareGenericHeadersAndParams = (opts: AxiosOptions) => {
  const headers = {};
  if (opts.contentType) {
    set(headers, "Content-Type", opts.contentType);
  }
  if (opts.includeToken) {
    set(headers, "Authorization", `Bearer ${opts.token || _accessToken}`);
  }
  if (opts.includeLang) {
    set(headers, "Language", _language);
  }

  const params = {};
  if (opts.includeInstallation) {
    const selectedInstallation = _installationId;
    if (selectedInstallation) {
      set(params, "installations", selectedInstallation);
    }
  }

  return { headers, params };
};

function processGetPutPostResponse<T>(
  res: AxiosResponse<IDataResponse<T> | T>,
  url: string,
  method: string,
  opts: PutPostOptions | GetOptions
) {
  if (opts.returnRawData || method === "PUT") {
    // console.log(`rest api :: or raw response :: ${method} :: ${url}`, res)
    return res.data as T;
  }
  if (res.data) {
    // console.log(`rest api :: ok response with data :: ${method} :: ${url}`, res)
    const dataResponse = res.data as IDataResponse<T>;
    if (dataResponse) {
      if (dataResponse.infoMessages && dataResponse.infoMessages.length > 0) {
        // console.log(`rest api :: info from server :: ${method} :: ${url} :: ${dataResponse.infoMessages.join(' :: ')}`)
      }
      if (dataResponse.errorMessages && dataResponse.errorMessages.length > 0) {
        console.error(
          `rest api :: errors from server ::: ${method} :: ${url} :: ${dataResponse.errorMessages.join(
            " :: "
          )}`
        );
        // throw new Error('error messages received from server: ' + dataResponse.errorMessages.join(', '))
      }
      return dataResponse.data;
    }
  }
  console.error(
    `rest api :: ok response, but data is missing :: ${method} :: ${url}`,
    res
  );
  throw restApiError(`no data received :: ${method} :: ${url}`);
}

function processDeleteResponse(res: AxiosResponse, url: string) {
  if (res) {
    // console.log(`rest api :: ok raw response :: DELETE :: ${url}`, res)
    return res.data as void;
  }
  throw restApiError(`no data received :: DELETE :: ${url}`);
}

function processResponseError<T>(
  error: any,
  url: string,
  method: string,
  opts: PutPostOptions | GetOptions | DeleteOptions
) {
  if (error === null || error === undefined) {
    // console.log(`rest api :: strange response ! :: catched error, but it is null or undefined :: returning empty object :: ${method} :: ${url}`)
    return {} as T;
  }
  console.error(`rest api :: error :: ${method} :: ${url} ::`, error);
  throw error;
}

export default class RestApi {
  static _setAccessToken(token: string | null) {
    _accessToken = token;
  }

  static _getAccessToken() {
    return _accessToken;
  }

  static _setLanguage(language: Models.Language) {
    _language = language;
  }

  static _setInstallationId(instId: string | undefined) {
    _installationId = instId;
  }

  static _getInstallationId() {
    return _installationId;
  }

  static doGet<T>(url: string, options?: Partial<GetOptions>): Promise<T> {
    const opts = options
      ? { ...defaultGetOptions, ...options }
      : defaultGetOptions;

    const { headers, params } = prepareGenericHeadersAndParams(opts);

    if (opts.pageable) {
      set(params, "page", opts.page);
      set(params, "size", opts.pageSize);
    }

    return axios
      .get<IDataResponse<T> | T>(url, {
        headers,
        params: { ...params, ...opts.params },
        responseType: opts.responseType,
        onUploadProgress: opts.onUploadProgress,
      })
      .then((res) => processGetPutPostResponse(res, url, "GET", opts))
      .catch((error) => processResponseError(error, url, "GET", opts));
  }

  static doPost<T>(
    url: string,
    data: {},
    options?: Partial<PutPostOptions>
  ): Promise<T> {
    const opts = options
      ? { ...defaultPutPostOptions, ...options }
      : defaultPutPostOptions;

    const { headers, params } = prepareGenericHeadersAndParams(opts);

    return axios
      .post<IDataResponse<T> | T>(url, data, {
        headers,
        params: { ...params, ...opts.params },
        responseType: opts.responseType,
        onUploadProgress: opts.onUploadProgress,
      })
      .then((res) => processGetPutPostResponse(res, url, "POST", opts))
      .catch((error) => processResponseError(error, url, "POST", opts));
  }

  static doPut<T>(
    url: string,
    data: {},
    options?: Partial<PutPostOptions>
  ): Promise<T> {
    const opts = options
      ? { ...defaultPutPostOptions, ...options }
      : defaultPutPostOptions;

    const { headers, params } = prepareGenericHeadersAndParams(opts);

    return axios
      .put<IDataResponse<T> | T>(url, data, {
        headers,
        params: { ...params, ...opts.params },
        responseType: opts.responseType,
        onUploadProgress: opts.onUploadProgress,
      })
      .then((res) => processGetPutPostResponse(res, url, "PUT", opts))
      .catch((error) => processResponseError(error, url, "PUT", opts));
  }

  static doPatch<T>(
    url: string,
    data: {},
    options?: Partial<PutPostOptions>
  ): Promise<T> {
    const opts = options
      ? { ...defaultPutPostOptions, ...options }
      : defaultPutPostOptions;

    const { headers, params } = prepareGenericHeadersAndParams(opts);

    return axios
      .patch<IDataResponse<T> | T>(url, data, {
        headers,
        params: { ...params, ...opts.params },
        responseType: opts.responseType,
        onUploadProgress: opts.onUploadProgress,
      })
      .then((res) => processGetPutPostResponse(res, url, "PUT", opts))
      .catch((error) => processResponseError(error, url, "PUT", opts));
  }

  static doDelete(
    url: string,
    options?: Partial<DeleteOptions>
  ): Promise<void> {
    const opts = options
      ? { ...defaultDeleteOptions, ...options }
      : defaultDeleteOptions;

    const { headers, params } = prepareGenericHeadersAndParams(opts);

    return axios
      .delete(url, {
        headers,
        params: { ...params, ...opts.params },
        onUploadProgress: opts.onUploadProgress,
      })
      .then((res) => processDeleteResponse(res, url))
      .catch((error) => processResponseError(error, url, "DELETE", opts));
  }

  static version() {
    return RestApi.doGet<Models.VersionDto>("/version", {
      includeInstallation: false,
      includeLang: false,
      includeToken: false,
      returnRawData: true,
    });
  }

  static loadSuitableDataSourcesForTemplate(widgetTemplateId: string) {
    return RestApi.doGet<IDataSourceResult>("/v1/data-sources", {
      pageable: true,
      params: { widgetTemplateId },
    });
  }

  static createWidgetFromTemplate(data: {
    dataSetId: string;
    widgetTemplateId: string;
  }) {
    return RestApi.doPost<string>("/v1/widgets", data);
  }

  static updateDatasetData(dataSetId: string) {
    return RestApi.doPut<void>(
      `/v1/data-sets/${dataSetId}/update-data`,
      {},
      { returnRawData: true }
    );
  }

  // todo:
  // api/dataForWidgetConfiguration - load data
  // api/widgetConfiguration - save
  // addWidgetToDashboard - pridat na dashboard - posiela sa null param
  // pozriet ako sa plni param v MongoWidgetConfigurationTest a podla toho vyskladat...
  static loadDataForWidgetConfigurationV1(
    configData: Models.WidgetConfigurationDto
  ) {
    return RestApi.doPost<any>("/v1/widgets/configuration-data", configData);
  }

  static loadWidgetConfigurationV1(widgetId: string) {
    return RestApi.doGet<Models.WidgetConfigurationDto>(
      `/v1/widgets/${widgetId}/configuration`
    );
  }

  static loadPredefinedDataSetConfiguration(
    dataSetId: string,
    predefinedWidgetConfigurationId: string
  ) {
    return RestApi.doGet<IDsWidgetConfigPredefinedData>(
      `/v1/data-sets/${dataSetId}/widget-config-predefined/${predefinedWidgetConfigurationId}`
    );
  }

  static loadPredefinedConfiguration(predefinedWidgetConfigurationId: string) {
    return RestApi.doGet<IDsWidgetConfigPredefinedData>(
      `/v1/widget-config-predefined/${predefinedWidgetConfigurationId}`
    );
  }

  static saveWidgetConfigurationV1(configData: Models.WidgetConfigurationDto) {
    return RestApi.doPost<string>("/v1/widgets/configuration", configData);
  }

  static updateWidgetConfigurationV1(
    widgetId: string,
    configData: Models.WidgetConfigurationDto
  ) {
    return RestApi.doPut<void>(
      `/v1/widgets/${widgetId}/configuration/`,
      JSON.stringify(configData),
      { returnRawData: true }
    );
  }

  static loadWidgetDataV1(widgetId: string, context: any | null) {
    return RestApi.doGet<any>(`/v1/widgets/${widgetId}/data`, {
      params: {
        date:
          context && context.date
            ? moment(context.date).format("YYYY-MM-DD")
            : undefined,
        dateFrom:
          context && context.dateFrom
            ? moment(context.dateFrom).format("YYYY-MM-DD")
            : undefined,
        dateTo:
          context && context.dateTo
            ? moment(context.dateTo).format("YYYY-MM-DD")
            : undefined,
        fullScreen: context && context.fullScreen,
        keys:
          context && Array.isArray(context.filterKeys)
            ? context.filterKeys.join()
            : context.filterKeys,
      },
    });
  }

  static loadWidgetDataExternalV1(widgetId: string, context: any | null) {
    return RestApi.doGet<any>(`/v1/external/widgets/${widgetId}/data`, {
      returnRawData: true,
      params: {
        date:
          context && context.date
            ? moment(context.date).format("YYYY-MM-DD")
            : undefined,
        dateFrom:
          context && context.dateFrom
            ? moment(context.dateFrom).format("YYYY-MM-DD")
            : undefined,
        dateTo:
          context && context.dateTo
            ? moment(context.dateTo).format("YYYY-MM-DD")
            : undefined,
        fullScreen: context && context.fullScreen,
      },
    });
  }

  static prepareDSFromFileV1(file: File) {
    const formData = new FormData();
    formData.append("file", file);
    return RestApi.doPost<string>("/v1/data-sets-file", formData, {
      contentType: "multipart/form-data",
      onUploadProgress: (event) => {
        console.log("File upload...", event);
      },
    });
  }

  static uploadUserPhotoV1(file: File, userId: string) {
    const formData = new FormData();
    formData.append("file", file);
    return RestApi.doPut<void>(`/v1/users/${userId}/photo`, formData, {
      contentType: "multipart/form-data",
      returnRawData: true,
      onUploadProgress: (event) => {
        console.log("User photo upload...", event);
      },
    });
  }

  static deleteUserPhotoV1(userId: string) {
    return RestApi.doDelete(`/v1/users/${userId}/photo`);
  }

  static loadUserPhotoV1(userId: string, format = "BASE_64") {
    return RestApi.doGet<string>(`/v1/users/${userId}/photo`, {
      params: { format },
    });
  }

  static createCustomDSDataSetv1(data: IDSSCreateCustomData) {
    return RestApi.doPost<string>("/v1/data-sets/create-custom", { data });
  }

  static buildDSDataSetV1(dataSourceId: string, data: IDSBuildData) {
    return RestApi.doPut<void>(
      `/v1/data-sets/${dataSourceId}/build`,
      JSON.stringify(data),
      { returnRawData: true }
    );
  }

  static updateDSDataSetV1(dataSetId: string, data: IDSDataSetUpdate) {
    return RestApi.doPut<void>(
      `/v1/data-sets/${dataSetId}/cells`,
      JSON.stringify(data),
      { returnRawData: true }
    );
  }

  static loadConnectorServerFiles(fileTypes?: string[]) {
    return RestApi.doGet<ServerFileDto[]>("/v1/connector-server-files", {
      params: { "file-types": fileTypes?.join() },
    });
  }

  static loadDSMeta(dataSetId: string) {
    return RestApi.doGet<IDSInfoLoadData>(`/v1/data-sets/${dataSetId}/meta`);
  }

  static loadDSDataSetDataV1(dataSetId: string, page = 0, pageSize = 20) {
    return RestApi.doGet<IDSDataSetDataPageable>(
      `/v1/data-sets/${dataSetId}/data`,
      {
        pageable: true,
        pageSize,
        page,
      }
    );
  }

  static loadDSv1(dataSetId: string) {
    return RestApi.doGet<IDSSLoadData>(`/v1/data-sets/${dataSetId}/prepared`);
  }

  static loadDSTableDatav1Pageable(
    dataSetId: string,
    dataSetTableId: string,
    page = 0,
    pageSize = 20
  ) {
    return RestApi.doGet<IDSDataSetDataPageable>(
      `/v1/data-sets/${dataSetId}/table/${dataSetTableId}/data`,
      {
        pageable: true,
        pageSize,
        page,
      }
    );
  }

  // @depracated
  static loadDSPreviewV1(dataSetId: string) {
    return RestApi.doGet<Models.TableDataDto>(
      `/v1/data-sets/${dataSetId}/preview`
    );
  }

  // TODO pageable in future
  static loadDataSetPreviewJson(dataSetId: string) {
    return RestApi.doGet<Models.DataSetPreviewJsonDto>(
      `/v1/data-sets/${dataSetId}/preview-json`
    );
  }

  static loadDSPreviewPageableV1(dataSetId: string, page = 0, pageSize = 20) {
    return RestApi.doGet<Models.TableDataDtoPageable>(
      `/v1/data-sets/${dataSetId}/preview`,
      {
        pageable: true,
        pageSize,
        page,
      }
    );
  }

  static createDataSetTemplate(dataSetId: string, template: IDsTemplate) {
    return RestApi.doPost<string>(
      `/v1/data-sets/${dataSetId}/templates`,
      JSON.stringify(template)
    );
  }

  static exportDataSetTemplate(dataSetId: string, templateId: string) {
    return RestApi.doGet<any>(
      `/v1/data-sets/${dataSetId}/templates/${templateId}/export`,
      {
        responseType: "arraybuffer",
        returnRawData: true,
        params: {
          type: "PDF",
        },
      }
    );
  }

  static loadDatasetTemplates(datasetId: string) {
    return RestApi.doGet<IDsTemplate[]>(`/v1/data-sets/${datasetId}/templates`);
  }

  static createDSFromGoogleFileV1(googleDriveFile: GoogleDriveFile) {
    return RestApi.doPost<string>(
      "/v1/google/prepare-data-set-from-file",
      googleDriveFile,
      {
        onUploadProgress: (event) => {
          console.log("Google file upload upload...", event);
        },
      }
    );
  }

  static generateTokenFromCodeV1(parameter: any) {
    return RestApi.doPost<IGoogleTokenFromCodeDtoR>(
      "/v1/google/generate-token-from-code",
      JSON.stringify(parameter)
    );
  }

  static loadWidgetsVisualisationsv1() {
    return RestApi.doGet<IWidgetsVisualisationsResult>(
      "/v1/widget-visualisations",
      {
        pageable: true,
      }
    );
  }

  static loadWidgetVisualisationV1(visualisationId: string) {
    return RestApi.doGet<Models.IWidgetVisualisationData>(
      `/v1/widget-visualisations/${visualisationId}`
    );
  }

  static loadWidgetsv1(dashboardId: string | undefined) {
    return RestApi.doGet<IWidgetsResult>("/v1/widgets", {
      pageable: true,
      params: {
        dashboardIds: dashboardId || undefined,
      },
    });
  }

  static loadWidgetItemV1(widgetId: string) {
    return RestApi.doGet<IWidget>(`/v1/widgets/${widgetId}`);
  }

  static loadWidgetsExternalV1() {
    return RestApi.doGet<IDashboardWidgetExternal[]>("/v1/external/widgets", {
      returnRawData: true,
    });
  }

  /* DS  MERGE */
  static dataForDsMergeConfigurationV1(confgiruation: IDsMergeConfiguration) {
    return RestApi.doPost<ITable>(
      "/v1/data-sets/merge/preview/",
      JSON.stringify(confgiruation)
    );
  }

  static saveDsMergeConfigurationV1(data: IDsMergeData) {
    return RestApi.doPost<string>("/v1/data-sets/merge", data);
  }

  static updateDsMergeConfigurationV1(
    data: IDsMergeData,
    dataSourceId: string
  ) {
    return RestApi.doPut<void>(`/v1/data-sets/merge/${dataSourceId}`, data, {
      returnRawData: true,
    });
  }

  static loadUserPropertyV1(key: string) {
    return RestApi.doGet<{ properties: UserProperty[] }>(
      `/v1/user-properties?keys=${key}`,
      {
        includeInstallation: false,
      }
    ).then((response) =>
      response && response.properties && response.properties.length > 0
        ? response.properties[0]
        : undefined
    );
  }

  static saveUserPropertyV1(property: UserProperty) {
    return RestApi.doPut<void>(
      "/v1/user-properties",
      { properties: [property] },
      {
        returnRawData: true,
        includeInstallation: false,
      }
    );
  }

  static googleConfigurationV1() {
    return RestApi.doGet<GoogleConfiguration>("/v1/google/configuration");
  }

  static exportFile(widgetId: string, context: any, type: FileType) {
    return RestApi.doGet<any>(`/v1/widgets/${widgetId}/export`, {
      responseType: "blob",
      returnRawData: true,
      params: {
        date:
          context && context.date
            ? moment(context.date).format("YYYY-MM-DD")
            : "",
        dateFrom:
          context && context.dateFrom
            ? moment(context.dateFrom).format("YYYY-MM-DD")
            : "",
        dateTo:
          context && context.dateTo
            ? moment(context.dateTo).format("YYYY-MM-DD")
            : "",
        type,
      },
    });
  }

  static loginV1(authData: IAuthData) {
    return RestApi.doPost<{ accessToken: string }>(
      "/v1/auth/login",
      JSON.stringify(authData),
      {
        includeInstallation: false,
        includeLang: false,
        includeToken: false,
        returnRawData: true,
      }
    );
  }

  static passwordReset(authData: IAuthData, token: string) {
    return RestApi.doPost<void>(
      "/v1/auth/password-renew",
      JSON.stringify(authData),
      {
        includeInstallation: false,
        includeLang: false,
        token,
        returnRawData: true,
      }
    );
  }

  static changePassword(
    oldPassword: string,
    newPassword: string
  ): Promise<Response> {
    return RestApi.doPost<Response>(
      "/v1/me/change-password",
      JSON.stringify({
        oldPassword,
        newPassword,
      }),
      {
        includeInstallation: false,
        includeLang: false,
        includeToken: true,
        returnRawData: true,
      }
    );
  }

  static searchUsersV1(searchParameter: string) {
    return RestApi.doGet<IUsersGroupsFindResultData>("/v1/search/users", {
      pageable: true,
      params: {
        search: searchParameter,
      },
    });
  }

  static shareDataSourceV1(
    dataSourceId: string,
    data: IDataSourceShareWithData
  ) {
    return RestApi.doPut<void>(
      `/v1/data-sets/share/${dataSourceId}`,
      JSON.stringify(data),
      { returnRawData: true }
    );
  }

  static shareWidgetV1(dataSourceId: string, data: IWidgetShareWithData) {
    return RestApi.doPut<void>(
      `/v1/widgets/share/${dataSourceId}`,
      JSON.stringify(data),
      { returnRawData: true }
    );
  }

  static shareWithExternalV1(data: IShareWithExternalData) {
    return RestApi.doPut<string>(
      "/v1/widgets/share/external/",
      JSON.stringify(data)
    );
  }

  static createTaskV1(data: ITaskData) {
    return RestApi.doPost<void>("/v1/tasks/", JSON.stringify(data), {
      returnRawData: true,
    });
  }

  static loadTasksV1() {
    return RestApi.doGet<ITaskItemDataResult>("/v1/tasks/", {
      pageable: true,
    });
  }

  static taskCompleteV1(taskId: string) {
    return RestApi.doPut<void>(
      `/v1/tasks/${taskId}/complete`,
      {},
      { returnRawData: true }
    );
  }

  static taskInProgressV1(taskId: string) {
    return RestApi.doPut<void>(
      `/v1/tasks/${taskId}/in-progress`,
      {},
      { returnRawData: true }
    );
  }

  static loadNotificationsV1() {
    return RestApi.doGet<INotificationDataResult>(
      "/v1/notifications/?notificationStates=CREATED",
      {
        pageable: true,
      }
    );
  }

  // TODO deprecated, remove and use notifications with pageable instead
  static loadNotificationsInfoV1() {
    return RestApi.doGet<INotificationsInfoData>("/v1/notifications/info/");
  }

  static markNotificationAsProcessedV1(id: string) {
    return RestApi.doPut<void>(
      `/v1/notifications/${id}/processed`,
      {},
      { returnRawData: true }
    );
  }

  static generateFakeData(data: IGenerateData) {
    return RestApi.doPost<IGenerateOutputData>(
      "/v1/faker/generate",
      JSON.stringify(data)
    );
  }

  static currentUser() {
    return RestApi.doGet<ICurrentUser>("/v1/user");
  }

  static users() {
    return RestApi.doGet<IUserResult>("/v1/users", {
      pageable: true,
    });
  }

  static user(id: string) {
    return RestApi.doGet<IUser>(`/v1/users/${id}`);
  }

  static updateUser(id: string, userData: ICreateUser) {
    return RestApi.doPut<void>(`/v1/users/${id}`, userData, {
      returnRawData: true,
    });
  }

  static installation(id: string) {
    return RestApi.doGet<IInstallationResult>("/v1/installations", {
      pageable: true,
      includeInstallation: false,
    }).then((result) => {
      if (result && result.content && result.content.length > 0) {
        const filtered = result.content.filter((inst) => inst.id === id);
        if (filtered.length > 0) return filtered[0];
      }
      throw restApiError(`no installation found for id: ${id}`);
    });
  }

  static installations() {
    return RestApi.doGet<IInstallationResult>("/v1/installations", {
      includeInstallation: false,
      pageable: true,
      params: {
        sort: "name,asc",
      },
    });
  }

  static permissionsTypes() {
    return RestApi.doGet<IPermissionType[]>("/v1/permission-types", {
      includeInstallation: false,
    });
  }

  static getAllPermissionGroups() {
    return RestApi.doGet<IPermissionGroupResult>("/v1/permission-groups", {
      pageable: true,
      includeInstallation: false,
    });
  }

  static getPermissionGroups() {
    return RestApi.doGet<IPermissionGroupResult>("/v1/permission-groups", {
      pageable: true,
    });
  }

  static getPermissionGroup(id: string) {
    return RestApi.getPermissionGroups().then((result) => {
      if (result && result.content) {
        const group = result.content.find((pg) => pg.id === id);
        if (group) {
          return group;
        }
      }
      throw new Error(`permission group for id ${id} is not available`);
    });
  }

  static updatePermissionGroup(id: string, pgData: ICreatePermissionGroup) {
    return RestApi.doPut<void>(`/v1/permission-groups/${id}`, pgData, {
      returnRawData: true,
    });
  }

  static createPermissionGroup(pgData: ICreatePermissionGroup) {
    return RestApi.doPost<string>("/v1/permission-groups", pgData);
  }

  static deletePermissionGroup(id: string) {
    return RestApi.doDelete(`/v1/permission-groups/${id}`);
  }

  static getAllUsersPermissionGroups(userIds: string[] | null) {
    return RestApi.doGet<IPermissionGroupUsersResult>(
      "/v1/permission-group-users",
      {
        pageable: true,
        includeInstallation: false,
        params: {
          users: userIds ? userIds.join() : undefined,
        },
      }
    );
  }

  static getUsersPermissionGroups(userIds: string[] | null) {
    return RestApi.doGet<IPermissionGroupUsersResult>(
      "/v1/permission-group-users",
      {
        pageable: true,
        params: {
          users: userIds ? userIds.join() : undefined,
        },
      }
    );
  }

  static getLinksOfInstallationsToPermissionGroups(installationIds: string[]) {
    return RestApi.doGet<IPermissionGroupInstallationResult>(
      "/v1/installation-permission-groups",
      {
        includeInstallation: false,
        pageable: true,
        params: {
          installations: installationIds.join(),
        },
      }
    );
  }

  static linkPermissionGroupToCurrentInstallation(permissionGroupId: string) {
    const installationId = _installationId;
    if (!installationId) {
      throw new Error("no installation context");
    }
    return RestApi.linkPermissionGroupToInstallation(
      permissionGroupId,
      installationId
    );
  }

  static linkPermissionGroupToInstallation(
    permissionGroupId: string,
    installationId: string
  ) {
    return RestApi.doPost<string>(
      "/v1/installation-permission-groups",
      { permissionGroupId, installationId },
      { includeInstallation: false }
    );
  }

  static deleteInstallationPermissionGroupRelation(
    installationPermissionGroupRelationId: string
  ) {
    return RestApi.doDelete(
      `/v1/installation-permission-groups/${installationPermissionGroupRelationId}`,
      { includeInstallation: false }
    );
  }

  static createUser(data: ICreateUser) {
    return RestApi.doPost<Models.IResetPasswordResponse>(
      "/v1/users",
      JSON.stringify(data)
    );
  }

  static createInstallation(
    data: IInstallationCreate,
    permissionGroupId: string
  ) {
    return RestApi.doPost<string>("/v1/installations", JSON.stringify(data), {
      params: {
        "permission-group-id": permissionGroupId,
      },
    });
  }

  static userResetPassword(userId: string, direct = false) {
    return RestApi.doPut<Models.IResetPasswordResponse>(
      `/v1/users/${userId}/reset-password`,
      {},
      {
        params: {
          option: direct ? "DIRECT" : undefined,
        },
      }
    );
  }

  static loadCatalogueCategories() {
    return RestApi.doGet<ICatalogueCategoryResult>("/v1/catalogue-categories", {
      pageable: true,
    });
  }

  static loadCatalogueCategory(catalogueCategoryId: string) {
    return RestApi.doGet<ICatalogueCategory>(
      `/v1/catalogue-categories/${catalogueCategoryId}`,
      {
        params: { catalogueCategoryId },
      }
    );
  }

  static loadCatalogueWidgetTemplates(
    catalogueCategoryIds: string | string[] | undefined = undefined
  ) {
    return RestApi.doGet<ICatalogueWidgetTemplateResult>(
      "/v1/widget-templates",
      {
        pageable: true,
        params: {
          catalogueCategoryIds: Array.isArray(catalogueCategoryIds)
            ? catalogueCategoryIds.join()
            : catalogueCategoryIds,
        },
      }
    );
  }

  static loadWidgetConfig(widgetConfigId: string) {
    return RestApi.doGet<IWidgetConfig>(
      `/v1/widget-config-predefined/${widgetConfigId}`
    );
  }

  static connectors(perUser?: boolean) {
    return RestApi.doGet<Models.IConnectorResult>("/v1/connectors", {
      pageable: true,
      params: {
        "per-user": perUser,
      },
    });
  }

  static loadDataSources() {
    return RestApi.doGet<IDataSourceResult>("/v1/data-sources", {
      pageable: true,
    });
  }

  // TODO move to transport
  static testDataSource(
    dataSourceId: string,
    testDto: Models.DataSourceTestDto
  ) {
    return RestApi.doPost<DataSourceTestResultDto>(
      `/v1/data-sources/${dataSourceId}/test`,
      testDto
    );
  }

  // TODO move to transport
  static loadFieldTypes() {
    return RestApi.doGet<FieldTypeResult>("/v1/field-types", {
      pageable: true,
    });
  }

  static loadFieldType(id: string) {
    return RestApi.doGet<FieldTypeDto>(`/v1/field-types/${id}`);
  }

  static loadDataSetLabelTypes() {
    return RestApi.doGet<DataSetLabelTypeResult>("v1/data-set-label-types", {
      pageable: true,
    });
  }

  static loadDataSetLabelType(id: string) {
    return RestApi.doGet<DataSetLabelTypeDto>(`/v1/data-set-label-types/${id}`);
  }

  static loadScriptExecutorJobs() {
    return RestApi.doGet<any>("/v1/script-executor-jobs", {
      pageable: true,
    });
  }

  static loadScriptDefinitionLabels(scriptDefinitionId: string | string[]) {
    return RestApi.doGet<ScriptDefinitionLabelResult>(
      "v1/script-definition-labels",
      {
        pageable: true,
        params: {
          "script-definitions": Array.isArray(scriptDefinitionId)
            ? scriptDefinitionId.join()
            : scriptDefinitionId,
        },
      }
    );
  }

  static loadScriptDefinitionLabel(id: string) {
    return RestApi.doGet<ScriptDefinitionLabelDto>(
      `/v1/script-definition-labels/${id}`
    );
  }

  static createScriptDefinitionLabel(dto: ScriptDefinitionLabelDtoU) {
    return RestApi.doPost<string>("/v1/script-definition-labels", dto);
  }

  static updateScriptDefinitionLabel(
    id: string,
    dto: ScriptDefinitionLabelDtoU
  ) {
    return RestApi.doPut<void>(`/v1/script-definition-labels/${id}`, dto);
  }

  static deleteScriptDefinitionLabel(id: string) {
    return RestApi.doDelete(`/v1/script-definition-labels/${id}`);
  }

  static loadScriptExecutorJob(scriptExecutorJobId: string) {
    return RestApi.doGet<any>(
      `/v1/script-executor-jobs/${scriptExecutorJobId}`
    );
  }

  static loadDataSetSchedulesV1(dataSetId: string | string[]) {
    return RestApi.doGet<Models.IDataSetScheduleResult>(
      "/v1/data-set-schedules",
      {
        pageable: true,
        params: {
          "data-sets": Array.isArray(dataSetId) ? dataSetId.join() : dataSetId,
        },
      }
    );
  }

  static createDataSetSchedule(dto: Models.IDataSetSchedule) {
    return RestApi.doPost<string>("/v1/data-set-schedules", dto);
  }

  static updateDataSetSchedule(
    dto: Models.IDataSetSchedule,
    dataSetScheduleId: string
  ) {
    return RestApi.doPut<void>(
      `/v1/data-set-schedules/${dataSetScheduleId}`,
      dto,
      { returnRawData: true }
    );
  }

  static deleteDataSetSchedule(dataSetScheduleId: string) {
    return RestApi.doDelete(`/v1/data-set-schedules/${dataSetScheduleId}`);
  }

  static sendMessageToAssitent(message: string) {
    return RestApi.doPost<Models.AssitentResponse>("/v1/assistant-command", {
      inputText: message,
    });
  }

  static loadAssistantPersons() {
    return RestApi.doGet<Models.AssistantPersonDto[]>("v1/assistant-persons");
  }

  static workspacesGetAll() {
    return RestApi.doGet<Models.WorkspacesPageable>("/v1/workspaces", {
      pageable: true,
    });
  }

  static workspacesGet1(workspaceId: string) {
    return RestApi.doGet<Models.Workspace>(`/v1/workspaces/${workspaceId}`);
  }

  static workspacesPost(workspaceData: Models.WorkspaceCreate) {
    return RestApi.doPost<string>("/v1/workspaces", workspaceData);
  }

  static workspacesPut1(
    workspaceId: string,
    workspaceData: Models.WorkspaceCreate
  ) {
    return RestApi.doPut<void>(`/v1/workspaces/${workspaceId}`, workspaceData, {
      returnRawData: true,
    });
  }

  static workspacesDelete1(workspaceId: string) {
    return RestApi.doDelete(`/v1/workspaces/${workspaceId}`);
  }

  static workspacesGetAllConnectionsToWidgets() {
    return RestApi.doGet<Models.WorkspaceWidgetRelationPageable>(
      "/v1/workspace-widgets",
      {
        pageable: true,
      }
    );
  }

  static workspacesPostConnectionToWidget(
    connectionData: Models.WorkspaceWidgetCreateRelation
  ) {
    return RestApi.doPost<string>("/v1/workspace-widgets", connectionData);
  }

  static workspacesDelete1ConnectionToWidget(connectionId: string) {
    return RestApi.doDelete(`/v1/workspace-widgets/${connectionId}`);
  }

  static workspacesGetAllConnectionsToDataSources(
    workspaceIds: string | string[] | undefined,
    dataSourcesIds: string | string[] | undefined
  ) {
    return RestApi.doGet<Models.WorkspaceDataSourceRelationPageable>(
      "/v1/workspace-data-sources",
      {
        pageable: true,
        params: {
          workspaceIds: Array.isArray(workspaceIds)
            ? workspaceIds.join()
            : workspaceIds,
          dataSourcesIds: Array.isArray(dataSourcesIds)
            ? dataSourcesIds.join()
            : dataSourcesIds,
        },
      }
    );
  }

  static workspacesPostConnectionToDataSources(
    connectionData: Models.WorkspaceDataSourceCreateRelation
  ) {
    return RestApi.doPost<string>("/v1/workspace-data-sources", connectionData);
  }

  static workspacesDelete1ConnectionToDataSource(connectionId: string) {
    return RestApi.doDelete(`/v1/workspace-data-sources/${connectionId}`);
  }

  static workspacesGetAllConnectionsToDataSets(
    workspaceIds: string | string[] | undefined,
    dataSetsIds: string | string[] | undefined
  ) {
    return RestApi.doGet<Models.WorkspaceDataSetRelationPageable>(
      "/v1/workspace-data-sets",
      {
        pageable: true,
        params: {
          workspaceIds: Array.isArray(workspaceIds)
            ? workspaceIds.join()
            : workspaceIds,
          dataSetsIds: Array.isArray(dataSetsIds)
            ? dataSetsIds.join()
            : dataSetsIds,
        },
      }
    );
  }

  static workspacesPostConnectionToDataSets(
    connectionData: Models.WorkspaceDataSetCreateRelation
  ) {
    return RestApi.doPost<string>("/v1/workspace-data-sets", connectionData);
  }

  static workspacesDelete1ConnectionToDataSet(connectionId: string) {
    return RestApi.doDelete(`/v1/workspace-data-sets/${connectionId}`);
  }

  static workspacesGetAllConnectionsToDashboards(
    workspaceIds: string | string[] | undefined,
    dashboardIds: string | string[] | undefined
  ) {
    return RestApi.doGet<Models.WorkspaceDashboardRelationPageable>(
      "/v1/workspace-dashboards",
      {
        pageable: true,
        params: {
          workspaceIds: Array.isArray(workspaceIds)
            ? workspaceIds.join()
            : workspaceIds,
          dashboardIds: Array.isArray(dashboardIds)
            ? dashboardIds.join()
            : dashboardIds,
        },
      }
    );
  }

  static workspacesPostConnectionToDashboards(
    connectionData: Models.WorkspaceDashboardCreateRelation
  ) {
    return RestApi.doPost<string>("/v1/workspace-dashboards", connectionData);
  }

  static workspacesDelete1ConnectionToDashboard(connectionId: string) {
    return RestApi.doDelete(`/v1/workspace-dashboards/${connectionId}`);
  }

  static workspacesGetAllConnectionsToConnectors(
    workspaceIds: string | string[] | undefined,
    connectorIds: string | string[] | undefined
  ) {
    return RestApi.doGet<Models.WorkspaceConnectorRelationPageable>(
      "/v1/workspace-connectors",
      {
        pageable: true,
        params: {
          workspaceIds: Array.isArray(workspaceIds)
            ? workspaceIds.join()
            : workspaceIds,
          connectorIds: Array.isArray(connectorIds)
            ? connectorIds.join()
            : connectorIds,
        },
      }
    );
  }

  static workspacesPostConnectionToConnectors(
    connectionData: Models.WorkspaceConnectorCreateRelation
  ) {
    return RestApi.doPost<string>("/v1/workspace-connectors", connectionData);
  }

  static workspacesDelete1ConnectionToConnector(connectionId: string) {
    return RestApi.doDelete(`/v1/workspace-connectors/${connectionId}`);
  }

  static workspacesGetAllMemberTypes() {
    return RestApi.doGet<Models.WorkspaceMemberTypePageable>(
      "/v1/workspace-member-types",
      {
        pageable: true,
      }
    );
  }

  static workspacesGetAllConnectionsToMembers(
    workspaceIds: string | string[] | undefined,
    workspaceMemberTypeIds: string | string[] | undefined
  ) {
    return RestApi.doGet<Models.WorkspaceMemberRelationPageable>(
      "/v1/workspace-members",
      {
        pageable: true,
        params: {
          workspaceIds: Array.isArray(workspaceIds)
            ? workspaceIds.join()
            : workspaceIds,
          workspaceMemberTypeIds: Array.isArray(workspaceMemberTypeIds)
            ? workspaceMemberTypeIds.join()
            : workspaceMemberTypeIds,
        },
      }
    );
  }

  static workspacesPostConnectionToMembers(
    connectionData: Models.WorkspaceMemberCreateRelation
  ) {
    return RestApi.doPost<string>("/v1/workspace-members", connectionData);
  }

  static workspacesPut1ConnectionToMember(
    connectionId: string,
    connectionData: Models.WorkspaceMemberCreateRelation
  ) {
    return RestApi.doPut<void>(
      `/v1/workspace-members/${connectionId}`,
      connectionData,
      { returnRawData: true }
    );
  }

  static workspacesDelete1ConnectionToMember(connectionId: string) {
    return RestApi.doDelete(`/v1/workspace-members/${connectionId}`);
  }
}
