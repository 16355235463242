import React from "react";

import Loading from "./Loading";

export type LoadingOverlayItem = {
  id: string;
  message: string;
};

export type Props = {
  items?: LoadingOverlayItem[];
};

export default function LoadingOverlay({ items }: Props) {
  return (
    <div className="loadingOverlay">
      {!!items &&
        items.map(({ id, message }) => (
          <div className="lead" key={id}>
            {message}
          </div>
        ))}
      <Loading show />
    </div>
  );
}
